import React from 'react'
import loadable from '@loadable/component'
import { Spinner } from 'transfix-ui/components/Spinner'

export const TmsIndexAsync = loadable(
  () => {
    return import('./index')
  },
  {
    fallback: <Spinner />,
  }
)

export default TmsIndexAsync
