import MuiBox, { BoxProps as MuiBoxProps } from '@mui/material/Box'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { Theme as MaterialTheme } from '@mui/material/styles'
import { BoxTypeMap } from '@mui/system'

export interface IBoxProps extends MuiBoxProps {}

/**
 * The Box component serves as a wrapper component for most of the CSS utility needs.
 * @see https://v4.mui.com/components/box/
 */
const Box: OverridableComponent<BoxTypeMap<{}, 'div', MaterialTheme>> = MuiBox

export default Box
