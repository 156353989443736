import { decamelize, camelize } from 'humps'
import { keymirror } from 'utils/keymirror'

export const generateApiActionTypes = actionName => {
  if (!actionName || typeof actionName !== 'string')
    return new Error('Cannot generate action types without an action name.')
  const simplified = actionName.replace('_ACTION_TYPES', '')
  const lowercased = simplified.toUpperCase() === simplified ? simplified.toLowerCase() : simplified
  const camelized = camelize(lowercased)
  const decamelized = decamelize(camelized)
  const formattedActionName = decamelized.toUpperCase()
  return keymirror({
    [`${formattedActionName}_REQUEST`]: null,
    [`${formattedActionName}_SUCCESS`]: null,
    [`${formattedActionName}_FAILURE`]: null,
  })
}
