import React, { useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { Card } from 'transfix-ui/components/Card'
import { Typography } from 'transfix-ui/core'
import Box from 'transfix-ui/core/Box'
import Grid from 'transfix-ui/core/Grid'
import { IResetPasswordFormProps, ResetPasswordForm } from './components'
import { useResetPassword } from './hooks'

interface IResetPasswordPageProps {
  /**
   * @default 'Resend Instructions'
   */
  pageTitle?: string
}

const ResetPasswordPage = ({ pageTitle = 'Create a new password' }: IResetPasswordPageProps) => {
  const location = useLocation()
  const { errorMessage, loading: isLoading, resetPassword } = useResetPassword()

  const submitSuccessHandler: IResetPasswordFormProps['onSubmitSuccess'] = useCallback(
    async ({ newPassword }) => {
      const token = new URLSearchParams(location.search).get('token') ?? ''
      const response = await resetPassword(newPassword, token)
      const { success } = response || {}

      if (success) {
        window.location.pathname = `/booking`
      }
    },
    [location, resetPassword]
  )

  return (
    <Box data-test='ResetPasswordPage' display='flex' justifyContent='center' width='100%'>
      <Box p={2} pt={8} maxWidth='500px' flex='1 1 500px'>
        <Card>
          <Box>
            <Box mb={2}>
              <Typography component='div' variant='h3'>
                {pageTitle}
              </Typography>
            </Box>
            <Box mb={2}>
              <ResetPasswordForm
                onSubmitSuccess={submitSuccessHandler}
                isLoading={isLoading}
                errorMessage={errorMessage}
              />
            </Box>
          </Box>
        </Card>
        <Box display='flex' justifyContent='center' mt={3}>
          <Grid container spacing={4} justifyContent='center'>
            <Grid item xs={12} md='auto'>
              <a href={`${__ROOT__}/login`} className='btn-link'>
                Back to login
              </a>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}

export default ResetPasswordPage
