import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum'
import isEmpty from 'lodash/isEmpty'
import { IUserDetails } from 'types'
import { log } from 'utils/errorLogger'

// Datadog Rums
const DD_RUMS_CONFIG: RumInitConfiguration = {
  applicationId: __DATADOG_APP_ID__,
  clientToken: __DATADOG_RUM_TOKEN__,
  site: 'datadoghq.com',
  service: 'rogers',
  env: __ENV__,
  version: __ROGERS_VERSION__,
  sessionSampleRate: 25,
  sessionReplaySampleRate: 25,
  trackUserInteractions: true,
  defaultPrivacyLevel: 'mask-user-input',
  allowedTracingUrls: [/https:\/\/.*\.transfix\.io/],
  traceSampleRate: 25,
}

const errorsToIgnore = [
  'ChunkLoadError', // Good old chunking loading errors - they are entirely related to FE client error during deploys
  'Loading chunk',
  'Market insights for non-U.S. loads are currently unavailable.', // happens when a shipment has pickup/delivery in Mexico or Canada (usually Canada)
  'No DAT market found for location', // when we cant find a DatMarket for the postal code of one of the stops
  'Failed to fetch', // Stop trying to make fetch happen
  'Fetched is aborted', // it's not going to happen
  'Failed to initialize WebGL', // mapbox error
  'You are not authorized to do that',
  'The operation was aborted',
  'URI malformed',
  'The node before which the new node is to be inserted is not a child of this node',
  'Please try again in a few minutes',
  'You must be authenticated',
  'getBoundingClientRect',
]

export const initDDR = () => {
  if (isEmpty(__DATADOG_APP_ID__)) return
  datadogRum.init({
    ...DD_RUMS_CONFIG,
    beforeSend: event => {
      // Discard any of the errorsToIgnore that partially matches the event.error.message
      if (
        event.type === 'error' &&
        errorsToIgnore.some(ignoreString => event.error.message.includes(ignoreString))
      ) {
        return false // Ignore the error
      }
      return true
    },
  })
  datadogRum.startSessionReplayRecording()
}

export const identifyUserDDR = (userDetails: IUserDetails) => {
  const {
    applicationId,
    applicationType,
    companyId,
    companyName,
    companyType,
    email,
    firstName,
    groups,
    id: userId,
    isPro,
    lastName,
    parentUserId,
    roleName,
    userTypeName,
  } = userDetails || {}

  datadogRum.setUser({
    id: `${userId}`,
    email,
    companyType,
    companyId,
    companyName,
    applicationId,
    applicationType,
    isPro,
    firstName,
    groups,
    lastName,
    parentUserId,
    role: roleName,
    userType: userTypeName,
  })
}

export const trackDDREvent = (
  appName: string,
  eventValue: string,
  properties?: Record<string, any>
) => {
  try {
    datadogRum.addAction(eventValue, properties || {})
  } catch (e) {
    log('Analytics', e)
  }
}
