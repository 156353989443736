import { useCallback, useState } from 'react'
import {
  useResendDetailsMutation,
  IUseResendDetailsMutationResponse,
} from './useResendDetailsMutation'

export const useResendDetails = () => {
  const [errorMessage, setErrorMessage] = useState<string>()
  const { mutate: _resendDetails, ...state } = useResendDetailsMutation()

  /**
   * Resend user details to the email address provided
   */
  const resendUserDetails = useCallback(
    async (email: string): Promise<IUseResendDetailsMutationResponse | null> => {
      setErrorMessage(undefined)

      let response: IUseResendDetailsMutationResponse | null = null

      try {
        response = await _resendDetails({ email })
      } catch (error) {
        const { message } = error || {}
        setErrorMessage(message || 'Invalid email address')
      }

      return response
    },
    [_resendDetails]
  )

  return {
    ...state,
    resendUserDetails,
    errorMessage,
  }
}
