import React, { ReactElement, cloneElement, useState, useCallback, FC, useEffect } from 'react'
import { createPortal } from 'react-dom'
import ReactTooltip from 'react-tooltip'
import { useEffectOnce } from 'react-use'
import { makeStyles } from 'transfix-ui/core/styles'
import { GLOBAL_TOOLTIP_ID, GLOBAL_TOOLTIP_CONTENT_ID } from './constants'

const useStyles = makeStyles(() => ({
  triggerWrapper: {
    display: 'inline-flex',
  },
}))

interface IGlobalTooltipContentProps {
  trigger: ReactElement
  /**
   * @default 'bottom'
   */
  place?: ReactTooltip.Props['place']
  /**
   * @default 'dark'
   */
  type?: ReactTooltip.Props['type']
  /**
   * @default 'solid'
   */
  effect?: ReactTooltip.Props['effect']
  /**
   * @default false
   */
  disable?: ReactTooltip.Props['disable']
}

interface ITriggerProps
  extends Pick<
    ReactTooltip.DataProps,
    'data-type' | 'data-place' | 'data-effect' | 'data-tip-disable'
  > {
  'data-tip': boolean
  'data-for': string
}

export const GlobalTooltipContent: FC<IGlobalTooltipContentProps> = ({
  children,
  disable = false,
  effect = 'solid',
  place = 'bottom',
  trigger,
  type = 'dark',
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const mouseEnterHandler = useCallback(() => {
    setIsHovered(true)
  }, [])
  const mouseLeaveHandler = useCallback(() => {
    setIsHovered(false)
  }, [])
  const triggerComponent: ReactElement<ITriggerProps> = cloneElement(trigger, {
    'data-effect': effect,
    'data-place': place,
    'data-type': type,
    // data-tip needs to exist on the element for the bind function to grab the element
    'data-tip': true,
    'data-for': GLOBAL_TOOLTIP_ID,
    'data-tip-disable': disable,
  })

  /**
   * useEffectOnce to bind/unbind on mount/unmount, respectively, the trigger to the global tooltip.
   */
  useEffectOnce(() => {
    ReactTooltip.rebuild()

    return () => ReactTooltip.rebuild()
  })

  const globalTooltipContentElement = document.getElementById(GLOBAL_TOOLTIP_CONTENT_ID)
  const classes = useStyles()

  /**
   * useEffect to hide the GlobalTooltip if the component triggering it is being unmounted.
   */
  useEffect(() => {
    return () => {
      if (isHovered) {
        ReactTooltip.hide()
      }
    }
  }, [isHovered])

  return (
    <>
      <div
        className={classes.triggerWrapper}
        onMouseEnter={mouseEnterHandler}
        onMouseLeave={mouseLeaveHandler}>
        {triggerComponent}
      </div>
      {isHovered &&
        globalTooltipContentElement &&
        createPortal(children, globalTooltipContentElement)}
    </>
  )
}

export default GlobalTooltipContent
