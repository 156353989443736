import React from 'react'
import MuiGrid, { GridProps as MuiGridProps, GridTypeMap } from '@mui/material/Grid'
import { OverridableComponent } from '@mui/material/OverridableComponent'

export interface IGridProps extends MuiGridProps {}

/**
 * @see https://v4.mui.com/components/grid/
 */
const Grid: OverridableComponent<GridTypeMap> = (props: IGridProps) => {
  return <MuiGrid {...props} />
}

export default Grid
