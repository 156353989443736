import { createTheme } from '@mui/material/styles'
import SelectToggleIcon from 'transfix-ui/components/Select/SelectToggleIcon'
import {
  FormHelperTextProps,
  switchClasses,
  inputLabelClasses,
  outlinedInputClasses,
  selectClasses,
} from 'transfix-ui/core'
import { breakpoints } from './breakpoints'
import { brandColors, getBrandColor } from './colors'
import ExoBoldWoff2 from './fonts/exo/Exo2-Bold.woff2'
import ExoExtraBoldWoff2 from './fonts/exo/Exo2-ExtraBold.woff2'
import ExoExtraBoldItalicWoff2 from './fonts/exo/Exo2-ExtraBoldItalic.woff2'
import ExoItalicWoff2 from './fonts/exo/Exo2-Italic.woff2'
import ExoRegularWoff2 from './fonts/exo/Exo2-Regular.woff2'
import ExoSemiBoldWoff2 from './fonts/exo/Exo2-SemiBold.woff2'
import { shadows } from './shadows'
import { calcRem } from './typography'

export const rogersFontFamily = [
  '"Nunito Sans"',
  '-apple-system',
  'BlinkMacSystemFont',
  '"avenir next"',
  'avenir',
  'helvetica',
  'helvetica neue',
  'ubuntu',
  'roboto',
  'noto',
  '"segoe ui"',
  'arial',
  'sans-serif',
].join(',')

export const rogersHeaderFontFamily = [
  'Exo',
  '"Nunito Sans"',
  '-apple-system',
  'BlinkMacSystemFont',
  '"avenir next"',
  'avenir',
  'helvetica',
  'helvetica neue',
  'ubuntu',
  'roboto',
  'noto',
  '"segoe ui"',
  'arial',
  'sans-serif',
].join()

const OPTIONS = {
  fontFamily: rogersFontFamily,
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  borderRadius: 2,
} as const

/**
 * Canonical theme for Transfix UI
 */
const BASE_THEME = createTheme({})

/**
 * @name Theme!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 */
export default createTheme({
  ...BASE_THEME,
  typography: {
    ...BASE_THEME.typography,
    fontFamily: rogersFontFamily,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    /** Mobile */
    h1: {
      fontFamily: rogersHeaderFontFamily,
      fontWeight: 700,
      letterSpacing: 0,
      fontSize: calcRem(48), // 48px
      lineHeight: calcRem(65),
      /** Desktop */
      [BASE_THEME.breakpoints.up('md')]: {
        letterSpacing: calcRem(-0.5),
        fontWeight: 300,
        fontSize: calcRem(60),
        lineHeight: calcRem(82),
      },
    },
    /** Mobile */
    h2: {
      fontFamily: rogersHeaderFontFamily,
      fontWeight: 600,
      letterSpacing: 0,
      fontSize: calcRem(24), // 24px
      lineHeight: calcRem(33),
      fontStyle: 'normal',
      /** Desktop */
      [BASE_THEME.breakpoints.up('md')]: {
        fontSize: calcRem(34), // 34px
        letterSpacing: calcRem(0.25),
        lineHeight: calcRem(46),
      },
    },
    /** Mobile */
    h3: {
      fontFamily: rogersHeaderFontFamily,
      fontWeight: 600,
      letterSpacing: calcRem(0.15),
      fontSize: calcRem(24),
      lineHeight: calcRem(33),
      fontStyle: 'normal',
      /** Desktop */
      [BASE_THEME.breakpoints.up('md')]: {
        fontWeight: 500,
        fontSize: calcRem(20), // 20px
        lineHeight: calcRem(27),
      },
    },
    h4: {
      fontFamily: rogersHeaderFontFamily,
      fontWeight: 400,
      fontSize: calcRem(20), // 20px
      color: brandColors.coolGray8,
    },
    h5: {
      fontFamily: rogersHeaderFontFamily,
    },
    h6: {
      fontFamily: rogersHeaderFontFamily,
    },
    /** Mobile */
    subtitle1: {
      fontFamily: rogersFontFamily,
      fontWeight: 600,
      letterSpacing: calcRem(0.15),
      fontSize: calcRem(18),
      lineHeight: calcRem(25),
      fontStyle: 'normal',

      /** Desktop */
      [BASE_THEME.breakpoints.up('md')]: {
        fontSize: calcRem(16),
        lineHeight: calcRem(22),
      },
    },
    /** Mobile */
    subtitle2: {
      fontFamily: rogersFontFamily,
      fontWeight: 600,
      fontSize: calcRem(16), // 16px
      lineHeight: calcRem(22),
      letterSpacing: calcRem(0.1),
      fontStyle: 'normal',
      /** Desktop */
      [BASE_THEME.breakpoints.up('md')]: {
        fontSize: calcRem(14), // 14px
        lineHeight: calcRem(19),
      },
    },
    /** Mobile */
    body1: {
      fontFamily: rogersFontFamily,
      fontWeight: 400,
      fontSize: calcRem(18), // 18px,
      lineHeight: calcRem(25),
      letterSpacing: 0,
      fontStyle: 'normal',
      /** Desktop */
      [BASE_THEME.breakpoints.up('md')]: {
        fontSize: calcRem(16),
        lineHeight: calcRem(22),
      },
    },
    /** Mobile */
    body2: {
      fontFamily: rogersFontFamily,
      fontWeight: 400,
      fontSize: calcRem(16),
      lineHeight: calcRem(22),
      letterSpacing: 0,
      fontStyle: 'normal',
      /** Desktop */
      [BASE_THEME.breakpoints.up('md')]: {
        fontSize: calcRem(14),
        lineHeight: calcRem(19),
      },
    },
    /** Mobile */
    button: {
      fontFamily: rogersFontFamily,
      fontWeight: 700,
      fontSize: calcRem(16),
      lineHeight: calcRem(22),
      letterSpacing: 0,
      textTransform: 'none',
      fontStyle: 'normal',
      /** Desktop */
      [BASE_THEME.breakpoints.up('md')]: {
        fontSize: calcRem(14),
        lineHeight: calcRem(19),
      },
    },
    /** Mobile */
    caption: {
      fontFamily: rogersFontFamily,
      fontWeight: 400,
      fontSize: calcRem(14),
      letterSpacing: calcRem(0.25),
      lineHeight: calcRem(19),
      fontStyle: 'normal',
      /** Desktop */
      [BASE_THEME.breakpoints.up('md')]: {
        fontWeight: 600,
        fontSize: calcRem(12),
        lineHeight: calcRem(16),
        letterSpacing: calcRem(0.2),
      },
    },
    /** Mobile and Desktop */
    overline: {
      fontFamily: rogersFontFamily,
      fontWeight: 600,
      fontSize: calcRem(12),
      letterSpacing: calcRem(1.5),
      textTransform: 'uppercase',
      fontStyle: 'normal',
      lineHeight: calcRem(16),
    },
  },
  /**
   * Copies the z index values from the scss master list. This is necessary for
   * Material UI components to be compatible with some of our old DPL components
   * (i.e. rendering a material UI Select within a legacy Modal).
   *
   * @see assets/scss/1_settings/_variables.scss
   * @note we're not currently using material UI tooltip, but when we do, we
   * must configure the tooltip value to be higher than both modal and
   * snackbar as it is in the defaults.
   */
  zIndex: {
    modal: 99999,
    snackbar: 100000,
  },
  /**
   * Material-UI uses a recommended 8px scaling factor by default.
   * @see https://material-ui.com/customization/spacing/
   */
  spacing: 8,
  shape: {
    borderRadius: 2,
  },
  shadows,
  transitions: {},
  /**
   * API that enables the use of breakpoints in a wide variety of contexts.
   * @see https://material-ui.com/customization/breakpoints/
   */
  breakpoints: {
    values: {
      xs: breakpoints.xs,
      sm: breakpoints.sm,
      md: breakpoints.md,
      lg: breakpoints.lg,
      xl: breakpoints.xl,
    },
  },
  palette: {
    primary: {
      main: brandColors.skyBlue6,
      dark: brandColors.skyBlue7,
      contrastText: brandColors.coolGray1,
    },
    secondary: {
      main: brandColors.white,
      dark: brandColors.skyBlue2,
      contrastText: brandColors.skyBlue6,
    },
    error: {
      main: brandColors.error,
    },
    divider: brandColors.coolGray4,
    mode: 'light',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      
@font-face {
  font-family: 'Exo';
  font-display: swap;
  font-style: normal;
  font-weight: normal;
  src: local('Exo'), local('Exo-Regular'), url(${ExoRegularWoff2}) format('woff2');
}

@font-face {
  font-family: 'Exo';
  font-display: swap;
  font-style: italic;
  font-weight: normal;
  src: local('Exo'), local('Exo-Italic'), url(${ExoItalicWoff2}) format('woff2');
}

@font-face {
  font-family: 'Exo';
  font-display: swap;
  font-style: normal;
  font-weight: bold;
  src: local('Exo'), local('Exo-Bold'), url(${ExoBoldWoff2}) format('woff2');
}

@font-face {
  font-family: 'Exo';
  font-display: swap;
  font-style: italic;
  font-weight: bold;
  src: local('Exo'), local('Exo-BoldItalic'), url(${ExoExtraBoldItalicWoff2}) format('woff2');
}

@font-face {
  font-family: 'Exo';
  font-display: swap;
  font-style: normal;
  font-weight: bold;
  src: local('Exo'), local('Exo-ExtraBold'), url(${ExoExtraBoldWoff2}) format('woff2');
}

@font-face {
  font-family: 'Exo';
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  src: local('Exo'), local('Exo-SemiBold'), url(${ExoSemiBoldWoff2}) format('woff2');
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*, *::before, *::after {
  box-sizing: inherit;
}

strong, b {
  font-weight: 700;
}

body {
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  font-size: ${calcRem(14)};
  line-height: ${calcRem(18)};
  font-style: normal;
  background-color: ${brandColors.white};
  font-family: ${rogersFontFamily};
  font-weight: 400;
  letter-spacing: 0;
}

@media (min-width:768px) {
  body {
    font-size: 0.875rem;
    line-height: 1.1875rem;
  }
}

@media print {
  body {
    background-color: #fff;
  }
}

body::backdrop {
  background-color: #fafafa;
}
      `,
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        color: 'default',
        elevation: 2,
      },
      styleOverrides: {
        colorDefault: {
          color: brandColors.coolGray1,
          backgroundColor: brandColors.coolGray8,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
            paddingTop: '3.185px !important',
            paddingBottom: '3.185px !important',
            paddingLeft: '6px',
            paddingRight: '39px',
          },
        },
        listbox: {
          padding: 0,
        },
        clearIndicator: {
          fontSize: calcRem(14),
        },
        noOptions: {
          padding: `${BASE_THEME.spacing(1)} calc(${BASE_THEME.spacing(1)} + 2px)`, // 8px 10px
        },
        loading: {
          padding: `${BASE_THEME.spacing(1)} calc(${BASE_THEME.spacing(1)} + 2px)`, // 8px 10px
        },
      },
    },
    /**
     * @name Avatar
     * @see https://material-ui.com/api/avatar/
     */
    MuiAvatar: {
      defaultProps: {
        variant: 'circular',
      },
      styleOverrides: {
        root: {
          fontSize: calcRem(14),
          fontWeight: OPTIONS.fontWeightBold,
        },
      },
    },
    /**
     * @name Button
     * @see https://material-ui.com/api/button/
     */
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
        disableFocusRipple: false,
        variant: 'contained',
        color: 'primary',
        size: 'small',
      },
      styleOverrides: {
        root: {
          fontWeight: OPTIONS.fontWeightBold,
          textTransform: 'none',
          padding: 8,
          paddingLeft: 16,
          paddingRight: 16,
          fontSize: calcRem(16),
          lineHeight: calcRem(22),
          borderStyle: 'solid',
          borderWidth: 1,
          borderColor: 'transparent',
          transition: 'none',

          '&:focus': {
            boxShadow: `0 0 0 3px ${brandColors.skyBlue3}`,
            transition: 'none',
          },

          '&:hover:focus': {
            boxShadow: `0 0 0 3px ${brandColors.skyBlue3}`,
            transition: 'none',
          },

          [BASE_THEME.breakpoints.up('md')]: {
            fontSize: calcRem(14),
            lineHeight: calcRem(19),
          },
        },
        sizeSmall: {
          fontSize: calcRem(14),
          lineHeight: calcRem(22),
          paddingTop: BASE_THEME.spacing(0.5) + 1,
          paddingBottom: BASE_THEME.spacing(0.5) + 1,
          paddingLeft: 8 + 2,
          paddingRight: 8 + 2,
        },
        /**
         * This is for 'default' or 'tertiary' color
         */
        contained: {
          backgroundColor: brandColors.white,
          color: brandColors.skyBlue6,
          borderColor: brandColors.white,

          '&:hover': {
            color: brandColors.skyBlue7,
            backgroundColor: brandColors.skyBlue2,
          },

          '&:focus': {
            color: brandColors.skyBlue7,
            backgroundColor: brandColors.skyBlue2,
            borderColor: brandColors.skyBlue3,
          },
        },
        /**
         * This is for 'secondary' color
         */
        containedSecondary: {
          borderColor: brandColors.skyBlue6,
          '&:hover': {
            color: brandColors.skyBlue7,
            background: brandColors.skyBlue2,
          },
          '&:focus': {
            color: brandColors.skyBlue7,
            background: brandColors.skyBlue2,
            borderColor: brandColors.skyBlue3,

            '&:hover': {
              color: brandColors.skyBlue7,
              background: brandColors.skyBlue2,
            },
          },
        },
        outlinedPrimary: {
          border: 'none !important',
        },
        /**
         * This is for 'primary' color
         */
        containedPrimary: {
          color: brandColors.white,
          backgroundColor: brandColors.skyBlue6,
          borderColor: brandColors.skyBlue6,

          '&:active': {
            color: brandColors.white,
            backgroundColor: brandColors.skyBlue6,
          },

          '&:focus': {
            borderColor: brandColors.skyBlue6,
            backgroundColor: brandColors.skyBlue6,
            color: brandColors.white,

            '&:hover': {
              borderColor: brandColors.skyBlue7,
              backgroundColor: brandColors.skyBlue7,
            },
          },

          '&:hover': {
            borderColor: brandColors.skyBlue6,
            color: brandColors.white,
            backgroundColor: brandColors.skyBlue7,
          },
        },
        /**
         * This is the same size as our "medium" size
         * until we need to customize this further
         */
        containedSizeLarge: {
          padding: 8,
          paddingLeft: BASE_THEME.spacing(3),
          paddingRight: BASE_THEME.spacing(3),
          fontSize: calcRem(16),
          lineHeight: calcRem(22),

          [BASE_THEME.breakpoints.up('md')]: {
            fontSize: calcRem(14),
            lineHeight: calcRem(19),
          },
        },
        textPrimary: {
          backgroundColor: 'transparent !important',
          border: 'none !important',
          boxShadow: 'none !important',
          color: brandColors.skyBlue6,

          '&:hover, &:focus': {
            backgroundColor: 'transparent !important',
            textDecoration: 'underline',
            color: brandColors.skyBlue6,
          },
        },
        textSecondary: {
          backgroundColor: 'transparent !important',
          border: 'none !important',
          boxShadow: 'none !important',
          color: brandColors.skyBlue6,

          '&:hover, &:focus': {
            backgroundColor: 'transparent !important',
            textDecoration: 'underline',
            color: brandColors.skyBlue6,
          },
        },
        /**
         * This is for 'primary' color
         */
        text: {
          backgroundColor: 'transparent !important',
          border: 'none !important',
          boxShadow: 'none !important',
          color: brandColors.coolGray1,

          '&:hover, &:focus': {
            backgroundColor: 'transparent !important',
            textDecoration: 'underline',
            color: brandColors.coolGray1,
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true, // Disables the ripple effect globally for buttons
      },
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            cursor: 'no-drop',

            '&:hover': {
              cursor: 'no-drop !important',
            },
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: OPTIONS.borderRadius,
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: false,
      },
      styleOverrides: {
        root: {
          color: brandColors.white,
          '&:focus': {
            boxShadow: `0 0 0 2px ${brandColors.skyBlue2}`,
            borderRadius: '2px',
          },
          borderRadius: '50%',
        },
        indeterminate: {
          color: brandColors.skyBlue6,
        },
        colorPrimary: {
          '&.Mui-disabled': {
            color: brandColors.coolGray4,
          },
          '&.Mui-checked': {
            '&:hover': {
              backgroundColor: brandColors.coolGray1,
            },
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: OPTIONS.borderRadius,
          color: 'inherit',
        },
      },
    },
    /**
     * @name DialogActions
     * @see https://material-ui.com/api/dialog-actions/
     */
    MuiDialogActions: {
      defaultProps: {
        disableSpacing: false,
      },
      styleOverrides: {
        spacing: {
          padding: BASE_THEME.spacing(3),
        },
      },
    },
    /**
     * @name Divider
     * @see https://mui.com/material-ui/react-divider/
     */
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: brandColors.coolGray4,
          color: brandColors.coolGray1,
          border: 'none',
        },
      },
    },
    /**
     * @name Fab
     * @see https://material-ui.com/api/fab/
     */
    MuiFab: {
      styleOverrides: {
        root: {
          position: 'fixed',
          bottom: BASE_THEME.spacing(3), // 24px
          right: BASE_THEME.spacing(3), // 24px
          zIndex: BASE_THEME.zIndex.modal + 1,

          // Sets icon height/width default
          '& .icon': {
            height: '20px',
            width: '20px',
          },
        },
        primary: {
          backgroundColor: brandColors.skyBlue6,
          borderColor: brandColors.skyBlue6,
          color: brandColors.coolGray1,
          transition: 'none',

          '&:hover, &:focus, &:active': {
            backgroundColor: brandColors.skyBlue7,
            borderColor: brandColors.skyBlue7,
            color: brandColors.coolGray1,
            transition: 'none',
          },
        },
        secondary: {
          backgroundColor: brandColors.white,
          borderColor: brandColors.white,
          color: brandColors.skyBlue7,
          transition: 'none',

          '&:hover, &:focus, &:active': {
            backgroundColor: brandColors.skyBlue2,
            borderColor: brandColors.skyBlue2,
            color: brandColors.skyBlue6,
            transition: 'none',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          position: 'relative',
          color: brandColors.coolGray8,
          '&.Mui-error': {
            color: 'inherit',
          },
          '&.Mui-focused': {
            color: 'inherit',
          },
          '&.Mui-disabled': {
            color: brandColors.coolGray5,
          },
        },
        asterisk: {
          display: 'none',
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          '& > label[for="calendar"]': {
            marginBottom: '0 !important',
            fontSize: `${calcRem(12)} !important`,
            lineHeight: 1.6,
          },
        },
      },
    },
    /**
     * @name FormControlLabel
     * @see https://material-ui.com/api/form-control-label/
     */
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          display: 'flex',
          '&.Mui-disabled': {
            cursor: 'not-allowed',
            color: brandColors.coolGray5,
          },
        },
        label: {
          flex: '1 1 auto',
          fontSize: calcRem(14),
          marginTop: '1px',

          '&.Mui-disabled': {
            color: brandColors.coolGray5,
          },

          '.MuiFormControlLabel-root &.MuiTypography-body1': {
            fontSize: calcRem(14),
          },
        },
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        /**
         * This casted typed is necessary because there
         * seems to be an error/omission in MUI's types.
         *
         * All base MUI components can receive a 'component' prop
         * and in this case we need this to be rendered as a <span />
         */
        component: 'span',
      } as Partial<FormHelperTextProps<'p', {}>>,
      styleOverrides: {
        root: {
          color: brandColors.coolGray8,
          paddingLeft: 2,
          fontWeight: BASE_THEME.typography.fontWeightRegular,
          letterSpacing: 0,

          [BASE_THEME.breakpoints.up('md')]: {
            fontWeight: BASE_THEME.typography.fontWeightRegular,
            letterSpacing: 0,
          },
        },
        contained: {
          marginLeft: 2,
          marginRight: 0,
        },
      },
    },
    /**
     * @name InputAdornment
     * @see https://material-ui.com/api/input-adornment/
     */
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          /**
           * Removes extra spacing on right, before input starts
           */
          marginRight: 0,
          '&.MuiInputAdornment-positionStart': {
            marginLeft: 5,
            marginRight: 0,
          },
        },
        positionStart: {
          marginLeft: 5,
          marginRight: 0,
        },
      },
    },
    /**
     * @name InputBase
     * @see https://material-ui.com/api/input-base/
     */
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            background: brandColors.coolGray1,
          },
        },
        input: {
          height: '1.1876em',
          '&.Mui-disabled::placeholder': {
            color: brandColors.coolGray6,
            fontWeight: BASE_THEME.typography.fontWeightMedium,
          },
        },
      },
    },
    /**
     * @name InputLabel
     * @see https://material-ui.com/api/input-label/
     */
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
      styleOverrides: {
        root: {
          color: brandColors.coolGray8,
          lineHeight: calcRem(19),
        },
        /**
         * Overrides transform and animations for 'shrink' state
         */
        shrink: {
          lineHeight: '1.6',
          fontSize: '0.75rem',
          transform: 'none',
        },
        formControl: {
          fontWeight: BASE_THEME.typography.fontWeightRegular,
          position: 'relative',
          lineHeight: 1.6,
          color: 'rgb(33,37,41)',
          margin: 0,
        },
        outlined: {
          [`&.${inputLabelClasses.shrink}`]: {
            transform: 'translate(0px, 0px) scale(1)',
            marginBottom: '0 !important',
            fontSize: `${calcRem(12)} !important`,
          },
        },
      },
    },
    /**
     * @name Link
     * @see https://material-ui.com/api/menu-item/
     */
    MuiLink: {
      styleOverrides: {
        root: {
          fontWeight: OPTIONS.fontWeightBold,
        },
      },
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          lineHeight: calcRem(22),
          color: brandColors.coolGray6,
          fontWeight: BASE_THEME.typography.fontWeightMedium,
        },
        gutters: {
          paddingLeft: BASE_THEME.spacing(1.5),
          paddingRight: BASE_THEME.spacing(1.5),
        },
      },
    },
    /**
     * @name MenuItem
     * @see https://material-ui.com/api/menu-item/
     */
    MuiMenuItem: {
      styleOverrides: {
        root: {
          [BASE_THEME.breakpoints.up('md')]: {
            fontSize: 14,
          },
          transition: 'none',

          '&:hover': {
            transition: 'none',
          },

          '&.Mui-selected': {
            backgroundColor: getBrandColor('skyBlue2').mix(getBrandColor('coolGray1'), 0.5).hex(),
            color: brandColors.skyBlue6,

            '&:hover': {
              backgroundColor: getBrandColor('skyBlue2').mix(getBrandColor('coolGray1'), 0.3).hex(),
              color: getBrandColor('skyBlue7').hex(),
            },
          },
        },
      },
    },
    /**
     * @name OutlinedInput
     * @see https://material-ui.com/api/outlined-input/
     */
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: brandColors.coolGray5,
        },
        root: {
          borderRadius: OPTIONS.borderRadius,
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            borderWidth: 1,
          },
          /**
           * @summary :focused .MuiOutlinedInput-notchedOutline
           */
          [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
            borderWidth: 1,
            borderColor: BASE_THEME.palette.primary.main,
          },
          /**
           * This syntax enables targeting child elements and two classes
           * @see https://stackoverflow.com/questions/58113579/how-do-i-override-hover-notchedoutline-for-outlinedinput
           * @summary .Mui-focused .MuiOutlinedInput-notchedOutline
           */
          [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
            borderWidth: 1,
            borderColor: brandColors.skyBlue6,
            boxShadow: `0 0 0 3px ${brandColors.skyBlue2}`,
          },
          /**
           * Targets an input that is a descendent of focused/error
           * @summary .Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline
           */
          [`&.Mui-focused.Mui-error .${outlinedInputClasses.notchedOutline}`]: {
            boxShadow: `0 0 0 3px ${brandColors.errorLight}`,
          },

          '&.MuiInputBase-sizeSmall .MuiAutocomplete-input': {
            padding: '4.5px 4px !important',
          },

          '& .MuiAutocomplete-input.MuiInputBase-inputAdornedStart': {
            padding: '9.5px 4px',
          },
        },
        input: {
          fontSize: calcRem(16),
          lineHeight: calcRem(25.1),
          boxShadow: 'none',
          backgroundColor: brandColors.white,
          border: `1px solid ${brandColors.coolGray5}`,
          color: brandColors.coolGray8,
          borderRadius: OPTIONS.borderRadius,

          [BASE_THEME.breakpoints.up('md')]: {
            fontSize: calcRem(14),
            lineHeight: calcRem(22),
          },
        },
        adornedStart: {
          paddingLeft: 10,
        },
        adornedEnd: {
          paddingRight: 10,
        },
        inputAdornedStart: {
          borderLeft: 'none !important',
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        },
        inputAdornedEnd: {
          borderRight: 'none !important',
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        },
      },
    },
    /**
     * @name Radio
     * @see https://material-ui.com/api/radio/
     */
    MuiRadio: {
      defaultProps: {
        color: 'primary',
        disableRipple: true,
        size: 'small',
      },
    },
    /**
     * @name Select
     * @see https://material-ui.com/api/select/
     */
    MuiSelect: {
      defaultProps: {
        fullWidth: true,
        variant: 'outlined',
        displayEmpty: true,
        /**
         * Overrides theme with our own icon
         */
        IconComponent: SelectToggleIcon,
      },
      styleOverrides: {
        select: {
          /**
           * Override makes sure that some styles that bled over
           * from native input stylings don't interfere and make
           * the select div too wide
           */
          [`& + .${selectClasses.nativeInput}`]: {
            position: 'absolute',
          },
          /**
           * Custom className to set the text as gray when empty
           * @note .MuiSelect-empty is not an official className
           */
          [`& > .MuiSelect-empty`]: {
            color: brandColors.coolGray5,
          },
          '&.Mui-disabled': {
            backgroundColor: brandColors.coolGray1,
            cursor: 'no-drop !important',
          },
        },
        icon: {
          color: BASE_THEME.palette.primary.main,
          /**
           * Use parent FormControl status to determine
           * color of svg icon
           */
          '.Mui-error &': {
            color: BASE_THEME.palette.error.main,
          },
        },
      },
    },
    /**
     * @name StepLabel
     * @see https://mui.com/material-ui/api/step-label/
     */
    MuiStepLabel: {
      styleOverrides: {
        labelContainer: {
          color: brandColors.black,
        },
        root: {
          padding: 0,
        },
      },
    },
    /**
     * @name Stepper
     * @see https://mui.com/material-ui/react-stepper/
     */
    MuiStepper: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    /**
     * @name Switch
     * @see https://material-ui.com/api/switch/
     */
    MuiSwitch: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          width: '28px',
          height: '16px',
          paddingLeft: '4px',
          paddingRight: 0,
          marginRight: '10px',
          overflow: 'visible',
          left: '-2px',
        },
        switchBase: {
          color: '#A9B2BD',
          padding: 0,
          left: 2,
          '&:hover': {
            backgroundColor: 'inherit',
          },
          '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: brandColors.skyBlue6,
            [`& + .${switchClasses.track}`]: {
              borderColor: brandColors.skyBlue6,
              backgroundColor: brandColors.skyBlue6,
              opacity: 1,
            },
            [`& .${switchClasses.thumb}`]: {
              color: brandColors.skyBlue6,
              borderColor: brandColors.skyBlue6,
            },
            [`&.Mui-disabled + .${switchClasses.track}`]: {
              borderColor: brandColors.coolGray4,
            },
            [`&.Mui-disabled .${switchClasses.thumb}`]: {
              borderColor: brandColors.coolGray4,
              backgroundColor: brandColors.coolGray4,
            },
          },
          [`&:focus-within:not(:disabled) + .${switchClasses.track}`]: {
            boxShadow: `0 0 0 2px ${brandColors.skyBlue2}`,
          },
          '&.Mui-disabled': {
            [`& + .${switchClasses.track}`]: {
              borderColor: brandColors.coolGray4,
              backgroundColor: brandColors.coolGray4,
              opacity: 1,
            },
            [`& .${switchClasses.thumb}`]: {
              borderColor: brandColors.coolGray4,
            },
          },
        },
        colorSecondary: {
          '&.Mui-checked': {
            [`& + .${switchClasses.thumb}`]: {
              backgroundColor: brandColors.skyBlue6,
            },
          },
        },
        thumb: {
          color: brandColors.coolGray1,
          border: `2px solid ${brandColors.coolGray5}`,
          height: '16px',
          width: '16px',
          boxShadow: 'none',
          marginTop: '5px',
        },
        track: {
          backgroundColor: brandColors.coolGray5,
          border: `1px solid ${brandColors.coolGray5}`,
          borderRadius: '14px',
          width: '26px',
          opacity: 1,
        },
        input: {
          [`&:focus:not(:disabled) + .${switchClasses.thumb}`]: {
            boxShadow: `0 0 0 2px ${brandColors.skyBlue2}`,
          },
        },
      },
    },

    /**
     * @name TableCell
     * @see https://material-ui.com/api/table-cell/
     */
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: BASE_THEME.spacing(1.5),
          lineHeight: calcRem(19),
        },
        head: {
          color: brandColors.coolGray5,
          backgroundColor: brandColors.white,
          lineHeight: calcRem(19),
          borderBottom: `1px solid ${brandColors.coolGray4}`,
          fontWeight: OPTIONS.fontWeightBold,
        },
        stickyHeader: {
          backgroundColor: brandColors.white,
        },
      },
    },
    /**
     * @name Tabs
     * @see https://material-ui.com/api/tabs/
     */
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${brandColors.coolGray3}`,
        },
        scrollButtons: {
          color: brandColors.coolGray8,

          '&:hover': {
            color: brandColors.skyBlue6,
          },
        },
      },
    },
    /**
     * @name Tab
     * @see https://material-ui.com/api/tab/
     */
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: OPTIONS.fontWeightBold,
          textTransform: 'none',

          '&:hover': {
            color: brandColors.skyBlue6,
          },
        },
        textColorPrimary: {
          color: brandColors.coolGray8,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        InputProps: {
          notched: false,
        },
        InputLabelProps: {
          shrink: true,
          disableAnimation: true,
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'h4',
          h5: 'h5',
          h6: 'h6',
          subtitle1: 'h6',
          subtitle2: 'h6',
        },
      },
      styleOverrides: {
        // colorPrimary: {
        //   color: brandColors.skyBlue6,
        // },
        root: {
          '&.MuiTypography-h4': {
            fontFamily: rogersHeaderFontFamily,
          },
        },
      },
    },
    /**
     * @name ToolTip
     * @see https://v4.mui.com/api/tooltip/
     */
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: calcRem(12),
          backgroundColor: getBrandColor('coolGray8').alpha(0.9).toString(),
        },
        arrow: {
          color: getBrandColor('coolGray8').alpha(0.9).toString(),
        },
      },
    },
    /**
     * @name DatePicker
     * @see https://material-ui-pickers.dev/api/DatePicker
     */
    // @ts-ignore
    MuiPickersCalendarHeader: {
      styleOverrides: {
        iconButton: {
          borderRadius: OPTIONS.borderRadius,
          color: brandColors.skyBlue6,
          '&.Mui-disabled, &:active, &:focus': {
            backgroundColor: 'transparent !important',
            border: 0,
            boxShadow: 'none',
          },
          '&:focus': {
            color: brandColors.skyBlue6,
          },
          '&:hover': {
            backgroundColor: `${brandColors.coolGray1} !important`,
            boxShadow: 'none',
            color: brandColors.skyBlue7,
          },
        },
        transitionContainer: {
          height: '35px',
          '& p': {
            fontWeight: OPTIONS.fontWeightBold,
          },
        },
      },
    },
    /**
     * @name DatePicker
     * @see https://material-ui-pickers.dev/api/DatePicker
     */
    // @ts-ignore
    MuiPickersDay: {
      styleOverrides: {},
    },
    MuiPickersBasePicker: {
      styleOverrides: {
        container: {
          padding: '10px',
        },
      },
    },
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          padding: 10,
          '& .MuiPickersCalendarHeader-labelContainer': {
            position: 'absolute',
            top: 6,
            width: 'auto',
            display: 'flex',
            justifyContent: 'center',
            left: '50%',
            transform: 'translate(-50%)',
          },
          '& .MuiPickersArrowSwitcher-root': {
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
          },
          '& .MuiPickersCalendarHeader-root': {
            position: 'relative',
            display: 'flex',
            marginTop: 8,
            alignItems: 'center',
            marginBottom: 8,
            justifyContent: 'space-between',
            padding: 0,
          },
          '& .MuiPickersArrowSwitcher-button': {
            color: brandColors.skyBlue6,
            padding: 12,
            transition: 'none',

            '&.Mui-disabled': {
              backgroundColor: `${brandColors.white} !important`,
              color: `${brandColors.coolGray6} !important`,
              borderColor: `${brandColors.white} !important`,
            },

            '&:hover': {
              transition: 'none',
              color: brandColors.skyBlue7,
            },
          },
          '& .MuiDayCalendar-weekDayLabel': {
            color: 'rgba(0, 0, 0, 0.38)',
          },
          '& .MuiPickersCalendarHeader-label': {
            fontWeight: 700,
            fontSize: calcRem(18),
            lineHeight: calcRem(25),

            /** Desktop */
            [BASE_THEME.breakpoints.up('md')]: {
              fontSize: calcRem(16),
              lineHeight: calcRem(22),
            },
          },
          '& .MuiPickersDay-root': {
            borderRadius: OPTIONS.borderRadius,
            transition: 'none',
            fontWeight: 400,
            fontSize: calcRem(16),
            lineHeight: calcRem(22),

            '&.Mui-disabled': {
              backgroundColor: `${brandColors.white} !important`,
              color: `${brandColors.coolGray6} !important`,
              borderColor: `${brandColors.white} !important`,
            },

            /** Desktop */
            [BASE_THEME.breakpoints.up('md')]: {
              fontSize: calcRem(14),
              lineHeight: calcRem(19),
            },

            '&:focus, &:hover': {
              border: `1px solid ${brandColors.skyBlue6}`,
              boxShadow: 'none',
              transition: 'none',
            },

            '&:hover': {
              backgroundColor: 'transparent !important',
              transition: 'none',
            },

            '&:active': {
              backgroundColor: `${brandColors.skyBlue6} !important`,
              color: brandColors.white,
            },

            '&.MuiPickersDay-today': {
              color: brandColors.coolGray8,
              borderColor: 'transparent',
              fontWeight: OPTIONS.fontWeightBold,
            },

            '&.Mui-selected': {
              borderRadius: OPTIONS.borderRadius,
              color: brandColors.white,
              transition: 'none',

              '&:active, &:focus, &:hover': {
                transition: 'none',
                backgroundColor: `${brandColors.skyBlue6} !important`,
                color: brandColors.white,
              },
            },
          },
        },
      },
    },
    MuiPickersPopper: {
      styleOverrides: {
        paper: {
          marginTop: 8,
        },
      },
    },
  },
})
