import React from 'react'
import { render } from 'react-dom'
import uuid from 'uuid/v4'
import AppContainer from './AppContainer'
import 'core-js'
import 'regenerator-runtime/runtime'
import 'scss/3_generic/_generic.scss'
import 'scss/4_base/_base.scss'

const renderApp = (Component: React.ComponentType) =>
  render(<Component key={uuid()} />, document.querySelector('#app'))

renderApp(AppContainer)
