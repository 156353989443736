import { Dispatch, Middleware, MiddlewareAPI } from 'redux'
import { IGlobalMessagingAction } from 'types/globalMessaging'
import { ACTION_TYPES, hideMessage } from 'utils/actions/globalMessaging'

const { SHOW_MESSAGE } = ACTION_TYPES

export const globalMessaging: Middleware = (store: MiddlewareAPI) => (next: Dispatch) => (
  action: IGlobalMessagingAction
) => {
  if (action.type !== SHOW_MESSAGE) return next(action)

  const { closeDelay, id } = action.payload ?? {}
  if (closeDelay && id) {
    setTimeout(() => {
      store.dispatch(hideMessage(id))
    }, closeDelay)
  }

  return next(action)
}
