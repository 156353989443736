import React, { ChangeEvent, RefObject, forwardRef, useRef } from 'react'
import classNames from 'classnames'
import { TextInputIconType } from 'components/atoms/TextInput'
import {
  TextField as MuiTextField,
  OutlinedTextFieldProps,
  makeStyles,
  createStyles,
  InputLabelProps as MuiInputLabelProps,
  FormHelperTextProps as MuiFormHelperTextProps,
} from 'transfix-ui/core'
import {
  SearchIcon,
  LocationFilledIcon,
  ClockIcon,
  PriceIcon,
  DryVanIcon,
  DropTrailerIcon,
  NumberIcon,
  PlusIcon,
  PeopleIcon,
  CustomerIcon,
  CalendarIcon,
} from 'transfix-icons'
import { brandColors } from '../../theme'
import { FormControl } from '../FormControl'
import { EndAdornment } from './components/EndAdornment'
import { StartAdornment } from './components/StartAdornment'
import { usePasswordVisibility } from './hooks/usePasswordVisibility'
import { getEndAdornmentIcon } from './utils/getEndAdornmentIcon'

const INPUT_ICONS: Record<TextInputIconType, JSX.Element> = {
  calendar: <CalendarIcon size='large' />,
  search: <SearchIcon size='large' />,
  locationPin: <LocationFilledIcon size='large' />,
  clock: <ClockIcon size='large' />,
  price: <PriceIcon size='large' />,
  dryVanIcon: <DryVanIcon size='large' />,
  dropTrailerIcon: <DropTrailerIcon size='large' />,
  numberIcon: <NumberIcon size='large' />,
  plus: <PlusIcon size='large' color='skyBlue6' />,
  people: <PeopleIcon size='large' />,
  customer: <CustomerIcon size='large' />,
}

export interface ITextFieldProps extends Omit<OutlinedTextFieldProps, 'variant'> {
  /**
   * When enabled, adds an "X" icon to endAdornment, which clears input
   * @default false
   */
  clearable?: boolean
  /**
   * Callback fn after clicking on clearable "X" button
   */
  onClearCallback?: () => void
  validationMessage?: string
  helperMessage?: string
  icon?: keyof typeof TextInputIconType
  /**
   * @default false
   */
  large?: boolean
  /**
   * Adds highlighted background color
   */
  highlighted?: 'info' | 'warning'
  InputLabelProps?: MuiInputLabelProps
  FormHelperTextProps?: MuiFormHelperTextProps
  dataTest?: string
  inputRef?: RefObject<HTMLInputElement>
  /**
   * When true, adds the ViewOn/ViewOff icon to the endAdornment, which will
   * hide or show the password
   * @default false
   */
  isPassword?: boolean
}

type TClassKey =
  | 'input'
  | 'inputLarge'
  | 'inputMedium'
  | 'highlightedInfo'
  | 'highlightedWarning'
  | 'inputDatePickerMedium'

export const useTextFieldStyles = makeStyles(theme =>
  createStyles<TClassKey, ITextFieldProps>({
    input: {
      '& .MuiInputBase-input': {
        // Overrides the styles found in _form.scss for input[type="text"] borderColor
        '.Mui-error &:focus': {
          borderColor: `${theme.palette.error.main} !important`,
        },
        // Overrides the styles found in _form.scss for input[type="text"] borderColor
        '.Mui-error &:hover': {
          borderColor: `${theme.palette.error.main} !important`,
        },
      },
    },
    inputLarge: {
      '& .MuiInputBase-input': {
        // Overrides the styles found in _form.scss for input[type="text"] padding
        padding: `${theme.spacing(1.5)} !important`,
        height: '1.143em',
      },

      '& .MuiInputAdornment-root .MuiButton-root': {
        width: 32,
      },
    },
    inputMedium: {
      '& .MuiInputBase-input': {
        padding: `${theme.spacing(0.5)} !important`,
        paddingLeft: `${theme.spacing(1)} !important`,
        paddingRight: `${theme.spacing(1)} !important`,
        height: 24,
      },
    },
    inputDatePickerMedium: {
      '& .MuiInputBase-input': {
        padding: `calc(${theme.spacing(1)} - 2px)`,
        paddingLeft: `${theme.spacing(0.5)} !important`,
        paddingRight: `${theme.spacing(1.25)} !important`,
        height: 20,
      },
      '& .MuiInputAdornment-root .MuiButton-root': {
        width: 32,
      },
    },
    // Overrides the styles found in _form.scss for input[type="text"] backgroundColor
    highlightedInfo: {
      '& .MuiInputBase-input': {
        background: `${brandColors.infoLight} !important`,
        borderColor: `${brandColors.coolGray5} !important`,
      },
    },
    highlightedWarning: {
      '& .MuiInputBase-input': {
        backgroundColor: `${brandColors.warningLight} !important`,
      },
    },
  })
)

/**
 * @note For rendering a raw HTML input only
 */
export const TextFieldRawComponent = forwardRef<HTMLDivElement, ITextFieldProps>(
  (
    {
      InputProps,
      clearable: clearableProp,
      dataTest,
      disabled: disabledProp,
      error: errorProp,
      highlighted,
      icon,
      large,
      name,
      onChange,
      onClearCallback: onClearCallbackProp,
      ...props
    },
    ref
  ) => {
    const { inputProps, ...InputPropsWithoutInputProps } = InputProps || {}
    const { className: inputClassName } = inputProps || {}
    const classes = useTextFieldStyles(props)

    const clearHandler = () => {
      if (onChange && clearableProp) {
        onChange({ target: { name, value: '' } } as ChangeEvent<HTMLInputElement>)
        if (onClearCallbackProp) {
          onClearCallbackProp()
        }
      }
    }

    return (
      <MuiTextField
        {...props}
        onChange={onChange}
        ref={ref}
        name={name}
        variant='outlined'
        disabled={disabledProp}
        error={errorProp}
        classes={{
          root: classNames(inputClassName, classes.input, {
            [classes.inputLarge]: large,
            [classes.inputMedium]: !large,
            [classes.highlightedInfo]: highlighted === 'info',
            [classes.highlightedWarning]: highlighted === 'warning',
          }),
        }}
        InputProps={{
          inputProps: {
            'data-test': dataTest,
            ...inputProps,
          },
          startAdornment: icon ? (
            <StartAdornment icon={INPUT_ICONS[icon]} invalid={errorProp} disabled={disabledProp} />
          ) : undefined,
          endAdornment: clearableProp ? (
            <EndAdornment onClick={clearHandler} invalid={errorProp} disabled={disabledProp} />
          ) : undefined,
          ...InputPropsWithoutInputProps,
        }}
      />
    )
  }
)

/**
 * @name TextField
 * @see https://material-ui.com/api/text-field/
 * @example <TextField value='Joe Driver' />
 */
export const TextField = forwardRef<HTMLDivElement, ITextFieldProps>(
  (
    {
      disabled: disabledProp = false,
      error: errorProp = false,
      required: requiredProp = false,
      clearable: clearableProp = false,
      isPassword = false,
      onClearCallback: onClearCallbackProp,
      large = false,
      highlighted,
      label: labelProp,
      validationMessage,
      helperMessage,
      onChange,
      icon,
      id: idProp,
      InputLabelProps = {},
      InputProps = {},
      FormHelperTextProps = {},
      dataTest,
      name,
      type,
      ...props
    },
    ref
  ) => {
    const id = useRef(idProp)
    const classes = useTextFieldStyles(props)
    const { inputType, isPasswordVisible, togglePasswordVisibility } = usePasswordVisibility({
      type,
      isPassword,
    })

    const clearHandler = () => {
      if (onChange && clearableProp) {
        onChange({ target: { name, value: '' } } as ChangeEvent<HTMLInputElement>)
        if (onClearCallbackProp) {
          onClearCallbackProp()
        }
      }
    }

    const endAdornmentIcon = getEndAdornmentIcon({
      isClearable: clearableProp,
      isPassword,
      isPasswordVisible,
    })

    const endAdornmentClickHandler = isPassword ? togglePasswordVisibility : clearHandler

    // Since InputProps contains some nested props, we need to spread them separately
    const { inputProps, ...InputPropsWithoutInputProps } = InputProps

    return (
      <FormControl
        required={requiredProp}
        disabled={disabledProp}
        error={errorProp}
        label={labelProp}
        validationMessage={validationMessage}
        helperMessage={helperMessage}
        InputLabelProps={{
          htmlFor: id.current,
          ...InputLabelProps,
        }}
        FormHelperTextProps={FormHelperTextProps}>
        <TextFieldRawComponent
          {...props}
          ref={ref}
          clearable={clearableProp}
          onClearCallback={onClearCallbackProp}
          large={large}
          disabled={disabledProp}
          error={errorProp}
          highlighted={highlighted}
          onChange={onChange}
          icon={icon}
          id={id.current}
          dataTest={dataTest}
          name={name}
          type={inputType}
          InputProps={{
            inputProps: {
              className: classNames(InputProps.inputProps?.className, classes.input, {
                [classes.inputLarge]: large,
                [classes.inputMedium]: !large,
                [classes.highlightedInfo]: highlighted === 'info',
                [classes.highlightedWarning]: highlighted === 'warning',
              }),
              'data-test': dataTest,
              ...inputProps,
            },
            startAdornment: icon ? (
              <StartAdornment
                icon={INPUT_ICONS[icon]}
                invalid={errorProp}
                disabled={disabledProp}
              />
            ) : undefined,
            endAdornment: endAdornmentIcon ? (
              <EndAdornment
                Icon={endAdornmentIcon}
                invalid={errorProp}
                disabled={disabledProp}
                onClick={endAdornmentClickHandler}
              />
            ) : undefined,
            ...InputPropsWithoutInputProps,
          }}
        />
      </FormControl>
    )
  }
)

export default TextField
