import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
import { globalMiddleware } from 'utils/middleware'

const emptyReducer = () => ({})

export const configureStore = () => {
  const middleware = [thunkMiddleware, ...globalMiddleware]
  const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 30 })
  const composedEnhancers = composeEnhancers(applyMiddleware(...middleware))
  return createStore(emptyReducer, {}, composedEnhancers)
}
