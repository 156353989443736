import React, { FC } from 'react'
import { makeStyles, SvgIcon, SvgIconTypeMap, OverrideProps } from 'transfix-ui/core'

interface ISelectToggleIconProps extends OverrideProps<SvgIconTypeMap, 'svg'> {}

// All Material UI SvgIcon elements are 24 x 24
const TOTAL_HEIGHT = 24
const TOTAL_WIDTH = 24

// Custom Rogers icon is a bit smaller
const ICON_HEIGHT = 8
const ICON_WIDTH = 14

// Divides difference by two to center icon
const OFFSET_Y = `${(TOTAL_HEIGHT - ICON_HEIGHT) / 2}px`
const OFFSET_X = `${(TOTAL_WIDTH - ICON_WIDTH) / 2}px`

const useIconStyles = makeStyles({
  inner: {
    width: ICON_WIDTH,
    height: ICON_HEIGHT,
    transform: `translateY(${OFFSET_Y}) translateX(${OFFSET_X})`,
  },
})

/**
 * Renders custom toggle icon for <Select />
 */
export const SelectToggleIcon: FC<ISelectToggleIconProps> = props => {
  const classes = useIconStyles()
  return (
    <SvgIcon {...props}>
      <g
        id='Page-1'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        className={classes.inner}>
        <g
          id='Inputs'
          transform='translate(-1219.000000, -2263.000000)'
          fill='currentColor'
          fillRule='nonzero'>
          <g id='Group-2' transform='translate(1219.000000, 2263.000000)'>
            <path
              d='M7,5.58578644 L7.70710678,6.29289322 C8.09763107,6.68341751 8.09763107,7.31658249 7.70710678,7.70710678 C7.31658249,8.09763107 6.68341751,8.09763107 6.29289322,7.70710678 L0.292893219,1.70710678 C-0.0976310729,1.31658249 -0.0976310729,0.683417511 0.292893219,0.292893219 C0.683417511,-0.0976310729 1.31658249,-0.0976310729 1.70710678,0.292893219 L7,5.58578644 L12.2928932,0.292893219 C12.6834175,-0.0976310729 13.3165825,-0.0976310729 13.7071068,0.292893219 C14.0976311,0.683417511 14.0976311,1.31658249 13.7071068,1.70710678 L7.70710678,7.70710678 C7.31658249,8.09763107 6.68341751,8.09763107 6.29289322,7.70710678 C5.90236893,7.31658249 5.90236893,6.68341751 6.29289322,6.29289322 L7,5.58578644 Z'
              id='icon-carrot_down'
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}

export default SelectToggleIcon
