import React from 'react'
import { CssBaseline } from 'transfix-ui/core'
import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider as MuiStyledEngineProvider,
  Theme,
  ThemeProviderProps,
} from 'transfix-ui/core/styles'
import rogersTheme from './theme'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

interface IThemeProviderProps extends Pick<ThemeProviderProps, 'children'> {}

/**
 * Provides Material UI theme configuration for all child elements.
 * By default uses one theme, but can be configured for more.
 */
export function ThemeProvider({ children }: IThemeProviderProps) {
  return (
    <MuiStyledEngineProvider injectFirst>
      <MuiThemeProvider theme={rogersTheme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </MuiStyledEngineProvider>
  )
}
