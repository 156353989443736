import React, { ReactElement, FC } from 'react'
import classNames from 'classnames'
import { createStyles, makeStyles } from 'transfix-ui/core'
import { calcRem } from 'transfix-ui/theme/typography'

interface ICardHeader extends JSX.ElementChildrenAttribute {
  dataTest?: string
  className?: string
  actionButton?: ReactElement
}

const useCardHeaderStyles = makeStyles(() =>
  createStyles({
    cardHeader: {
      h2: {
        fontWeight: 'normal',
        fontSize: calcRem(20),
      },
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  })
)

/**
 * Adds styled header element to <Card />
 * @example
 * ```
 * <Card>
 *     <CardHeader actionButton={<Button onClick={() => {}}>EDIT</Button>}>
 *       <Typography variant='h2'>Title here</Typography>
 *     </CardHeader>
 *     <div>Your own content here</div>
 *   </Card>
 * ```
 */
export const CardHeader: FC<ICardHeader> = ({
  actionButton,
  children,
  className,
  dataTest = '',
}) => {
  const classes = useCardHeaderStyles()

  return (
    <div className={classNames(classes.cardHeader, className)} data-test={dataTest}>
      {children}
      {actionButton && <div>{actionButton}</div>}
    </div>
  )
}

export default CardHeader
