import React from 'react'
import { Provider } from 'react-redux'
import { getApiUrl } from 'utils/api/apiHelpers'
import { RogersRestfulProvider } from 'components/RogersRestfulProvider'
import App from './components/App'
import { configureStore } from './utils/configureStore'

/**
 * None of these routes require prior authentication
 */
const noOp = () => {}

const BASE_API_URL = getApiUrl('MARKETPLACE')

const store = configureStore()

const AuthenticationPages = () => (
  <Provider store={store}>
    <RogersRestfulProvider baseApi={BASE_API_URL} onUnauthenticatedResponse={noOp}>
      <App />
    </RogersRestfulProvider>
  </Provider>
)

export default AuthenticationPages
