import React, { FunctionComponent } from 'react'
import { BrowserRouter, HashRouter } from 'react-router-dom'

interface IRouterProps {
  basename?: string
}

export const Router: FunctionComponent<IRouterProps> = props => {
  const { basename = '' } = props
  if (__STAGE__ && !__STAGING_NEW__) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <HashRouter {...props} basename={basename} />
  }
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <BrowserRouter {...props} basename={basename} />
}
