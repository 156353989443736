import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Spinner } from 'transfix-ui/components/Spinner'

export class RedirectTo extends Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { history, openNewTab, url } = this.props
    // Pop ups for prop.url must be enabled to successfully open a new tab
    // https://support.google.com/chrome/answer/95472?co=GENIE.Platform%3DDesktop&hl=en
    if (openNewTab) {
      history.goBack()
      window.open(url)
    } else {
      window.location.replace(url)
    }
  }

  render() {
    const { url } = this.props
    return (
      <section className='container tc'>
        <Spinner />
        <Link to={url}>Click here if you see this message for too long</Link>
      </section>
    )
  }
}

RedirectTo.propTypes = {
  url: PropTypes.string,
  openNewTab: PropTypes.bool,
  history: PropTypes.shape({}),
}

RedirectTo.defaultProps = {
  url: '',
  openNewTab: false,
  history: {},
}
