import Color from 'color'
import get from 'lodash/get'

const newColors = {
  skyBlue0: '#EDF8FE',
  skyBlue1: '#E0F3FD',
  skyBlue2: '#BEE7FD',
  skyBlue3: '#8FCAE9',
  skyBlue4: '#5BACD7',
  skyBlue5: '#2B93CA',
  skyBlue6: '#0072B5',
  skyBlue7: '#025187',
  gold0: '#FDF0C7',
  gold1: '#F2CC57',
  gold2: '#F5BF18',
  green0: '#D6F0E2',
  green1: '#B7E5CC',
  green2: '#56B784',
  green3: '#226342',
  error0: '#FCE8E8',
  error1: '#BD1A1D',
  hotButtonHover: '#941517',
  hotButtonFocus: '#FED8D1',
  warning0: '#FFEAD7',
  warning1: '#D76106',
  coolGray0: '#F7F8FA',
  coolGray1: '#F3F5F7',
  coolGray2: '#EBEEF1',
  coolGray3: '#D3D9E0',
  coolGray4: '#A9B2BD',
  coolGray5: '#697684',
  coolGray6: '#394657',
  coolGray7: '#22282B',
  coolGray8: '#0D0E10',
  gray0: '#FAFAFA',
  gray1: '#DFDFDF',
  gray2: '#BCBCBC',
  gray3: '#727272',
  gray4: '#333333',
  gray5: '#050505',
  purple0: '#F2ECF5',
  purple1: '#763B93',
}

export const brandColors = {
  black: '#000',
  white: '#fff',
  transparent: 'rgba(0, 0, 0, 0)',
  transparentWhite: 'rgba(255, 255, 255, 0)',
  defaultPurple: '#800080',
  lightPurple: '#925EB1',
  centerline: newColors.gold2,
  activeBlue: '#2298fe',
  error: newColors.error1,
  errorLight: newColors.error0,
  info: newColors.skyBlue6,
  infoLight: newColors.skyBlue0,
  infoDark: newColors.skyBlue7,
  infoMedium: newColors.skyBlue2,
  warning: newColors.warning1,
  warningLight: newColors.warning0,
  badgeRed: '#F44336',
  ...newColors,
}

export type TBrandColor = keyof typeof brandColors

export const avatarColors = {
  orange: '#e5ae65',
  salmonRed: '#e38e76',
  roseRed: '#cc7c7d',
  bubblegumPink: '#d688a2',
  plumPurple: '#a46fad',
  lavenderPurple: '#8e7bb2',
  steelBlue: '#7d86b0',
  iceBlue: '#74aedb',
  spruceGreen: '#569c95',
  artichokeGreen: '#84ad86',
} as const

export type TAvatarColor = keyof typeof avatarColors

/**
 * Returns a Color object based on the brandColor
 */
export const getBrandColor = (colorName: TBrandColor): Color<string> => {
  const colorValue = get(brandColors, colorName, brandColors.coolGray8)
  return Color(colorValue)
}

export const errorHover = getBrandColor('error').mix(getBrandColor('white'), 0.75).hex()
