import React from 'react'
import SvgIcon from 'transfix-ui/components/SvgIcon'
import { LocationFilledSvg } from '../icons'

const LocationFilledIcon = props => {
  return (
    <SvgIcon className='RogersSvgIcon-LocationFilled' component={LocationFilledSvg} {...props} />
  )
}

export default LocationFilledIcon
