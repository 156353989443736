export const getDownloadUrl = (queryString: URLSearchParams, isIOSDevice: boolean): string => {
  const utmSource = queryString.get('utm_source') ?? 'unknown_source'
  const utmMedium = queryString.get('utm_medium') ?? 'unknown_medium'
  const utmContent = queryString.get('utm_content') ?? 'unknown_content'
  const utmCampaign = queryString.get('utm_campaign') ?? 'unknown_campaign'
  const appId = queryString.get('app_id') ?? 935682564
  const pt = queryString.get('pt') ?? 112500818
  const mt = queryString.get('mt') ?? 8

  return isIOSDevice
    ? `https://click.google-analytics.com/redirect?tid=UA-47720775-2&url=https%3A%2F%2Fitunes.apple.com%2Fapp%2Fapple-store%2Fid${appId}&aid=io.transfix.TransfixDriver&idfa={idfa}&cs=${utmSource}&cm=${utmMedium}&cn=${utmContent}&cc=${utmCampaign}&pt=${pt}&ct=${utmCampaign}&mt=${mt}&hash=md5`
    : `https://play.google.com/store/apps/details?id=io.app.transfix&referrer=utm_source%3D${utmSource}%26utm_medium%3D${utmMedium}&utm_campaign%3D${utmCampaign}`
}
