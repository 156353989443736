import { DISPLAY } from 'constants/display'

export const parseError = (data: any) => {
  if (!data) return DISPLAY.genericError
  if (typeof data === 'string') return data
  if (data.error) return data.error
  if (typeof data.errors === 'string') return data.errors
  if (!data.errors) return DISPLAY.genericError
  const errorsArray = Array.isArray(data.errors) ? data.errors : Object.values(data.errors)
  const errorString = errorsArray.join(', ')
  return errorString
}

export const getError = (error: any) => {
  if (typeof error === 'string') {
    return error
  }
  if (error.data && error.data.errors && Array.isArray(error.data.errors)) {
    return error.data.errors.join(',')
  }
  return error.message
}
