import { DefaultValues, useForm, UseFormOptions } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { ResetPasswordSchema, TResetPasswordSchema } from '../schemas'

export const getDefaultValues = (
  values: DefaultValues<TResetPasswordSchema>
): DefaultValues<TResetPasswordSchema> => {
  return ResetPasswordSchema.cast({
    ...values,
  })
}

interface IUseResetPasswordFormProps
  extends Omit<UseFormOptions<TResetPasswordSchema>, 'resolver'> {}

export const useResetPasswordForm = ({
  defaultValues = {},
  ...props
}: IUseResetPasswordFormProps) => {
  return useForm<TResetPasswordSchema>({
    resolver: yupResolver(ResetPasswordSchema),
    defaultValues: getDefaultValues(defaultValues),
    ...props,
  })
}
