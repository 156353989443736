import React from 'react'
import { useLocation } from 'react-router-dom'
import { getDownloadUrl } from 'utils/getDownloadUrl'
import { RedirectTo } from './RedirectTo'

const useQueryString = () => {
  const location = useLocation()
  return new URLSearchParams(location.search)
}

export const DownloadPage = () => {
  const { userAgent } = navigator
  const isIOSDevice = /iPhone|iPad|iPod/i.test(userAgent)

  const queryString = useQueryString()
  const url = getDownloadUrl(queryString, isIOSDevice)
  return <RedirectTo url={url} />
}
