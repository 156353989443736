import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { UpArrowIcon } from 'transfix-icons'

export const LegacyTableHeader = memo(({ columns, onSort, sortField, sortOrder }) => {
  const onClick = ({ target: { value: field } }) =>
    onSort({
      sortField: field,
      sortOrder: field === sortField && sortOrder === 'desc' ? 'asc' : 'desc',
    })
  return (
    <div className='table-header-v2 hide-on-small-only'>
      {columns.map(({ key, label, size, sortable }) => (
        <div className={size} key={key}>
          {!sortable ? (
            <span>{label}</span>
          ) : (
            <button
              className={`btn-text-link sort-action ${key === sortField ? `${sortOrder} b` : ''}`}
              value={key}
              onClick={onClick}>
              <span className={key === sortField ? 'b' : ''}>{label}</span>
              {key === sortField && <UpArrowIcon />}
            </button>
          )}
        </div>
      ))}
    </div>
  )
})

export const LegacyTableRow = memo(({ cells, columns }) => (
  <>
    <div className='row collapse table-row'>
      {columns.map(({ key, size }) => (
        <div className={size} key={key}>
          {cells[key]}
        </div>
      ))}
    </div>
    <hr className='v2' />
  </>
))

const columnProp = PropTypes.shape({
  key: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  label: PropTypes.string,
  sortable: PropTypes.bool,
})

LegacyTableRow.propTypes = {
  columns: PropTypes.arrayOf(columnProp).isRequired,
  cells: PropTypes.shape({}).isRequired,
}

LegacyTableHeader.propTypes = {
  columns: PropTypes.arrayOf(columnProp).isRequired,
  sortField: PropTypes.string,
  sortOrder: PropTypes.string,
  onSort: PropTypes.func,
}

LegacyTableHeader.defaultProps = {
  sortField: null,
  sortOrder: null,
  onSort: () => {},
}
