export const DISPLAY = {
  na: 'N/A',
  emptyDateTime: '–– : ––',
  emptyDate: 'MM/DD/YYYY',
  empty: '––',
  emptyDash: '--',
  emptyUnderline: '________________________',
  genericError: 'Sorry, there was an error. Please try again.',
  formSubmitError:
    'Sorry, something went wrong. Please make sure the data you entered is valid and try again.',
  missingField: 'Error, one or more fields are missing.',
  shortErrorMessage: 'Error, please try again',
  shipmentDataError: 'Error getting shipment data.',
  authError:
    'We were unable to locate your account. Please try again or contact support@transfix.io.',
  authVerifyError:
    'This code is incorrect. Please try again or go back to verify your phone number.',
  success: 'Success!',
  statusUpdateSuccess: 'Your shipment status has been updated.',
  podUploadSuccess: 'Your POD has been added to the shipment.',
  loadingMessage: 'Loading...',
  reviewMessage: {
    false: 'Not yet submitted / reviewed',
    true: 'Reviewed by:',
  },
  inputHeight: 54,
  none: 'None',
  noResults: "Sorry, we couldn't find any matches.",
  botName: 'Ima Bot',
  emailSent: 'Sent! Check your email inbox.',
  yes: 'Yes',
  no: 'No',
  horizontalToastLimit: 300,
  missingTruck: 'Missing truck number',
  missingTrailer: 'Missing trailer number',
  missingHighwayData: 'No Data',
  unknown: 'Unknown',
}

export default DISPLAY
