import { handleNotification, NOTIFICATION_ACTION_TYPES } from 'utils/actions/notification'
import { SHIPMENT_UPDATE_ACTION_TYPES, UNRESPONSIVE_ACTION_TYPES } from 'utils/actions/shipments'
import { SEND_SMS_ACTION_TYPES } from 'utils/actions/sms'
import { handleSidebar } from 'apps/booking/utils/actions/sidebar'

export const notification = store => next => async action => {
  let message
  const { NOTIFICATION_SHOW } = NOTIFICATION_ACTION_TYPES
  const { SHIPMENT_UPDATE_FAILURE, SHIPMENT_UPDATE_SUCCESS } = SHIPMENT_UPDATE_ACTION_TYPES
  const { SEND_SMS_FAILURE, SEND_SMS_SUCCESS } = SEND_SMS_ACTION_TYPES
  const { UNRESPONSIVE_FAILURE, UNRESPONSIVE_SUCCESS } = UNRESPONSIVE_ACTION_TYPES
  if (
    ![
      SHIPMENT_UPDATE_SUCCESS,
      SHIPMENT_UPDATE_FAILURE,
      SEND_SMS_SUCCESS,
      SEND_SMS_FAILURE,
      UNRESPONSIVE_SUCCESS,
      UNRESPONSIVE_FAILURE,
    ].includes(action.type)
  )
    return next(action)

  const {
    payload: {
      requestData: {
        id,
        body: {
          shipment: {
            carrierAssignmentsAttributes,
            info = {},
            shipper_rate_confirmed_by_id: shipperRateConfirmedById = null,
            stops = [],
            shipmentCeTasksAttributes = [],
          } = {},
          statusChangeKey = '',
        } = {},
      } = {},
      message: payloadMessage = '',
    } = {},
  } = action

  // statusChangeKey is sent for updateStatus only (which only applies for dispatching)
  // we sent to the next action because the updateShipment follows
  if (statusChangeKey) return next(action)
  message = { success: 'Shipment successfully updated', rejected: 'Shipment update failed' }
  if (shipperRateConfirmedById)
    message = { success: 'Confirm shipment success', rejected: 'Confirm shipment rejected' }
  if (info && info.ops_rep_id)
    message = { success: 'Update OPs rep success', rejected: 'Update OPs rep rejected' }
  if (info && info.ce_rep_id)
    message = { success: 'Update CE rep success', rejected: 'Update CE rep rejected' }
  if (info && info.driver_id)
    message = { success: 'Update driver success', rejected: 'Update driver rejected' }
  if (info && info.vin) message = { success: 'Update VIN success', rejected: 'Update VIN rejected' }
  if (stops.length)
    message = {
      success: 'Update shipment status success',
      rejected: 'Update shipment status rejected',
    }
  if (shipmentCeTasksAttributes.length)
    message = {
      success: 'Task assignment update success',
      rejected: 'Task assignment update rejected',
    }

  if (action.type === SHIPMENT_UPDATE_FAILURE) {
    const infoMessageOptions = {
      message: message.rejected,
      type: NOTIFICATION_SHOW,
      cta: true,
      theme: 'error',
    }
    store.dispatch(handleNotification(infoMessageOptions))
  } else if (action.type === SHIPMENT_UPDATE_SUCCESS) {
    const infoMessageOptions = {
      message: message.success,
      type: NOTIFICATION_SHOW,
      cta: false,
      theme: 'success',
    }
    store.dispatch(handleNotification(infoMessageOptions))
  }
  if (action.type === SEND_SMS_SUCCESS) {
    const infoMessageOptions = {
      message: 'SMS Sent',
      type: NOTIFICATION_SHOW,
      cta: false,
      theme: 'success',
    }
    store.dispatch(handleNotification(infoMessageOptions))
  } else if (action.type === SEND_SMS_FAILURE) {
    const infoMessageOptions = {
      message: 'SMS Failed',
      type: NOTIFICATION_SHOW,
      cta: true,
      theme: 'error',
    }
    store.dispatch(handleNotification(infoMessageOptions))
  } else if (action.type === UNRESPONSIVE_SUCCESS) {
    const infoMessageOptions = {
      message: 'Unresponsive Count Updated',
      type: NOTIFICATION_SHOW,
      cta: false,
      theme: 'success',
    }
    store.dispatch(handleNotification(infoMessageOptions))
  } else if (action.type === UNRESPONSIVE_FAILURE) {
    const infoMessageOptions = {
      message: 'Unresponsive Count Failed',
      type: NOTIFICATION_SHOW,
      cta: true,
      theme: 'error',
    }
    store.dispatch(handleNotification(infoMessageOptions))
  }

  if (payloadMessage.includes('driver unresponsive count updated'))
    store.dispatch(handleSidebar(id, 'shipment'))
  if ((stops && stops.length) || carrierAssignmentsAttributes)
    store.dispatch(handleSidebar(id, 'shipment'))
  if (shipmentCeTasksAttributes && shipmentCeTasksAttributes.length)
    store.dispatch(handleSidebar(id, 'shipment'))

  return next(action)
}
