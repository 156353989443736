import React, { memo, ComponentType, ReactNode } from 'react'
import classNames from 'classnames'
import { TIconComponent } from 'transfix-icons/types'
import { Action, IActionProps } from './components/Action'
import { INLINE_MESSAGE_VARIANTS } from './constants'
import 'scss/5_components/_inline-messaging.scss'

export interface InlineMessageProps {
  message: string | ReactNode
  type?: INLINE_MESSAGE_VARIANTS
  cta?: IActionProps
  dataTest?: string
  fullWidth?: boolean
  title?: string
  className?: string
  /**
   * Pass a JSX element to act as a prefix
   */
  IconComponent?: ComponentType | TIconComponent
}

export const InlineMessage = memo<InlineMessageProps>(
  ({
    message,
    type = INLINE_MESSAGE_VARIANTS.INFO,
    cta,
    dataTest = '',
    fullWidth,
    title,
    className,
    IconComponent,
  }) => {
    const { action, label, type: ctaType, uploadAction, url } = cta || {}
    const classes = classNames('inline-message', type, className, {
      'inline-message-full-width': fullWidth,
    })
    const showCta = label && (action || uploadAction || url)

    return (
      <div className={classes} data-test={dataTest}>
        {IconComponent && (
          <div className='inline-message-icon-container'>
            <IconComponent />
          </div>
        )}
        <div>
          {title && <div className='inline-message-title'>{title}</div>}
          <span>{message}</span>{' '}
          {showCta && (
            <Action
              label={label}
              url={url}
              action={action}
              uploadAction={uploadAction}
              type={ctaType || type}
            />
          )}
        </div>
      </div>
    )
  }
)

export default InlineMessage
