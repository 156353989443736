import React, { memo } from 'react'
import { InputAdornment, makeStyles, createStyles } from 'transfix-ui/core'
import { getIconColor } from '../utils/getIconColor'

interface IStartAdornmentProps {
  /**
   * @default false
   */
  invalid?: boolean
  /**
   * @default false
   */
  disabled?: boolean
  icon: JSX.Element
  onClick?: () => void
}

type TClassKey = 'iconWrapper'

const useStartAdornmentStyles = makeStyles(() => {
  return createStyles<TClassKey, Pick<IStartAdornmentProps, 'invalid' | 'disabled'>>({
    iconWrapper: {
      color: getIconColor,
      height: 20,
      width: 20,
    },
  })
})

/**
 * Creates <InputAdornment /> for use with <TextField />
 */
export const StartAdornment = memo<IStartAdornmentProps>(
  ({ disabled = false, icon, invalid = false }) => {
    const classes = useStartAdornmentStyles({
      invalid,
      disabled,
    })
    return (
      <InputAdornment position='start' sx={{ position: 'relative' }}>
        <span className={classes.iconWrapper}>{icon}</span>
      </InputAdornment>
    )
  }
)

export default StartAdornment
