import { TBrandColor } from 'transfix-ui/theme'

export type TIconSize = 'small' | 'default' | 'medium' | 'large' | 'xlarge' | 'xxlarge'

export type TIconColor = TBrandColor | 'inherit'

export const ICON_REM_BY_SIZE: Record<TIconSize, number> = {
  small: 0.75,
  default: 0.875,
  medium: 1,
  large: 1.25,
  xlarge: 1.5,
  xxlarge: 3,
} as const

export type TIconRem = typeof ICON_REM_BY_SIZE

export interface ICommonProps {
  className?: string
}
