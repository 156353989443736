import React from 'react'
import { FieldErrors, SubmitHandler, SubmitErrorHandler } from 'react-hook-form'
import get from 'lodash/get'
import ControlledTextField from 'transfix-form/components/ControlledTextInput'
import { Button } from 'transfix-ui/components/Button'
import { InlineMessage, INLINE_MESSAGE_VARIANTS } from 'transfix-ui/components/InlineMessage'
import Grid from 'transfix-ui/core/Grid'
import { useResetPasswordForm } from './hooks'
import { TResetPasswordSchema } from './schemas'

const getErrorMessage = <T extends FieldErrors<TResetPasswordSchema>, K extends keyof T>(
  errors: T,
  keyName: K
): string | undefined => {
  return get(errors, [keyName, 'message'])
}

export interface IResetPasswordFormProps {
  /**
   * @default {}
   */
  defaultValues?: Partial<TResetPasswordSchema>
  /**
   * @example (data) => {}
   */
  onSubmitSuccess: SubmitHandler<TResetPasswordSchema>
  /**
   * @example (errors) => {}
   */
  onSubmitError?: SubmitErrorHandler<TResetPasswordSchema>
  /**
   * @default false
   */
  isLoading?: boolean
  /**
   * @example 'There was an API error'
   */
  errorMessage?: string
}

const ResetPasswordForm = ({
  defaultValues = {},
  errorMessage,
  isLoading = false,
  onSubmitSuccess,
  onSubmitError = () => {},
}: IResetPasswordFormProps) => {
  const { control, errors, handleSubmit } = useResetPasswordForm({
    defaultValues,
  })

  const submitHandler = handleSubmit(onSubmitSuccess, onSubmitError)

  return (
    <form onSubmit={submitHandler} data-test='ResetPasswordForm'>
      <Grid container spacing={2}>
        {errorMessage && (
          <Grid item xs={12}>
            <InlineMessage
              dataTest='message-error'
              message={errorMessage}
              type={INLINE_MESSAGE_VARIANTS.ALERT}
              fullWidth
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <ControlledTextField
            name='newPassword'
            control={control}
            TextInputProps={{
              type: 'password',
              label: 'New Password',
              helperMessage:
                'Include an uppercase letter, lowercase letter, number, and a special character (!?@#$).',
              validationMessage: getErrorMessage(errors, 'newPassword'),
              error: !!getErrorMessage(errors, 'newPassword'),
              dataTest: 'input-newPassword',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            type='submit'
            color='primary'
            size='large'
            data-test='submit-button'
            disabled={isLoading}>
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default ResetPasswordForm
