import React, { FC, ChangeEvent } from 'react'
import { Controller, ControllerProps } from 'react-hook-form'
import debounce from 'lodash/debounce'
import { TextField, ITextFieldProps } from 'transfix-ui/components/TextField'
import { identity } from 'apps/tms/utils/identity'
import { TextInput, ITextInputProps } from 'components/atoms'

type IBaseControllerProps = ControllerProps<React.ComponentType<ITextInputProps>>

export interface IDeprecatedControlledTextInputProps
  extends Pick<IBaseControllerProps, 'control' | 'name' | 'defaultValue'> {
  /**
   * Adds a slight delay to updating the values in `control` object
   * This significantly improves performance on large forms, and forms
   * that use `useFormContext` hook
   * @default 150
   */
  debounceMs?: number
  TextInputProps?: Omit<ITextInputProps, 'onChange' | 'value'>
}

/**
 * Helper component for use with React Hook Form
 * @see https://react-hook-form.com/api#Controller
 * @example
 * ```
 * <ControlledTextInput
 *   name='pickupNumber'
 *   control={control}
 *   TextInputProps={{
 *     label: 'Pickup Number',
 *     placeholder: '1234',
 *   }}
 * />
 * ```
 */
/** @deprecated */
export const DeprecatedControlledTextInput: FC<IDeprecatedControlledTextInputProps> = ({
  control,
  name,
  defaultValue = '',
  TextInputProps = {},
  debounceMs = 0,
}) => {
  const {
    autoFocus = false,
    className,
    clearable = true,
    dataTest,
    disabled = false,
    helperMessage,
    highlighted,
    icon,
    invalid = false,
    label,
    large = false,
    onBlur: onBlurProp,
    onClearButtonClick,
    placeholder,
    required = false,
    type = 'text',
    validationMessage,
  } = TextInputProps || {}
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ onBlur, onChange, value }) => {
        const changeHandler = debounce((e: ChangeEvent<HTMLInputElement>) => {
          onChange(e.target.value)
        }, debounceMs)

        return (
          <TextInput
            autoComplete='off'
            autoFocus={autoFocus}
            placeholder={placeholder}
            large={large}
            disabled={disabled}
            label={label}
            dataTest={dataTest}
            value={value}
            onChange={changeHandler}
            onBlur={onBlurProp || onBlur}
            clearable={clearable}
            required={required}
            invalid={invalid}
            validationMessage={validationMessage}
            helperMessage={helperMessage}
            icon={icon}
            className={className}
            onClearButtonClick={onClearButtonClick}
            highlighted={highlighted}
            type={type}
          />
        )
      }}
    />
  )
}

export interface IControlledTextFieldProps
  extends Pick<IBaseControllerProps, 'control' | 'name' | 'defaultValue'> {
  /**
   * Adds a slight delay to updating the values in `control` object
   * This significantly improves performance on large forms, and forms
   * that use `useFormContext` hook
   * @default 150
   */
  debounceMs?: number
  TextInputProps?: Omit<ITextFieldProps, 'onChange' | 'value'>
  /**
   * Allows the caller to make changes to the string that was entered into the
   * form, if necessary. Defaults to the identity function.
   *
   * @default identity
   */
  mutateValue?: (value: string) => string | number | undefined
}

/**
 * Helper component for use with React Hook Form
 * @see https://react-hook-form.com/api#Controller
 * @example
 * ```
 * <ControlledTextInput
 *   name='pickupNumber'
 *   control={control}
 *   TextInputProps={{
 *     label: 'Pickup Number',
 *     placeholder: '1234',
 *   }}
 * />
 * ```
 */
export const ControlledTextField: FC<IControlledTextFieldProps> = ({
  control,
  name,
  defaultValue,
  mutateValue = identity,
  TextInputProps = {},
}) => (
  <Controller
    control={control}
    name={name}
    defaultValue={defaultValue}
    render={({ onBlur, onChange, value: val }) => {
      const changeHandler = ({ target: { value = '' } }: ChangeEvent<HTMLInputElement>) => {
        onChange(mutateValue(value))
      }

      const blurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
        onBlur()
        const { onBlur: onBlurProp } = TextInputProps
        if (onBlurProp) {
          onBlurProp(e)
        }
      }

      return (
        <TextField {...TextInputProps} value={val} onBlur={blurHandler} onChange={changeHandler} />
      )
    }}
  />
)

export default ControlledTextField
