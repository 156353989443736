import { keymirror } from 'utils/keymirror'

export interface IHandleNotificationParams {
  message?: string
  type: string
  cta?: boolean
  theme?: string
}

export const NOTIFICATION_ACTION_TYPES = keymirror({
  NOTIFICATION_SHOW: undefined,
  NOTIFICATION_HIDE: undefined,
})

export const handleNotification = ({
  cta,
  message = '',
  theme = '',
  type,
}: IHandleNotificationParams) => {
  const { NOTIFICATION_HIDE, NOTIFICATION_SHOW } = NOTIFICATION_ACTION_TYPES
  return {
    payload: { message, type, cta, theme },
    type: type === NOTIFICATION_SHOW ? NOTIFICATION_SHOW : NOTIFICATION_HIDE,
  }
}
