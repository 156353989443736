export const VALIDATOR_PATTERNS = {
  EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PASSWORD: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
}

export const validatePhone = (phone: string): boolean => {
  if (!phone) return false
  return String(phone).replace(/\D/g, '').length === 10
}

export const validateExtension = (extension: string): boolean => {
  if (!extension) return true
  return /^[0-9]+$/.test(extension)
}

export const validateEmail = (email?: string): boolean => VALIDATOR_PATTERNS.EMAIL.test(email || '')
export const validatePassword = (password?: string): boolean =>
  VALIDATOR_PATTERNS.PASSWORD.test(password || '')

/**
 * Utility function for inserting Yup errors into an error object with the same
 * deeply nested shape as the defined schema
 *
 * Given a yup validation, this should be invoked like so
 *
 * ```
 * try {
 *    yupSchema.validateSync(formData)
 * } catch (errs) {
 *    return {
 *      errors: errs.inner.reduce(recursiveInsert, {})
 *    }
 * }
 * ```
 */
// Circular dependency can be ignored here
export const recursiveInsert = (
  errorObj: any,
  { message, path }: { path: string; message: string }
): any => {
  if (!path) return message
  const [key, ...restPath] = path.split('.')

  return {
    ...errorObj,
    [key]: recursiveInsert(errorObj[key] || {}, { path: restPath.join('.'), message }),
  }
}
