import { datadogRum } from '@datadog/browser-rum'

let globalContext = {}
let applicationName = 'unknown_app'
let applicationEnvironment = 'development'

export type LogLevel = 'debug' | 'info' | 'warn' | 'error'

const ALERT_LEVELS = ['warn', 'error']

export type LogParams = {
  tags?: object
  error?: Error
  metadata?: object
}

function notify(
  level: LogLevel,
  group: string,
  messageOrParams: string | LogParams,
  definitelyParams?: LogParams
) {
  const params = (typeof messageOrParams === 'string' ? definitelyParams : messageOrParams) || {}
  const message = typeof messageOrParams === 'string' ? messageOrParams : ''

  const tags = {
    ...globalContext,
    namespace: group,
    ...(params.tags || {}),
  }

  const errorInfo = params.error ? { 'error.stack': params.error.stack } : {}

  const record = {
    application: applicationEnvironment,
    level,
    payload: {
      metadata: params.metadata || {},
    },
    host: (window.location || { hostname: 'unknown' }).hostname,
    named_tags: tags,
    service: applicationName,
    timestamp: new Date().toISOString(),
    ...errorInfo,
  }

  window.console[level](message, level, params)

  if (ALERT_LEVELS.includes(level) || params.error) {
    if (params.error && (__PROD__ || __STAGE__ || __STAGING_NEW__)) {
      try {
        datadogRum.addError(params.error, { ...record, group })
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    } else {
      // eslint-disable-next-line no-console
      console.log(message)
    }
  }
}

export function debug(
  group: string,
  messageOrParams: string | LogParams,
  definitelyParams?: LogParams
) {
  notify('debug', group, messageOrParams, definitelyParams)
}

export function info(
  group: string,
  messageOrParams: string | LogParams,
  definitelyParams?: LogParams
) {
  notify('info', group, messageOrParams, definitelyParams)
}

export function warn(
  group: string,
  messageOrParams: string | LogParams,
  definitelyParams?: LogParams
) {
  notify('warn', group, messageOrParams, definitelyParams)
}

export function error(
  group: string,
  messageOrParams: string | LogParams,
  definitelyParams?: LogParams
) {
  notify('error', group, messageOrParams, definitelyParams)
}

export function configure({ appName }: { appName: string }) {
  applicationName = appName
  applicationEnvironment = __ENV__
}

export function setContext(context: object) {
  globalContext = context
}

export class AirhornLogger {
  group: string

  constructor(group: string) {
    this.group = group
  }

  debug(message: string, params: LogParams): void

  debug(params: LogParams): void

  debug(messageOrParams: string | LogParams, definitelyParams?: LogParams) {
    notify('debug', this.group, messageOrParams, definitelyParams)
  }

  info(message: string, params: LogParams): void

  info(params: LogParams): void

  info(messageOrParams: string | LogParams, definitelyParams?: LogParams) {
    notify('info', this.group, messageOrParams, definitelyParams)
  }

  warn(message: string, params: LogParams): void

  warn(params: LogParams): void

  warn(messageOrParams: string | LogParams, definitelyParams?: LogParams) {
    notify('warn', this.group, messageOrParams, definitelyParams)
  }

  error(message: string, params: LogParams): void

  error(params: LogParams): void

  error(messageOrParams: string | LogParams, definitelyParams?: LogParams) {
    notify('error', this.group, messageOrParams, definitelyParams)
  }
}

export function getLogger(group: string) {
  return new AirhornLogger(group)
}

// Adds user context to exceptions
export function setUserContext(user?: { [key: string]: any }) {
  datadogRum.setUser({ ...user })
}
