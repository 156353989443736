import { decamelize } from 'humps'

export const getParameterString = params => {
  if (!params) return ''
  const paramStr = Object.keys(params).reduce((acc, key, index, keysList) => {
    const underScoredKey = decamelize(key)
    let stringToAppend = ''
    if (typeof params[key] === 'object') {
      stringToAppend = JSON.stringify(params[key])
    } else if (params[key] !== null) {
      stringToAppend = params[key]
    }
    acc += `${encodeURIComponent(underScoredKey)}=${encodeURIComponent(stringToAppend)}`
    if (index < keysList.length - 1) {
      acc += '&'
    }
    return acc
  }, '?')
  if (paramStr !== '?') {
    return paramStr
  }
  return ''
}
