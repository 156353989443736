import { useCallback, useState } from 'react'

interface IUsePasswordVisibilityProps {
  type: string | undefined
  isPassword?: boolean
}

export const usePasswordVisibility = ({ isPassword, type }: IUsePasswordVisibilityProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const togglePasswordVisibility = useCallback(() => {
    setIsPasswordVisible(prev => !prev)
  }, [])

  let inputType = type
  if (isPassword) {
    inputType = isPasswordVisible ? 'text' : 'password'
  }

  return {
    isPasswordVisible,
    togglePasswordVisibility,
    inputType,
  }
}
