import { Dispatch, Middleware, Action, MiddlewareAPI } from 'redux'
import { LOGS_ACTION_TYPES, getLogsCount } from 'utils/actions/logs'

const { LOGS_REQUEST } = LOGS_ACTION_TYPES

interface IAction extends Action<string> {
  payload: {
    requestData: {
      params: {}
    }
  }
}

export const logs: Middleware = (store: MiddlewareAPI) => (next: Dispatch) => (action: IAction) => {
  if (action.type === LOGS_REQUEST) {
    store.dispatch(getLogsCount(action.payload.requestData.params))
  }

  return next(action)
}
