import { IUserDetails, IShipmentPermission, IUserPermissions } from 'types'
import callApi, { ENDPOINTS } from 'utils/api/callApi'

interface IUserDetailsResponse {
  user: IUserDetails
  shipmentPermissions: IShipmentPermission[]
  userPermissions: IUserPermissions
}

/**
 * Get user details from API using Cookies
 * @method GET
 */
export const getUserDetails = (id: string): Promise<IUserDetailsResponse> =>
  callApi({
    endpoint: ENDPOINTS.users,
    method: 'GET',
    id,
  })
