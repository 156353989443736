import ReactGA from 'react-ga'
import isEmpty from 'lodash/isEmpty'

// Google Analytics
export const initGA = (options = {}) => {
  if (isEmpty(__GA_ID__)) return false
  ReactGA.initialize(__GA_ID__, {
    ...options,
  })
  return true
}
