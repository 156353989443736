import React, { useEffect } from 'react'
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { GlobalTooltip } from 'transfix-ui/components/GlobalTooltip'
import { Spinner } from 'transfix-ui/components/Spinner'
import { ThemeProvider, DatePickersProvider } from 'transfix-ui/theme'
import { QueryParamProvider } from 'use-query-params'
import { AnalyticsProvider } from 'utils/context/AnalyticsContext'
import {
  FeatureFlagsProvider,
  useFeatureFlag,
  useFeatureFlagContext,
} from 'utils/context/FeatureFlagsContext'
import { UserDetailsProvider, useUserDetails } from 'utils/context/UserDetailsContext'
// async routes
import AuthenticationPages from 'apps/authentication-pages'
import Booking from 'apps/booking/indexAsync'
import Carrier from 'apps/carrier/indexAsync'
import CompareYourCost from 'apps/compare-your-cost/indexAsync'
import DetentionReview from 'apps/detention-review/indexAsync'
import EdiTools from 'apps/edi-tools/indexAsync'
import FleetPlanner from 'apps/fleet-planner/indexAsync'
import GraphiQL from 'apps/graphiql/indexAsync'
import MaintenancePage from 'apps/maintenance/MaintenancePage'
import Marketplace from 'apps/marketplace/indexAsync'
import ProductDemos from 'apps/product-demos/indexAsync'
import ShipperRates from 'apps/rms/indexAsync'
import Schedule from 'apps/schedule/indexAsync'
import ControlPanel from 'apps/spot-quote-control-panel/indexAsync'
import TMS from 'apps/tms/indexAsync'
import User from 'apps/user/indexAsync'
import { RouteNotFound } from 'components/404'
import { AuthenticatedRoute } from 'components/AuthenticatedRoute'
import { DownloadPage } from 'components/DownloadPage'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { Router } from 'components/Router'

const MAINTENANCE_EXCLUDED_PATHS = ['/maintenance', '/product-demos']

function AppContainer() {
  const { hasLoaded: areFeatureFlagsLoaded } = useFeatureFlagContext()
  const isCarrierAppInMaintenance = useFeatureFlag('carrierPortalMaintenance')
  const isShipperAppInMaintenance = useFeatureFlag('shipperPortalMaintenance')
  const isMarketplaceInMaintenance = useFeatureFlag('marketplaceMaintenance')
  const history = useHistory()
  const { pathname = '' } = useLocation()

  const {
    isFPUser,
    isMarketplaceAdmin,
    isTMSUser,
    loading: isUserDetailsLoading,
  } = useUserDetails()

  /**
   * It will check if the maintenance flag for MP is turned-on and redirect to maintenance page
   */
  useEffect(() => {
    const isAnExcludedMaintenancePage = MAINTENANCE_EXCLUDED_PATHS.some(href =>
      window.location.href.includes(href)
    )
    if (isMarketplaceInMaintenance && !isAnExcludedMaintenancePage) {
      window.location.href = '/maintenance'
    }
  }, [isMarketplaceInMaintenance])

  if (isUserDetailsLoading || !areFeatureFlagsLoaded) {
    return <Spinner />
  }

  /**
   * Ensure Shipper App users, once authenticated, stay within /tms routes, unless
   * we're in maintenance mode
   */
  if (
    isTMSUser &&
    !pathname.includes('/tms') &&
    !(isShipperAppInMaintenance && pathname.includes('/maintenance'))
  ) {
    history.replace('/tms')
  }

  return (
    <ErrorBoundary>
      <Switch>
        <Route exact path='/maintenance' component={MaintenancePage} />
        <Route exact path='/download' component={DownloadPage} />
        <Route
          exact
          path='/'
          render={() => {
            // if the user is not a marketplace admin, redirect to /fms/login
            // in development environments continue to redirect to /booking
            if (!isMarketplaceAdmin && !__DEV__) {
              return <Redirect to='/fms/login/' />
            }

            return <Redirect to='/booking' />
          }}
        />

        {/* Compare Your Cost Landing Page */}
        <Route exact path='/compare-your-cost' component={CompareYourCost} />

        {/* Product Demos */}
        <Route exact path='/product-demos' component={ProductDemos} />

        {/* Authentication Pages */}
        <Route
          exact
          path={['/login', '/signout', '/resend_details', '/change_password', '/forgot_password']}
          component={AuthenticationPages}
        />

        {/* TMS */}
        <Route
          path='/tms'
          render={() => {
            if (isShipperAppInMaintenance) {
              return <Redirect to='/maintenance' />
            }
            return <TMS />
          }}
        />
        <Route path='/dashboard'>
          <Redirect to='/tms/home' />
        </Route>
        <Route
          path='/shipments'
          render={({ location: { pathname, search } }) => {
            return <Redirect to={`/tms${pathname}${search}`} />
          }}
        />

        {/* FMS */}
        <Route
          path='/fms'
          render={() => {
            if (isCarrierAppInMaintenance) {
              return <Redirect to='/maintenance' />
            }
            return <FleetPlanner />
          }}
        />
        <Route path='/home'>
          <Redirect to='/fms/home' />
        </Route>

        {/* Shared */}
        <AuthenticatedRoute
          path='/users/:id?'
          render={() => {
            // if the user is a FP user, redirect to FP team page
            if (isFPUser) {
              return <Redirect to='/fms/team' />
            }
            // if the user is a TMS user, redirect to TMS users page
            if (isTMSUser) {
              return <Redirect to='/tms/users' />
            }
            return <User />
          }}
        />
        <AuthenticatedRoute
          path='/booking'
          render={() => {
            // if the user is a FP user, redirect to FP home
            if (isFPUser) {
              return <Redirect to='/fms' />
            }
            // if the user is a TMS user, redirect to TMS home
            if (isTMSUser) {
              return <Redirect to='/tms' />
            }
            return <Booking />
          }}
        />

        {/* Marketplace */}
        <Route path='/detention-review' component={DetentionReview} />
        <Route path='/schedule' component={Schedule} />
        <Redirect from='/ce-tools' to='/task-assignments' />
        <Route
          path='/carriers/matching_shipments'
          render={props => {
            const searchParams = new URLSearchParams(props.location.search)
            const carrierId = searchParams.get('company_id')
            const toPath = carrierId ? `/carriers/${carrierId}/matching_shipments` : '/carriers'

            return <Redirect to={toPath} />
          }}
        />
        <AuthenticatedRoute path='/carriers' component={Carrier} />
        <Redirect from='/ce-assignment' to='/task-assignments' />
        <AuthenticatedRoute path='/shipper-rates' component={ShipperRates} />
        <AuthenticatedRoute exact path='/shipper-rates/:id' component={ShipperRates} />
        <Route path='/spot-quote-control-panel'>
          <Redirect to='/spot-control-panel' />
        </Route>
        <AuthenticatedRoute path='/spot-control-panel' component={ControlPanel} />
        <Redirect from='/cx-tools' to='/task-assignments' />
        <AuthenticatedRoute path='/edi-tools' component={EdiTools} />
        {!__PROD__ && <AuthenticatedRoute path='/graphiql' component={GraphiQL} />}
        {/* TODO: Migrate 'marketplace' apps here */}
        <Route component={Marketplace} />
        <Route component={RouteNotFound} />
      </Switch>
    </ErrorBoundary>
  )
}

function AppWrap() {
  return (
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
        <ThemeProvider>
          <FeatureFlagsProvider>
            <UserDetailsProvider>
              <AnalyticsProvider>
                <DatePickersProvider>
                  <AppContainer />
                  <GlobalTooltip />
                </DatePickersProvider>
              </AnalyticsProvider>
            </UserDetailsProvider>
          </FeatureFlagsProvider>
        </ThemeProvider>
      </QueryParamProvider>
    </Router>
  )
}

export default AppWrap
