import {
  IUserDetailsState,
  IApiUserDetailsState,
  useUserDetails as _useUserDetails,
  getUserDetails as _getUserDetails,
  getUserPermissions as _getUserPermissions,
  getUserShipmentPermissions as _getUserShipmentPermissions,
  getUserAuthToken as _getUserAuthToken,
} from '../context/UserDetailsContext'

/**
 * This import/export forward will ease the transition between
 * the existing usages on the user singleton hook and the new user
 * context, supporting all existing imports to this file directly
 * as well as the utils/index references automagically 🪄✨.
 */
export const useUserDetailsHook = _useUserDetails
export const useUserDetails = _useUserDetails
export const getUserDetails = _getUserDetails
export const getUserPermissions = _getUserPermissions
export const getUserShipmentPermissions = _getUserShipmentPermissions
export const getUserAuthToken = _getUserAuthToken
export type IUseUserDetailsState = IApiUserDetailsState
export type IUserDetailsHook = IUserDetailsState

export default useUserDetails
