import React, { ReactNode, ReactElement, memo, useState } from 'react'
import classNames from 'classnames'
import { IconButton } from 'transfix-ui/components/IconButton'
import { makeStyles, Typography } from 'transfix-ui/core'
import { brandColors } from 'transfix-ui/theme'
import uuid from 'uuid/v4'
import { EditIcon } from 'transfix-icons'
import 'scss/5_components/_cards.scss'
import 'scss/5_components/_collapsible.scss'

type TCardSize = 'large' | 'small'

export interface ICardProps {
  /**
   * @default 'large'
   */
  size?: TCardSize
  collapsedByDefault?: boolean
  mini?: boolean
  collapsible?: boolean
  transparent?: boolean
  dataTest?: string
  maxHeight?: boolean
  title?: ReactNode
  callToAction?: ReactElement
  noPadding?: boolean
  className?: string
  dragging?: boolean
  info?: ReactElement
  /**
   * When set to true, the Card will expand vertically to fill it's container.
   * This is useful when rendering two cards side by side, where the content
   * may differ in height, but you want to maintain a consistent height across
   * both cards.
   *
   * @default false
   */
  fillContainer?: boolean
  editable?: boolean
  onEdit?: () => void
  children?: ReactNode
}

const useStyles = makeStyles({
  editableTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  editIcon: {
    color: brandColors.skyBlue6,
  },
})

export const Card = memo<ICardProps>(
  ({
    collapsedByDefault = false,
    size = 'large',
    mini = false,
    collapsible = false,
    transparent = false,
    callToAction = undefined,
    maxHeight = false,
    title = '',
    dataTest = uuid(),
    noPadding = false,
    className,
    children,
    dragging,
    info = undefined,
    fillContainer = false,
    editable = false,
    onEdit = () => {},
  }) => {
    const classes = useStyles()
    const [open, setOpen] = useState(!collapsedByDefault)

    const toggleOpen = (e: any) => {
      e.preventDefault()

      if (!collapsible) return false
      const oKey = e.which === 111
      const cKey = e.which === 99
      if (oKey || cKey) setOpen(!!cKey)
      return setOpen(!open)
    }

    const titleClass = classNames('heading', {
      expanded: open,
      collapsed: !open,
      pointer: collapsible || !open,
    })

    const cardClass = classNames(
      'card',
      {
        'card-no-padding': noPadding,
        'fill-container': fillContainer,
        collapsible: collapsible || !open,
        'card-mini': mini,
        'card-small': size === 'small',
        transparent,
        dragging,
      },
      className
    )

    return (
      <section className={cardClass} data-test={dataTest}>
        {title && (
          <header className='card-title'>
            <div
              className={classNames('title-container', {
                [classes.editableTitle]: editable,
              })}>
              <Typography
                className={titleClass}
                role='button'
                tabIndex={0}
                onClick={toggleOpen}
                onKeyPress={toggleOpen}>
                {title}
              </Typography>
              {info ? <span className='card-info'>{info}</span> : undefined}
              {editable ? (
                <IconButton
                  Icon={EditIcon}
                  color='tertiary'
                  size='small'
                  onClick={onEdit}
                  className={classNames('card-edit-button', classes.editIcon)}
                />
              ) : undefined}
            </div>
            {callToAction ? <div className='card-actions'>{callToAction}</div> : undefined}
          </header>
        )}
        {(!collapsible || open) && (
          <article className={`card-content ${maxHeight ? 'card-max-height' : ''}`}>
            {children}
          </article>
        )}
      </section>
    )
  }
)

export default Card
