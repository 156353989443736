import { IGlobalMessageOptions } from 'types/globalMessaging'
import uuid from 'uuid/v4'
import { keymirror } from 'utils/keymirror'

export const ACTION_TYPES = keymirror({
  SHOW_MESSAGE: undefined,
  HIDE_MESSAGE: undefined,
})

/**
 * @deprecated Use the `useGlobalToast` hook from transfix-ui/components/Toast/GlobalToast instead
 */
export const showMessage = (
  message: string,
  { clickToCopyValue, closeDelay, cta, type, typeLabel }: IGlobalMessageOptions
) => ({
  type: ACTION_TYPES.SHOW_MESSAGE,
  payload: {
    id: uuid(),
    message,
    clickToCopyValue,
    type,
    typeLabel,
    closeDelay,
    cta,
  },
})

/**
 * @deprecated Use the `useGlobalToast` hook from transfix-ui/components/Toast/GlobalToast instead
 */
export const hideMessage = (id: string) => ({
  type: ACTION_TYPES.HIDE_MESSAGE,
  payload: { id },
})
