import React, { memo, cloneElement, ReactElement, PropsWithChildren } from 'react'
import ReactTooltip from 'react-tooltip'
import uuid from 'uuid'
import 'scss/5_components/_tooltip.scss'

export interface ITooltipProps extends ReactTooltip.Props {
  /**
   * ID of the tooltip, this value MUST be unique or you will experience visual bugs.  If there isn't one passed in, a uuid will be assigned to help ensure uniqueness.
   *
   * @default uuid()
   */
  id?: string
  /**
   * Specifies the position of where to place the tooltip
   *
   * @default top
   */
  position?: 'top' | 'bottom' | 'right' | 'left'
  /**
   * Specifies the visual style of the tooltip
   *
   * @default dark
   */
  type?: 'dark' | 'success' | 'warning' | 'error' | 'info' | 'light'
  /**
   * Specifies the effect of the tooltip
   *
   * @default solid
   */
  effect?: 'float' | 'solid'
  /**
   * Controls the amount of time in milliseconds of how long to wait before displaying the tooltip
   *
   * @default 0
   */
  delayShow?: number
  /**
   * Overrides the display position of the tooltip
   *
   * @default undefined
   */
  overridePosition?: ReactTooltip.Props['overridePosition']
  /**
   * Specifies the element that will trigger the tooltip
   *
   * @default <div>Element</div>
   */
  trigger: ReactElement
}

interface ITriggerProps {
  'data-tip': boolean
  'data-for': string
}

export const Tooltip = memo<PropsWithChildren<ITooltipProps>>(
  ({
    id = uuid(),
    position = 'top',
    type = 'dark',
    effect = 'solid',
    delayShow = 0,
    overridePosition,
    children,
    trigger,
    ...props
  }) => {
    const triggerComponent: ReactElement<ITriggerProps> = cloneElement(trigger, {
      'data-tip': true,
      'data-for': id,
    })

    return (
      <>
        {triggerComponent}
        <ReactTooltip
          className='tooltip'
          id={id}
          place={position}
          type={type}
          effect={effect}
          delayShow={delayShow}
          overridePosition={overridePosition}
          role='tooltip'
          {...props}>
          {children}
        </ReactTooltip>
      </>
    )
  }
)

export default Tooltip
