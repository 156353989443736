import { decamelizeKeys } from 'humps'
import { getShipmentsCount, SHIPMENT_ACTION_TYPES } from 'utils/actions/shipments'

const { REQUEST_SHIPMENTS } = SHIPMENT_ACTION_TYPES

export const shipments = store => next => async action => {
  const { payload = {}, type } = action

  if (type !== REQUEST_SHIPMENTS) return next(action)

  const {
    requestData: {
      params: { filters },
    },
  } = payload

  if (!Array.isArray(payload.tabs)) return next(action)

  const countParams = {
    filters: payload.tabs.map(tab => {
      // Exclude counting for Archived and Post-Transit tab
      if (!tab.showCount) return null

      let status
      const { status: tabstatus } = tab
      const { stateOptions } = filters
      if (stateOptions && tabstatus.includes(stateOptions)) status = stateOptions
      if (stateOptions && !tabstatus.includes(filters.stateOptions)) status = ['']
      if (!stateOptions) status = tabstatus
      return decamelizeKeys({
        ...filters,
        status,
      })
    }),
  }
  store.dispatch(getShipmentsCount(countParams))

  return next(action)
}
