import React, { FC, memo } from 'react'
import { Tooltip } from 'transfix-ui/components/Tooltip'
import { brandColors } from 'transfix-ui/theme/colors'
import { useDeprecated } from 'utils/deprecation'
import { iconClassName } from 'utils/generateClassNames'
import 'assets/scss/4_base/_icons.scss'

/** @deprecated Use transfix-ui/components/icons instead */
export const LeftArrow: FC<ICommonProps> = ({ className = '' }) => (
  <svg
    className={`icon ${className}`}
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M17.2247 3.35252C16.7417 2.88249 15.963 2.88249 15.4799 3.35252L7.28833 11.3237C6.90389 11.6978 6.90389 12.3022 7.28833 12.6763L15.4799 20.6475C15.963 21.1175 16.7417 21.1175 17.2247 20.6475C17.7077 20.1775 17.7077 19.4197 17.2247 18.9496L10.0879 11.9952L17.2346 5.04077C17.7077 4.58034 17.7077 3.81295 17.2247 3.35252Z'
      fill='currentColor'
    />
  </svg>
)

/** @deprecated Use transfix-ui/components/icons instead */
export const FlatBed: FC<ICommonProps> = ({ className = '' }) => (
  <svg
    className={`icon ${className}`}
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.1707 16C14.0602 16.3128 14 16.6494 14 17C14 18.6569 15.3431 20 17 20C18.6569 20 20 18.6569 20 17C20 16.6494 19.9398 16.3128 19.8293 16H20C21.1046 16 22 15.1046 22 14V9.91439C22 9.40717 21.8073 8.91889 21.4608 8.54841L18.7357 5.63401C18.3575 5.22956 17.8286 5 17.2749 5H15C13.8954 5 13 5.89543 13 7V14H6.00121C6.00081 14 6.0004 14 6 14C5.9996 14 5.99919 14 5.99879 14H3C2.44772 14 2 14.4477 2 15C2 15.5523 2.44772 16 3 16H3.17071C3.06015 16.3128 3 16.6494 3 17C3 18.6569 4.34315 20 6 20C7.65685 20 9 18.6569 9 17C9 16.6494 8.93985 16.3128 8.82929 16L14.1707 16ZM15 7H17.2749L20 9.91439V14H15V7ZM6 16C5.44804 16.0004 5 16.4479 5 17C5 17.5523 5.44772 18 6 18C6.55229 18 7 17.5523 7 17C7 16.4479 6.55196 16.0004 6 16ZM17 16C16.4477 16 16 16.4477 16 17C16 17.5523 16.4477 18 17 18C17.5523 18 18 17.5523 18 17C18 16.4477 17.5523 16 17 16Z'
      fill='currentColor'
    />
  </svg>
)

/** @deprecated Use transfix-ui/components/icons instead */
export const DropTrailer: FC<ICommonProps> = ({ className = '' }) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={`icon ${className}`}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7 18C7.55229 18 8 17.5523 8 17C8 16.4477 7.55228 16 7 16C6.44772 16 6 16.4477 6 17C6 17.5523 6.44772 18 7 18ZM10 17C10 18.6569 8.65685 20 7 20C5.34315 20 4 18.6569 4 17C4 15.3431 5.34315 14 7 14C8.65685 14 10 15.3431 10 17Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14 6H4L4 14H14V6ZM4 4C2.89543 4 2 4.89543 2 6V14C2 15.1046 2.89543 16 4 16H14C15.1046 16 16 15.1046 16 14V6C16 4.89543 15.1046 4 14 4H4Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13 15C13 14.4477 13.4477 14 14 14H21C21.5523 14 22 14.4477 22 15C22 15.5523 21.5523 16 21 16H14C13.4477 16 13 15.5523 13 15Z'
      fill='currentColor'
    />
  </svg>
)

/** @deprecated Use transfix-ui/components/icons instead */
export const Reefer: FC<ICommonProps> = ({ className = '' }) => (
  <svg
    className={`icon ${className}`}
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13 3C13 2.44772 12.5523 2 12 2C11.4477 2 11 2.44771 11 3L11 6.58578L8.70711 4.29289C8.31658 3.90237 7.68342 3.90237 7.29289 4.29289C6.90237 4.68342 6.90237 5.31658 7.29289 5.70711L11 9.41421L11 11L9.4142 11L5.70711 7.29289C5.31658 6.90237 4.68342 6.90237 4.29289 7.29289C3.90237 7.68342 3.90237 8.31658 4.29289 8.70711L6.58577 11L3 11C2.44772 11 2 11.4477 2 12C2 12.5523 2.44771 13 3 13L6.5858 13L4.29289 15.2929C3.90237 15.6834 3.90237 16.3166 4.29289 16.7071C4.68342 17.0976 5.31658 17.0976 5.70711 16.7071L9.41423 13L11 13L11 14.5858L7.29289 18.2929C6.90237 18.6834 6.90237 19.3166 7.29289 19.7071C7.68342 20.0976 8.31658 20.0976 8.70711 19.7071L11 17.4142L11 21C11 21.5523 11.4477 22 12 22C12.5523 22 13 21.5523 13 21L13 17.4142L15.2929 19.7071C15.6834 20.0976 16.3166 20.0976 16.7071 19.7071C17.0976 19.3166 17.0976 18.6834 16.7071 18.2929L13 14.5858L13 13L14.5858 13L18.2929 16.7071C18.6834 17.0976 19.3166 17.0976 19.7071 16.7071C20.0976 16.3166 20.0976 15.6834 19.7071 15.2929L17.4142 13L21 13C21.5523 13 22 12.5523 22 12C22 11.4477 21.5523 11 21 11L17.4142 11L19.7071 8.70711C20.0976 8.31658 20.0976 7.68342 19.7071 7.29289C19.3166 6.90237 18.6834 6.90237 18.2929 7.29289L14.5858 11L13 11L13 9.41422L16.7071 5.70711C17.0976 5.31658 17.0976 4.68342 16.7071 4.29289C16.3166 3.90237 15.6834 3.90237 15.2929 4.29289L13 6.58579L13 3Z'
      fill='currentColor'
    />
  </svg>
)

/** @deprecated Use transfix-ui/components/icons instead */
export const DryVan: FC<ICommonProps> = ({ className = '' }) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    data-test='dry-van'
    xmlns='http://www.w3.org/2000/svg'
    className={`icon ${className}`}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.8937 11H16L16 14H20V12.6126L17.8937 11ZM16 9C14.8954 9 14 9.89543 14 11V14C14 15.1046 14.8954 16 16 16H20C21.1046 16 22 15.1046 22 14V12.6126C22 11.9901 21.7101 11.4031 21.2158 11.0246L19.1095 9.41199C18.7605 9.14479 18.3333 9 17.8937 9H16Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7 18C7.55229 18 8 17.5523 8 17C8 16.4477 7.55228 16 7 16C6.44772 16 6 16.4477 6 17C6 17.5523 6.44772 18 7 18ZM10 17C10 18.6569 8.65685 20 7 20C5.34315 20 4 18.6569 4 17C4 15.3431 5.34315 14 7 14C8.65685 14 10 15.3431 10 17Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16 18C16.5523 18 17 17.5523 17 17C17 16.4477 16.5523 16 16 16C15.4477 16 15 16.4477 15 17C15 17.5523 15.4477 18 16 18ZM19 17C19 18.6569 17.6569 20 16 20C14.3431 20 13 18.6569 13 17C13 15.3431 14.3431 14 16 14C17.6569 14 19 15.3431 19 17Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14 6H4L4 14H14V6ZM4 4C2.89543 4 2 4.89543 2 6V14C2 15.1046 2.89543 16 4 16H14C15.1046 16 16 15.1046 16 14V6C16 4.89543 15.1046 4 14 4H4Z'
      fill='currentColor'
    />
  </svg>
)

/** @deprecated Use transfix-ui/components/icons instead */
export const OpenInNewTab: FC<ICommonProps> = ({ className = '' }) => (
  <svg
    className={`icon ${className}`}
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.8024 4.8L11.2269 11.3755C10.8409 11.7615 10.8403 12.3889 11.227 12.7755C11.6128 13.1613 12.2404 13.1621 12.627 12.7755L19.2 6.20252V8.40071C19.2 8.89632 19.6029 9.3 20.1 9.3C20.596 9.3 21 8.89738 21 8.40071V3.89929C21 3.6516 20.8994 3.42688 20.7366 3.26402C20.5736 3.10089 20.3489 3 20.1007 3H15.5993C15.1037 3 14.7 3.40294 14.7 3.9C14.7 4.39604 15.1026 4.8 15.5993 4.8H17.8024ZM21 13.8V9.52166V18.7567C21 19.9956 20.1074 21 19.0049 21H4.99509C3.89323 21 3 19.9937 3 18.7567V5.24332C3 4.00437 3.89262 3 4.99509 3H14.6025H10.2C10.6971 3 11.1 3.40294 11.1 3.9C11.1 4.39706 10.6971 4.8 10.2 4.8H5.3125C5.02619 4.8 4.8 5.06882 4.8 5.40042V18.5996C4.8 18.9252 5.02946 19.2 5.3125 19.2H18.6875C18.9738 19.2 19.2 18.9312 19.2 18.5996V13.8C19.2 13.3029 19.6029 12.9 20.1 12.9C20.5971 12.9 21 13.3029 21 13.8Z'
      fill='currentColor'
    />
  </svg>
)

interface ICommonProps {
  margin?: string
  minWidth?: string
  className?: string
}

interface ICheckboxProps extends ICommonProps {
  disabled?: boolean
}

export const Calendar: FC<ICommonProps> = ({ className = '' }) => (
  <svg
    className={`icon ${className}`}
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9 4C9 3.44772 8.55228 3 8 3C7.44772 3 7 3.44772 7 4V5H5C3.89543 5 3 5.89543 3 7V11V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V7C21 5.89543 20.1046 5 19 5H17V4C17 3.44772 16.5523 3 16 3C15.4477 3 15 3.44772 15 4V5H9V4ZM7 7V8C7 8.55228 7.44772 9 8 9C8.55228 9 9 8.55228 9 8V7H15V8C15 8.55228 15.4477 9 16 9C16.5523 9 17 8.55228 17 8V7H19V10H5V7H7ZM5 12L5 19H19V12H5Z'
      fill='currentColor'
    />
  </svg>
)

export const Bullet: FC<ICommonProps> = ({ className, ...props }) => {
  const cls = `inline ${className}`
  return (
    <span className={cls} {...props}>
      &nbsp;&#8729;&nbsp;
    </span>
  )
}

export const MidDot = () => <>&nbsp;&middot;&nbsp;</>

export const Document: FC<ICommonProps> = ({ className = '' }) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={`icon ${className}`}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14 4L12 2V9C12 9.55228 12.4477 10 13 10H20L18 8H14V4Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8 13C8 12.4477 8.44772 12 9 12H15C15.5523 12 16 12.4477 16 13C16 13.5523 15.5523 14 15 14H9C8.44772 14 8 13.5523 8 13Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8 17C8 16.4477 8.44772 16 9 16H15C15.5523 16 16 16.4477 16 17C16 17.5523 15.5523 18 15 18H9C8.44772 18 8 17.5523 8 17Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8 9C8 8.44772 8.44772 8 9 8L10 8C10.5523 8 11 8.44772 11 9C11 9.55228 10.5523 10 10 10L9 10C8.44772 10 8 9.55228 8 9Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18 20V9.51164L12.3309 4H6V20H18ZM19 22H5C4.44772 22 4 21.5523 4 21V3C4 2.44772 4.44772 2 5 2H12.7369C12.9972 2 13.2473 2.10153 13.4339 2.28301L19.6971 8.37216C19.8907 8.56044 20 8.81905 20 9.08915V21C20 21.5523 19.5523 22 19 22Z'
      fill='currentColor'
    />
  </svg>
)

export const OffDuty: FC<ICommonProps> = ({ className = '' }) => (
  <svg
    className={`icon ${className}`}
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.043 2.50901C12.2365 2.85239 12.2103 3.27742 11.976 3.59439C11.1218 4.75005 10.7107 6.17392 10.8176 7.60703C10.9244 9.04015 11.5421 10.3873 12.5583 11.4035C13.5745 12.4197 14.9217 13.0374 16.3548 13.1442C17.7879 13.2511 19.2118 12.84 20.3674 11.9858C20.6844 11.7515 21.1094 11.7253 21.4528 11.9188C21.7962 12.1123 21.9938 12.4895 21.9576 12.882C21.7828 14.7733 21.073 16.5758 19.9112 18.0784C18.7494 19.5811 17.1837 20.7218 15.3972 21.3671C13.6108 22.0123 11.6776 22.1355 9.82368 21.7221C7.96981 21.3087 6.27201 20.3759 4.92893 19.0329C3.58586 17.6898 2.65306 15.992 2.23969 14.1381C1.82632 12.2842 1.94948 10.351 2.59475 8.56456C3.24002 6.77813 4.38072 5.21241 5.88336 4.05062C7.38601 2.88883 9.18846 2.17901 11.0798 2.00423C11.4723 1.96796 11.8495 2.16563 12.043 2.50901ZM9.2782 4.43905C8.50059 4.7204 7.76741 5.12201 7.10669 5.63286C5.90457 6.56229 4.99202 7.81486 4.4758 9.24401C3.95958 10.6732 3.86106 12.2198 4.19175 13.7029C4.52245 15.186 5.26869 16.5442 6.34315 17.6187C7.41761 18.6931 8.77585 19.4394 10.2589 19.77C11.742 20.1007 13.2887 20.0022 14.7178 19.486C16.1469 18.9698 17.3995 18.0572 18.3289 16.8551C18.8398 16.1944 19.2414 15.4612 19.5228 14.6836C18.4696 15.0646 17.34 15.2232 16.206 15.1387C14.2952 14.9962 12.499 14.1726 11.1441 12.8177C9.78921 11.4628 8.96561 9.66658 8.82312 7.75577C8.73855 6.6218 8.89719 5.49217 9.2782 4.43905Z'
      fill='currentColor'
    />
  </svg>
)

export const Plus: FC<ICommonProps> = ({ className = '' }) => (
  <svg
    className={`inline ${className}`}
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12 7C12.5523 7 13 7.44772 13 8V11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13H13V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V13H8C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11H11V8C11 7.44772 11.4477 7 12 7Z'
      fill='currentColor'
    />
  </svg>
)

export const CheckFilled: FC<ICommonProps> = ({ className = '' }) => (
  <svg
    className={`icon ${className}`}
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM17.2134 10.0007C17.6004 9.60667 17.5947 8.97353 17.2007 8.58654C16.8067 8.19955 16.1735 8.20525 15.7866 8.59928L10.3125 14.1729L8.21345 12.0356C7.82646 11.6416 7.19332 11.6359 6.79929 12.0229C6.40527 12.4099 6.39956 13.043 6.78655 13.4371L9.59905 16.3007C9.78708 16.4921 10.0442 16.6 10.3125 16.6C10.5808 16.6 10.8379 16.4921 11.0259 16.3007L17.2134 10.0007Z'
      fill='currentColor'
    />
  </svg>
)

export const Safety: FC<ICommonProps> = ({ className = '' }) => (
  <svg
    className={`icon ${className}`}
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.6489 1.06367C11.8753 0.978776 12.1247 0.978776 12.3511 1.06367L20.3511 4.06367C20.7414 4.21003 21 4.58316 21 5V12C21 15.4464 18.7183 18.2003 16.6585 20.0026C15.6076 20.9221 14.5615 21.6408 13.78 22.1292C13.3882 22.3741 13.0603 22.5627 12.8281 22.6913C12.7119 22.7556 12.6194 22.805 12.5547 22.839C12.5223 22.856 12.4968 22.8692 12.4788 22.8784L12.4573 22.8894L12.4508 22.8926L12.4486 22.8937C12.4484 22.8938 12.4477 22.8942 12.2362 22.472L12.4472 22.8944C12.1657 23.0352 11.8337 23.0349 11.5522 22.8941C11.5524 22.8942 11.5527 22.8944 11.764 22.472L11.5522 22.8941L11.5492 22.8926L11.5427 22.8894L11.5212 22.8784C11.5032 22.8692 11.4777 22.856 11.4453 22.839C11.3806 22.805 11.2881 22.7556 11.1719 22.6913C10.9397 22.5627 10.6118 22.3741 10.22 22.1292C9.43854 21.6408 8.39238 20.9221 7.3415 20.0026C5.28175 18.2003 3 15.4464 3 12V5C3 4.58316 3.25857 4.21003 3.64888 4.06367L11.6489 1.06367ZM5 11V12C5 14.5536 6.71825 16.7997 8.6585 18.4974C9.48899 19.2241 10.3231 19.8174 11 20.2552V11H5ZM11 9H5V5.693L11 3.443V9ZM13 11V20.2552C13.6769 19.8174 14.511 19.2241 15.3415 18.4974C17.2817 16.7997 19 14.5536 19 12V11H13ZM19 9H13V3.443L19 5.693V9Z'
      fill='currentColor'
    />
  </svg>
)

export const SmartphoneOn: FC<ICommonProps> = ({ className = '' }) => (
  <svg
    className={`icon ${className}`}
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.71429 3C7.45886 3 7 3.29754 7 4V20C7 20.7025 7.45886 21 7.71429 21H16.2857C16.5411 21 17 20.7025 17 20V14.5C17 13.9477 17.4477 13.5 18 13.5C18.5523 13.5 19 13.9477 19 14.5V20C19 21.5067 17.9238 23 16.2857 23H7.71429C6.07616 23 5 21.5067 5 20V4C5 2.49332 6.07616 1 7.71429 1H16.2857C17.9238 1 19 2.49332 19 4V5C19 5.55228 18.5523 6 18 6C17.4477 6 17 5.55228 17 5V4C17 3.29754 16.5411 3 16.2857 3H7.71429Z'
      fill='#0D0E10'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 18C10 17.4477 10.4477 17 11 17H13C13.5523 17 14 17.4477 14 18C14 18.5523 13.5523 19 13 19H11C10.4477 19 10 18.5523 10 18Z'
      fill='#0D0E10'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22.7007 4.28655C23.0947 4.67354 23.1004 5.30668 22.7134 5.70071L15.8384 12.7007C15.6504 12.8922 15.3933 13 15.125 13C14.8567 13 14.5996 12.8922 14.4116 12.7007L11.2866 9.51889C10.8996 9.12486 10.9053 8.49172 11.2993 8.10473C11.6933 7.71774 12.3265 7.72345 12.7134 8.11747L15.125 10.5729L21.2865 4.29929C21.6735 3.90527 22.3067 3.89956 22.7007 4.28655Z'
      fill='#2298FE'
    />
  </svg>
)

export const SmartphoneOff: FC<ICommonProps> = ({ className = '' }) => (
  <svg
    className={`icon ${className}`}
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.71429 2C6.71429 1.44772 7.162 1 7.71429 1H16.2857C17.9238 1 19 2.49332 19 4V12C19 12.5523 18.5523 13 18 13C17.4477 13 17 12.5523 17 12V4C17 3.29754 16.5411 3 16.2857 3H7.71429C7.162 3 6.71429 2.55228 6.71429 2ZM6 6C6.55228 6 7 6.44771 7 7V19.9993L6 20L5 19.9999V7C5 6.44771 5.44772 6 6 6ZM18 17.5C18.5523 17.5 19 17.9477 19 18.5V20C19 21.5067 17.9238 23 16.2857 23H7.71429C6.07616 23 5 21.5066 5 19.9999L6 20L7 19.9993C7 20.7018 7.45886 21 7.71429 21H16.2857C16.5411 21 17 20.7025 17 20V18.5C17 17.9477 17.4477 17.5 18 17.5Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 18C10 17.4477 10.4477 17 11 17H13C13.5523 17 14 17.4477 14 18C14 18.5523 13.5523 19 13 19H11C10.4477 19 10 18.5523 10 18Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.493089 0.719163C0.883613 0.328638 1.51678 0.328638 1.9073 0.719163L23.9073 22.7192C24.2978 23.1097 24.2978 23.7429 23.9073 24.1334C23.5168 24.5239 22.8836 24.5239 22.4931 24.1334L0.493089 2.13338C0.102564 1.74285 0.102564 1.10969 0.493089 0.719163Z'
      fill='currentColor'
    />
  </svg>
)

export const BellOn: FC<ICommonProps> = ({ className = '' }) => (
  <svg
    className={`icon ${className}`}
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.05033 3.05025C8.36308 1.7375 10.1436 1 12.0001 1C13.8566 1 15.6371 1.7375 16.9498 3.05025C17.3403 3.44078 17.3403 4.07394 16.9498 4.46447C16.5593 4.85499 15.9261 4.85499 15.5356 4.46447C14.5979 3.52678 13.3262 3 12.0001 3C10.674 3 9.40223 3.52678 8.46454 4.46447C7.52686 5.40215 7.00008 6.67392 7.00008 8C7.00008 11.6473 6.21714 14.0654 5.37797 15.6039C5.30187 15.7434 5.22549 15.8753 5.1494 16H18.8508C18.5859 15.566 18.3177 15.0443 18.0708 14.4234C17.8668 13.9102 18.1174 13.3288 18.6306 13.1247C19.1438 12.9207 19.7253 13.1713 19.9293 13.6845C20.3246 14.6787 20.7679 15.3223 21.0908 15.7041C21.2528 15.8956 21.386 16.023 21.47 16.0968C21.512 16.1336 21.5418 16.1572 21.5568 16.1686C21.5614 16.1722 21.5647 16.1745 21.5664 16.1758C21.9249 16.4221 22.0835 16.8725 21.9572 17.2898C21.8295 17.7115 21.4407 18 21.0001 18H3.00008C2.55941 18 2.17068 17.7115 2.04299 17.2898C1.91664 16.8725 2.07528 16.4221 2.43377 16.1758C2.43447 16.1753 2.43542 16.1746 2.43663 16.1737C2.44432 16.168 2.46218 16.1544 2.4888 16.1322C2.54202 16.0878 2.6304 16.0091 2.74288 15.8904C2.9673 15.6535 3.29039 15.2544 3.62218 14.6461C4.28301 13.4346 5.00008 11.3527 5.00008 8C5.00008 6.14349 5.73758 4.36301 7.05033 3.05025ZM2.44388 16.169C2.44395 16.1689 2.44403 16.1688 2.44411 16.1688C2.44411 16.1688 2.4441 16.1688 2.4441 16.1688L2.44388 16.169Z'
      fill='#0D0E10'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.76792 20.1349C10.2457 19.8578 10.8576 20.0204 11.1347 20.4981C11.2226 20.6497 11.3488 20.7755 11.5006 20.8629C11.6524 20.9504 11.8245 20.9964 11.9997 20.9964C12.1749 20.9964 12.347 20.9504 12.4988 20.8629C12.6506 20.7755 12.7768 20.6497 12.8647 20.4981C13.1418 20.0204 13.7537 19.8578 14.2315 20.1349C14.7092 20.412 14.8718 21.024 14.5947 21.5017C14.331 21.9563 13.9525 22.3337 13.497 22.596C13.0416 22.8583 12.5253 22.9964 11.9997 22.9964C11.4741 22.9964 10.9578 22.8583 10.5024 22.596C10.0469 22.3337 9.66841 21.9563 9.4047 21.5017C9.12758 21.024 9.2902 20.412 9.76792 20.1349Z'
      fill='#0D0E10'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22.7007 4.28655C23.0947 4.67354 23.1004 5.30668 22.7134 5.70071L15.8384 12.7007C15.6504 12.8922 15.3933 13 15.125 13C14.8567 13 14.5996 12.8922 14.4116 12.7007L11.2866 9.51889C10.8996 9.12486 10.9053 8.49172 11.2993 8.10473C11.6933 7.71774 12.3265 7.72345 12.7134 8.11747L15.125 10.5729L21.2865 4.29929C21.6735 3.90527 22.3067 3.89956 22.7007 4.28655Z'
      fill='#2298FE'
    />
  </svg>
)

export const BellOff: FC<ICommonProps> = ({ className = '' }) => (
  <svg
    className={`icon ${className}`}
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.76841 20.135C10.2461 19.8579 10.8581 20.0205 11.1352 20.4982C11.2231 20.6497 11.3493 20.7755 11.5011 20.863C11.6529 20.9504 11.825 20.9964 12.0002 20.9964C12.1754 20.9964 12.3475 20.9504 12.4993 20.863C12.6511 20.7755 12.7773 20.6497 12.8652 20.4982C13.1423 20.0205 13.7542 19.8579 14.232 20.135C14.7097 20.4121 14.8723 21.024 14.5952 21.5018C14.3315 21.9564 13.9529 22.3337 13.4975 22.596C13.0421 22.8584 12.5258 22.9964 12.0002 22.9964C11.4746 22.9964 10.9583 22.8584 10.5028 22.596C10.0474 22.3337 9.6689 21.9564 9.40518 21.5018C9.12806 21.024 9.29069 20.4121 9.76841 20.135Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18.0158 7.00013C18.568 7.00895 19.0085 7.46377 18.9997 8.01598C18.9743 9.60967 19.1745 11.1989 19.5945 12.7365C19.74 13.2693 19.4261 13.8191 18.8934 13.9647C18.3606 14.1102 17.8107 13.7963 17.6652 13.2635C17.1954 11.5439 16.9715 9.76643 17 7.98403C17.0088 7.43181 17.4636 6.99131 18.0158 7.00013Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.55465 5.30438C7.08243 5.46707 7.3784 6.02681 7.21571 6.55459C7.07162 7.02202 6.99892 7.50852 7.00007 7.99766L7.00008 8.00001C7.00008 11.6474 6.21715 14.0654 5.37797 15.6039C5.30188 15.7434 5.22549 15.8753 5.1494 16H17.0001C17.5524 16 18.0001 16.4477 18.0001 17C18.0001 17.5523 17.5524 18 17.0001 18H3.00008C2.55941 18 2.17068 17.7116 2.04299 17.2898C1.91664 16.8725 2.07528 16.4221 2.43377 16.1758C2.43447 16.1753 2.43542 16.1746 2.43663 16.1737C2.44432 16.168 2.46218 16.1544 2.4888 16.1322C2.54202 16.0878 2.6304 16.0091 2.74288 15.8904C2.9673 15.6535 3.29039 15.2544 3.62218 14.6462C4.28294 13.4348 4.99992 11.3531 5.00008 8.00109M2.44388 16.169C2.44395 16.1689 2.44403 16.1689 2.44411 16.1688C2.44411 16.1688 2.4441 16.1688 2.4441 16.1688L2.44388 16.169Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.6627 0.999221C12.9294 0.938119 14.1888 1.22224 15.3065 1.82122C16.4242 2.42021 17.3582 3.31157 18.0087 4.40008C18.6593 5.4886 19.0019 6.73338 19 8.00148C18.9992 8.55376 18.5508 9.00081 17.9985 8.99999C17.4463 8.99917 16.9992 8.55079 17 7.99851C17.0014 7.09273 16.7566 6.2036 16.292 5.42608C15.8273 4.64857 15.1602 4.01189 14.3618 3.58404C13.5634 3.15619 12.6638 2.95325 11.7591 2.9969C10.8544 3.04054 9.9785 3.32913 9.22503 3.83184C8.76561 4.13836 8.1447 4.01441 7.83818 3.55499C7.53166 3.09558 7.65561 2.47466 8.11503 2.16814C9.16989 1.46435 10.3961 1.06032 11.6627 0.999221Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L23.7071 22.2929C24.0976 22.6834 24.0976 23.3166 23.7071 23.7071C23.3166 24.0976 22.6834 24.0976 22.2929 23.7071L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z'
      fill='currentColor'
    />
  </svg>
)

export const CheckCircleChecked: FC<ICheckboxProps> = ({ className = '', disabled = false }) => {
  return disabled ? (
    <svg
      className={`icon ${className}`}
      width='16'
      height='17'
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.5' y='1' width='15' height='15' rx='7.5' fill='white' />
      <rect x='0.5' y='1' width='15' height='15' rx='7.5' stroke={brandColors.coolGray5} />
    </svg>
  ) : (
    <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.5' y='1' width='15' height='15' rx='7.5' fill='white' />
      <rect x='0.5' y='1' width='15' height='15' rx='7.5' stroke={brandColors.skyBlue6} />
      <circle cx='8' cy='8.5' r='5' fill={brandColors.skyBlue6} />
    </svg>
  )
}

export const CheckCircleUnchecked: FC<ICheckboxProps> = ({ className = '', disabled = false }) => {
  return disabled ? (
    <svg
      className={`icon ${className}`}
      width='16'
      height='17'
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.5' y='1' width='15' height='15' rx='7.5' fill='white' />
      <rect x='0.5' y='1' width='15' height='15' rx='7.5' stroke={brandColors.skyBlue6} />
      <circle cx='8' cy='8.5' r='5' fill={brandColors.skyBlue6} />
    </svg>
  ) : (
    <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.5' y='1' width='15' height='15' rx='7.5' fill='white' />
      <rect x='0.5' y='1' width='15' height='15' rx='7.5' stroke={brandColors.coolGray5} />
    </svg>
  )
}

export const CheckboxUnchecked: FC<ICheckboxProps> = ({ className = '', disabled = false }) => {
  return disabled ? (
    <svg
      className={className}
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <rect
        x='1'
        y='1'
        width='14'
        height='14'
        rx='1'
        fill='#F3F5F7'
        stroke='#A9B2BD'
        strokeWidth='2'
      />
    </svg>
  ) : (
    <svg
      className={className}
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <rect
        x='1'
        y='1'
        width='14'
        height='14'
        rx='1'
        fill='#F3F5F7'
        stroke='#697684'
        strokeWidth='2'
      />
    </svg>
  )
}

export const CheckboxChecked: FC<ICheckboxProps> = ({ className = '', disabled = false }) => {
  return disabled ? (
    <svg
      className={className}
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <rect width='16' height='16' rx='2' fill='#A9B2BD' />
      <path d='M4 8.04944L7.11111 11.5062' stroke='#697684' strokeWidth='2' strokeLinecap='round' />
      <path
        d='M11.5555 4.44444L7.1111 11.5062'
        stroke='#697684'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  ) : (
    <svg
      className={className}
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <rect width='16' height='16' rx='2' fill='#0072B5' />
      <path d='M4 8.04944L7.11111 11.5062' stroke='white' strokeWidth='2' strokeLinecap='round' />
      <path
        d='M11.8889 4.44444L7.44444 11.5062'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  )
}

export const CheckboxIndeterminate: FC<ICheckboxProps> = ({ className = '' }) => {
  return (
    <svg
      className={className}
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <rect width='16' height='16' rx='2' fill='#0072B5' />
      <line x1='4' y1='8' x2='12' y2='8' stroke='white' strokeWidth='2' strokeLinecap='round' />
    </svg>
  )
}

export const Paper: FC<ICommonProps> = ({ className = '' }) => (
  <svg
    className={`icon ${className}`}
    width='13'
    height='13'
    viewBox='0 0 13 13'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M2 0.75H11C11.6904 0.75 12.25 1.30964 12.25 2V11C12.25 11.6904 11.6904 12.25 11 12.25H6.81066L0.75 6.18934V2C0.75 1.30964 1.30964 0.75 2 0.75Z'
      stroke='#697684'
    />
    <path d='M0 6L6.5 12.5V9C6.5 7.34315 5.15685 6 3.5 6H0Z' fill='#697684' />
  </svg>
)

interface IconWithTooltipProps extends ICommonProps {
  tooltipAlign?: 'top' | 'bottom' | 'left' | 'right'
}

interface ICountProps extends IconWithTooltipProps {
  count: number | string
}

/** @deprecated Use transfix-ui/components/icons instead */
export const BouncedIcon: FC<ICommonProps> = ({ className = '' }) => (
  <svg
    width='24px'
    height='24px'
    aria-hidden='true'
    viewBox='0 0 24 24'
    className={`icon ${className}`}>
    <g id='Icon/bounced' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <path
        d='M5 10C6.65685 10 8 8.65685 8 7C8 5.34315 6.65685 4 5 4C3.34315 4 2 5.34315 2 7C2 8.65685 3.34315 10 5 10ZM9.34005 10.0447C8.96605 9.64806 8.41083 9.65009 8.09993 10.0492C7.78903 10.4483 7.84018 11.0934 8.21418 11.49C9.10673 12.4365 9.46954 12.9868 10.1721 14.0524C10.2824 14.2197 10.4011 14.3997 10.5316 14.596C10.8404 15.0607 11.3953 15.1801 11.771 14.8627C12.1467 14.5454 12.201 13.9115 11.8922 13.4468C11.7903 13.2935 11.6946 13.1489 11.6037 13.0116C10.7712 11.7541 10.3448 11.1101 9.34005 10.0447ZM13.6923 16.4914C13.4305 15.9923 12.886 15.7982 12.4762 16.0578C12.0663 16.3175 11.9462 16.9327 12.208 17.4318C12.5819 18.1447 12.9428 18.8951 13.288 19.6853C13.5064 20.1853 14 20.5 14.5 20.5C15 20.5 15.32 19.86 15.5 19.5C15.8804 18.7391 16.3053 18.0957 16.7809 17.3996C17.0609 16.9898 16.955 16.4335 16.5444 16.1571C16.1338 15.8807 15.5739 15.9889 15.294 16.3987C15.1813 16.5636 15.0378 16.7597 14.8814 16.9735C14.7033 17.217 14.5084 17.4834 14.3231 17.7528C14.1174 17.3211 13.907 16.9008 13.6923 16.4914ZM22.1182 12.6629C21.9367 12.2037 21.4145 11.9796 20.9518 12.1625C19.4953 12.7383 18.8491 13.0171 17.6363 14.0074C17.2512 14.3218 17.1937 14.8859 17.5079 15.2673C17.8221 15.6488 18.3889 15.7031 18.774 15.3887C19.8241 14.5312 20.3037 14.3416 21.6046 13.8274L21.6092 13.8256C22.0719 13.6427 22.2997 13.1221 22.1182 12.6629Z'
        id='Shape'
        fill='currentColor'
        fillRule='nonzero'
      />
    </g>
  </svg>
)

export const Bounced: FC<ICountProps> = ({
  className,
  count = 1,
  margin,
  minWidth = '35px',
  tooltipAlign,
}) => (
  <span
    data-tooltip={`Bounced ${count}x`}
    className={`flex ${className} items-center ${tooltipAlign ? `align-${tooltipAlign}` : ''}`}
    style={{ minWidth }}>
    <BouncedIcon className={iconClassName(margin)} />
  </span>
)

/** @deprecated Use transfix-ui/components/icons instead */
export const MustGo: FC<IconWithTooltipProps> = ({ className = '', tooltipAlign = 'bottom' }) => (
  <Tooltip
    effect='float'
    position={tooltipAlign}
    className='must-go-tooltip-container'
    trigger={
      <svg
        className={className}
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.5366 4.30774C12.5366 3.75545 12.0889 3.30774 11.5366 3.30774C10.9843 3.30774 10.5366 3.75545 10.5366 4.30774V6.72193C10.5366 7.27422 10.9843 7.72193 11.5366 7.72193C12.0889 7.72193 12.5366 7.27422 12.5366 6.72193V4.30774ZM5.00105 6.01482C4.61053 5.62429 3.97736 5.62429 3.58684 6.01482C3.19631 6.40534 3.19631 7.03851 3.58684 7.42903L5.30091 9.14311C5.69144 9.53363 6.3246 9.53363 6.71513 9.14311C7.10565 8.75258 7.10565 8.11942 6.71513 7.72889L5.00105 6.01482ZM15.4142 20.9999C15.4092 21 15.4041 21 15.3991 21H7.67366C7.66859 21 7.66354 21 7.65848 20.9999H4.29395C3.74166 20.9999 3.29395 20.5522 3.29395 19.9999C3.29395 19.4477 3.74166 18.9999 4.29395 18.9999H5.7092C5.70839 18.9756 5.70798 18.9513 5.70798 18.927L5.70798 15.7081C5.70798 14.0521 6.29909 12.4454 7.37889 11.2456C8.46181 10.0424 9.95454 9.34323 11.5364 9.34323C12.3173 9.34323 13.0876 9.51431 13.8022 9.8432C14.5164 10.1719 15.1581 10.6504 15.6938 11.2456C16.7736 12.4454 17.3647 14.0521 17.3647 15.7081V18.927C17.3647 18.9513 17.3643 18.9756 17.3635 18.9999H18.7791C19.3314 18.9999 19.7791 19.4477 19.7791 19.9999C19.7791 20.5522 19.3314 20.9999 18.7791 20.9999H15.4142ZM10.5 18.9999H7.7235C7.71598 18.9846 7.70798 18.9601 7.70798 18.927L7.70798 15.7081C7.70798 14.5185 8.13427 13.396 8.86548 12.5835C9.59355 11.7746 10.5571 11.3432 11.5364 11.3432C12.0236 11.3432 12.509 11.4497 12.966 11.66C13.4234 11.8705 13.8462 12.1824 14.2073 12.5835C14.9385 13.396 15.3647 14.5185 15.3647 15.7081V18.927C15.3647 18.9601 15.3567 18.9846 15.3492 18.9999H12.5V17C12.5 16.4477 12.0523 16 11.5 16C10.9477 16 10.5 16.4477 10.5 17V18.9999ZM19.9932 6.01482C19.6027 5.62429 18.9696 5.62429 18.579 6.01482L16.865 7.72889C16.4744 8.11942 16.4744 8.75258 16.8649 9.14311C17.2555 9.53363 17.8886 9.53363 18.2792 9.14311L19.9932 7.42903C20.3838 7.03851 20.3838 6.40534 19.9932 6.01482Z'
          fill='currentColor'
        />
      </svg>
    }>
    <p>MUST GO</p>
  </Tooltip>
)

/** @deprecated Use transfix-ui/components/icons instead */
export const WarningFilled: FC<ICommonProps> = ({ className, dataTest }) => (
  <svg
    className={`icon ${className}`}
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    data-test={dataTest}
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.3633 3.9041L2.25653 17.1827C2.08938 17.4667 2.00094 17.7887 2.00001 18.1167C1.99907 18.4446 2.08567 18.7671 2.25119 19.052C2.41671 19.3369 2.65538 19.5744 2.94346 19.7407C3.23153 19.907 3.55897 19.9964 3.8932 20H20.1068C20.441 19.9964 20.7685 19.907 21.0565 19.7407C21.3446 19.5744 21.5833 19.3369 21.7488 19.052C21.9143 18.7671 22.0009 18.4446 22 18.1167C21.9991 17.7887 21.9106 17.4667 21.7435 17.1827L13.6367 3.9041C13.466 3.62811 13.2258 3.39992 12.9391 3.24156C12.6524 3.0832 12.329 3 12 3C11.671 3 11.3476 3.0832 11.0609 3.24156C10.7742 3.39992 10.534 3.62811 10.3633 3.9041Z'
      fill='currentColor'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      style={{ fill: 'white' }}
      clipRule='evenodd'
      d='M12 7.10278C12.5523 7.10278 13 7.5505 13 8.10278V12.1028C13 12.6551 12.5523 13.1028 12 13.1028C11.4477 13.1028 11 12.6551 11 12.1028V8.10278C11 7.5505 11.4477 7.10278 12 7.10278Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11 16.1028C11 15.5505 11.4477 15.1028 12 15.1028H12.01C12.5623 15.1028 13.01 15.5505 13.01 16.1028C13.01 16.6551 12.5623 17.1028 12.01 17.1028H12C11.4477 17.1028 11 16.6551 11 16.1028Z'
      fill='white'
    />
  </svg>
)

/** @deprecated Use transfix-ui/components/icons instead */
export const Success: FC<ICommonProps> = ({ className = '', dataTest }) => (
  <svg
    className={`icon ${className}`}
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    data-test={dataTest}
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z'
      fill='currentColor'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.2007 8.58656C17.5947 8.97355 17.6004 9.60668 17.2134 10.0007L11.0259 16.3007C10.8379 16.4922 10.5808 16.6 10.3125 16.6C10.0442 16.6 9.78708 16.4922 9.59905 16.3007L6.78655 13.4371C6.39956 13.043 6.40527 12.4099 6.79929 12.0229C7.19332 11.6359 7.82646 11.6416 8.21345 12.0357L10.3125 14.1729L15.7866 8.5993C16.1735 8.20527 16.8067 8.19956 17.2007 8.58656Z'
      fill='white'
    />
  </svg>
)

/** @deprecated Use transfix-ui/components/icons instead */
export const AlertFilled: FC<ICommonProps> = ({ className, dataTest }) => (
  <svg
    className={`icon ${className}`}
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    data-test={dataTest}
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11 13C11 13.5523 11.4477 14 12 14C12.5523 14 13 13.5523 13 13L13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8V13ZM12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16H12.01C12.5623 16 13.01 16.4477 13.01 17C13.01 17.5523 12.5623 18 12.01 18H12Z'
      fill='white'
    />
  </svg>
)

/** @deprecated Use transfix-ui/components/icons instead */
export const ShieldFilled: FC<ICommonProps> = ({ className = '' }) => (
  <svg
    className={`icon ${className}`}
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0)'>
      <path d='M12 22C12 22 20 18 20 12V5L12 2L4 5V12C4 18 12 22 12 22Z' fill='currentColor' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.6489 1.06367C11.8753 0.978776 12.1247 0.978776 12.3511 1.06367L20.3511 4.06367C20.7414 4.21003 21 4.58316 21 5V12C21 15.4464 18.7183 18.2003 16.6585 20.0026C15.6076 20.9221 14.5615 21.6408 13.78 22.1292C13.3882 22.3741 13.0603 22.5627 12.8281 22.6913C12.7119 22.7556 12.6194 22.805 12.5547 22.839C12.5223 22.856 12.4968 22.8692 12.4788 22.8784L12.4573 22.8894L12.4508 22.8926L12.4486 22.8937C12.4483 22.8939 12.4472 22.8944 12 22C11.5528 22.8944 11.5525 22.8943 11.5522 22.8941L11.5492 22.8926L11.5427 22.8894L11.5212 22.8784C11.5032 22.8692 11.4777 22.856 11.4453 22.839C11.3806 22.805 11.2881 22.7556 11.1719 22.6913C10.9397 22.5627 10.6118 22.3741 10.22 22.1292C9.43854 21.6408 8.39238 20.9221 7.3415 20.0026C5.28175 18.2003 3 15.4464 3 12V5C3 4.58316 3.25857 4.21003 3.64888 4.06367L11.6489 1.06367ZM12 22L11.5522 22.8941C11.8337 23.0349 12.1657 23.0352 12.4472 22.8944L12 22ZM12 20.8628C12.1916 20.7541 12.4367 20.6103 12.72 20.4333C13.4385 19.9842 14.3924 19.3279 15.3415 18.4974C17.2817 16.7997 19 14.5536 19 12V5.693L12 3.068L5 5.693V12C5 14.5536 6.71825 16.7997 8.6585 18.4974C9.60762 19.3279 10.5615 19.9842 11.28 20.4333C11.5633 20.6103 11.8084 20.7541 12 20.8628Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 -4.37114e-08C12.5523 -6.78525e-08 13 0.447715 13 1L13 23C13 23.5523 12.5523 24 12 24C11.4477 24 11 23.5523 11 23L11 1C11 0.447715 11.4477 -1.95703e-08 12 -4.37114e-08Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1 10C1 9.44772 1.44772 9 2 9L22 9C22.5523 9 23 9.44772 23 10C23 10.5523 22.5523 11 22 11L2 11C1.44772 11 1 10.5523 1 10Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

/** @deprecated Use transfix-ui/components/icons instead */
export const RejectedFilled: FC<ICommonProps> = ({ className = '' }) => (
  <svg
    className={`icon ${className}`}
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM8.29289 8.13005C8.68342 7.73953 9.31658 7.73953 9.70711 8.13005L12.0814 10.5044L14.2929 8.29289C14.6834 7.90237 15.3166 7.90237 15.7071 8.29289C16.0976 8.68342 16.0976 9.31658 15.7071 9.70711L13.4956 11.9186L15.7071 14.1301C16.0976 14.5206 16.0976 15.1537 15.7071 15.5443C15.3166 15.9348 14.6834 15.9348 14.2929 15.5443L12.0814 13.3328L9.70711 15.7071C9.31658 16.0976 8.68342 16.0976 8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929L10.6672 11.9186L8.29289 9.54426C7.90237 9.15374 7.90237 8.52058 8.29289 8.13005Z'
      fill='currentColor'
    />
  </svg>
)

export interface ILoadSubscribersIcon {
  isEnabled?: boolean
}

export const BellToggle = memo<ILoadSubscribersIcon>(({ isEnabled = false }) => {
  if (isEnabled) return <BellOn className='large' />
  return <BellOff className='icon-secondary large' />
})

export const SmartPhoneToggle = memo<ILoadSubscribersIcon>(({ isEnabled = false }) => {
  return isEnabled ? (
    <SmartphoneOn className='large' />
  ) : (
    <SmartphoneOff className='icon-secondary large' />
  )
})

/** @deprecated Use transfix-ui/components/icons instead */
export const MultiTruck: FC<ICommonProps> = ({ className = '' }) => {
  return (
    <svg
      className={`icon ${className}`}
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_13826_172373)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M14.0787 10.8333H12.5007V13.3333H15.834V12.1772L14.0787 10.8333ZM12.5007 9.16667C11.5802 9.16667 10.834 9.91286 10.834 10.8333V13.3333C10.834 14.2538 11.5802 15 12.5007 15H15.834C16.7545 15 17.5007 14.2538 17.5007 13.3333V12.1772C17.5007 11.6584 17.2591 11.1692 16.8472 10.8538L15.0919 9.50999C14.8011 9.28733 14.445 9.16667 14.0787 9.16667H12.5007Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M5.00065 16.6667C5.46089 16.6667 5.83398 16.2936 5.83398 15.8333C5.83398 15.3731 5.46089 15 5.00065 15C4.54041 15 4.16732 15.3731 4.16732 15.8333C4.16732 16.2936 4.54041 16.6667 5.00065 16.6667ZM7.50065 15.8333C7.50065 17.214 6.38136 18.3333 5.00065 18.3333C3.61994 18.3333 2.50065 17.214 2.50065 15.8333C2.50065 14.4526 3.61994 13.3333 5.00065 13.3333C6.38136 13.3333 7.50065 14.4526 7.50065 15.8333Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.5007 16.6667C12.9609 16.6667 13.334 16.2936 13.334 15.8333C13.334 15.3731 12.9609 15 12.5007 15C12.0404 15 11.6673 15.3731 11.6673 15.8333C11.6673 16.2936 12.0404 16.6667 12.5007 16.6667ZM15.0007 15.8333C15.0007 17.214 13.8814 18.3333 12.5007 18.3333C11.1199 18.3333 10.0007 17.214 10.0007 15.8333C10.0007 14.4526 11.1199 13.3333 12.5007 13.3333C13.8814 13.3333 15.0007 14.4526 15.0007 15.8333Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10.834 6.66667H2.50065L2.50065 13.3333H10.834V6.66667ZM2.50065 5C1.58018 5 0.833984 5.74619 0.833984 6.66667V13.3333C0.833984 14.2538 1.58018 15 2.50065 15H10.834C11.7545 15 12.5007 14.2538 12.5007 13.3333V6.66667C12.5007 5.74619 11.7545 5 10.834 5H2.50065Z'
          fill='white'
        />
        <path
          d='M14.333 7.49984H16.5778L18.333 8.84369V9.99984C17.9232 9.99984 17.591 10.3321 17.591 10.7419V10.9245C17.591 11.3343 17.9232 11.6665 18.333 11.6665C19.2535 11.6665 19.9997 10.9203 19.9997 9.99984V8.84369C19.9997 8.32491 19.7581 7.83572 19.3462 7.52035L17.591 6.17649C17.3001 5.95383 16.944 5.83317 16.5778 5.83317H14.9997V3.33317C14.9997 2.4127 14.2535 1.6665 13.333 1.6665H4.99967C4.0792 1.6665 3.33301 2.4127 3.33301 3.33317V3.49986C3.33301 3.96009 3.7061 4.33319 4.16634 4.33319C4.62658 4.33319 4.99967 3.9601 4.99967 3.49986V3.33317H13.333V6.49984C13.333 7.05212 13.7807 7.49984 14.333 7.49984Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_13826_172373'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

/** @deprecated Use transfix-ui/components/icons instead */
export const SortArrows: FC<ICommonProps> = ({ className = '' }) => (
  <svg
    aria-hidden='true'
    width='14'
    height='13'
    viewBox='0 0 14 13'
    className={`icon icon-sort-arrows ${className}`}>
    <path
      className='sort-arrow-up'
      d='M7 -6.11959e-07L0.937823 5.25L13.0622 5.25L7 -6.11959e-07Z'
      fill='#0D0E10'
    />
    <path className='sort-arrow-down' d='M7 13L0.937823 7.75L13.0622 7.75L7 13Z' fill='#A9B2BD' />
  </svg>
)

interface ICommonProps {
  className?: string
  dataTest?: string
}

/** @deprecated Use transfix-ui/components/icons instead */
export const Specialized: FC<ICommonProps> = ({ className = '', ...props }) => {
  const cls = `icon-default inline ${className || 'icon-secondary large'}`

  return (
    <svg
      width='16px'
      height='16px'
      aria-hidden='true'
      viewBox='0 0 16 16'
      className={cls}
      {...props}>
      <g transform='translate(-428.000000, -44.000000)' fill='#404041'>
        <path d='M428,53 L437,44 L444,44 L444,51 L435,60 L428,53 Z M439.5,50 C440.328427,50 441,49.3284271 441,48.5 C441,47.6715729 440.328427,47 439.5,47 C438.671573,47 438,47.6715729 438,48.5 C438,49.3284271 438.671573,50 439.5,50 Z' />
      </g>
    </svg>
  )
}

interface ICommonProps {
  margin?: string
  minWidth?: string
  className?: string
}

interface ITrackingProps extends ICommonProps {
  toolTip: string
  minWidth?: string
}

interface AssigneeAndWebProps extends ICommonProps {
  tooltip?: string
}

/** @deprecated Use transfix-ui/components/icons instead */
export const Tracking: FC<ITrackingProps> = ({ className, minWidth = '35px', toolTip }) => {
  useDeprecated()

  return (
    <span data-tooltip={toolTip} className='flex items-center' style={{ minWidth }}>
      <svg className={className} width='20px' height='20px' aria-hidden='true' viewBox='0 0 20 20'>
        <circle id='Oval-2' cx='10' cy='10' r='10' />
        <path
          d='M10.9610212,12.0416749 C10.9610212,12.0416749 15.8732401,16.0537076 15.589832,15.2574046 C15.306424,14.4611017 10.8195005,3.33157583 10.8195005,3.33157583 C10.8195005,3.33157583 6.25813248,14.680353 6.04916899,15.2574046 C5.84020551,15.8344564 10.9610212,12.0416749 10.9610212,12.0416749 Z'
          id='Oval-4'
          transform='translate(10.822292, 9.346785) rotate(40.000000) translate(-10.822292, -9.346785) '
        />
      </svg>
    </span>
  )
}

/** @deprecated Use transfix-ui/components/icons instead */
export const Assignee: FC<AssigneeAndWebProps> = ({ margin = '', tooltip = undefined }) => {
  useDeprecated()

  return (
    <span data-tooltip={tooltip} className='flex items-center'>
      <svg
        className={`{${iconClassName(margin.split(' ')[1])} ${iconClassName(margin.split(' ')[0])}`}
        width='24px'
        height='24px'
        aria-hidden='true'
        viewBox='0 0 24 24'>
        <g id='assignee' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
          <g id='baseline-account_circle-24px-(2)'>
            <path
              d='M12,0 C5.376,0 0,5.376 0,12 C0,18.624 5.376,24 12,24 C18.624,24 24,18.624 24,12 C24,5.376 18.624,0 12,0 Z M12,3.6 C13.992,3.6 15.6,5.208 15.6,7.2 C15.6,9.192 13.992,10.8 12,10.8 C10.008,10.8 8.4,9.192 8.4,7.2 C8.4,5.208 10.008,3.6 12,3.6 Z M12,20.64 C9,20.64 6.348,19.104 4.8,16.776 C4.836,14.388 9.6,13.08 12,13.08 C14.388,13.08 19.164,14.388 19.2,16.776 C17.652,19.104 15,20.64 12,20.64 Z'
              id='Shape'
              fill='#D6D6D6'
              fillRule='nonzero'
            />
            <polygon id='Shape' points='0 0 24 0 24 24 0 24' />
          </g>
        </g>
      </svg>
    </span>
  )
}

/** @deprecated Use transfix-ui/components/icons instead */
export const ApptWarning: FC<IApptCommonProps> = ({ tooltip = '' }) => (
  <span data-tooltip={tooltip} className='flex items-center'>
    <svg
      width='20px'
      height='20px'
      aria-hidden='true'
      viewBox='0 0 20 20'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'>
      <g id='Icon/send-warning' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Bounding-Box'>
          <polygon id='Path' points='0 0 20 0 20 20 0 20' />
        </g>
        <g id='Icon' transform='translate(1.000000, 3.000000)'>
          <path
            d='M15.6666667,0.333333333 L2.33333333,0.333333333 C1.41666667,0.333333333 0.675,1.08333333 0.675,2 L0.666666667,12 C0.666666667,12.9166667 1.41666667,13.6666667 2.33333333,13.6666667 L15.6666667,13.6666667 C16.5833333,13.6666667 17.3333333,12.9166667 17.3333333,12 L17.3333333,2 C17.3333333,1.08333333 16.5833333,0.333333333 15.6666667,0.333333333 Z M15.6666667,3.66666667 L9,7.83333333 L2.33333333,3.66666667 L2.33333333,2 L9,6.16666667 L15.6666667,2 L15.6666667,3.66666667 Z'
            id='Shape'
            fill='#697684'
            fillRule='nonzero'
          />
          <g id='Clock' transform='translate(6.000000, 4.000000)' stroke='#FFFFFF'>
            <circle id='Oval' strokeWidth='1.54015714' fill='#E16A0E' cx='6' cy='6' r='6' />
            <polyline id='Path-2' strokeWidth='1.75' strokeLinecap='round' points='6 3 6 7 9 7' />
          </g>
        </g>
      </g>
    </svg>
  </span>
)

interface IApptCommonProps extends ICommonProps {
  tooltip?: string
}

/** @deprecated Use transfix-ui/components/icons instead */
export const ApptActive: FC<IApptCommonProps> = ({ tooltip = '' }) => (
  <span data-tooltip={tooltip} className='flex items-center'>
    <svg
      width='20px'
      height='20px'
      aria-hidden='true'
      viewBox='0 0 20 20'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'>
      <g id='Icon/send-active' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Bounding-Box'>
          <polygon id='Path' points='0 0 20 0 20 20 0 20' />
        </g>
        <g id='Icon' transform='translate(1.000000, 3.000000)' fill='#697684' fillRule='nonzero'>
          <path
            d='M15.6666667,0.333333333 L2.33333333,0.333333333 C1.41666667,0.333333333 0.675,1.08333333 0.675,2 L0.666666667,12 C0.666666667,12.9166667 1.41666667,13.6666667 2.33333333,13.6666667 L15.6666667,13.6666667 C16.5833333,13.6666667 17.3333333,12.9166667 17.3333333,12 L17.3333333,2 C17.3333333,1.08333333 16.5833333,0.333333333 15.6666667,0.333333333 Z M15.6666667,3.66666667 L9,7.83333333 L2.33333333,3.66666667 L2.33333333,2 L9,6.16666667 L15.6666667,2 L15.6666667,3.66666667 Z'
            id='Shape'
          />
        </g>
      </g>
    </svg>
  </span>
)
