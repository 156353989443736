import React, { createContext, useContext, useState, PropsWithChildren } from 'react'
import { useEffectOnce } from 'react-use'
import { Spinner } from 'transfix-ui/components/Spinner'
import { initDDR } from 'utils/tools/datadog'
import { initGA } from 'utils/googleAnalytics'

interface IAnalyticsState {
  hasLoadedAnalytics: boolean
}

const analyticsDefaultState: IAnalyticsState = {
  hasLoadedAnalytics: false,
}

/**
 * Init the context
 */
const AnalyticsContext = createContext<IAnalyticsState>(analyticsDefaultState)

/**
 * Provide default Provider state component to wrap around app
 */
export function AnalyticsProvider({ children }: PropsWithChildren<{}>) {
  const [analyticsState, setAnalyticsState] = useState(analyticsDefaultState)

  /**
   * Loads up third party analytics tools
   * @note This should only run once, when the app loads
   */
  useEffectOnce(() => {
    // Only load analytics in production or staging
    if (__PROD__ || __STAGE__ || __STAGING_NEW__) {
      initGA()
      initDDR()
    }
    setAnalyticsState({
      hasLoadedAnalytics: true,
    })
  })

  return (
    <AnalyticsContext.Provider value={analyticsState}>
      {analyticsState.hasLoadedAnalytics ? children : <Spinner />}
    </AnalyticsContext.Provider>
  )
}

// Helper method for accessing this context
export const useAnalyticsContext = () => useContext(AnalyticsContext)
