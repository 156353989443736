import React, { FunctionComponent } from 'react'
import Typography from 'transfix-ui/core/Typography'

export const RouteNotFound: FunctionComponent = () => (
  <>
    <section className='container tc'>
      <Typography variant='h1'>We&#39;re sorry!</Typography>
      <Typography variant='h2'>We can&rsquo;t find that page!</Typography>
      <p>
        Please click your browser&rsquo;s &quot;Back&quot; button to return to the previous page.
      </p>
    </section>
  </>
)

export default RouteNotFound
