import React, {
  useRef,
  useState,
  useEffect,
  InputHTMLAttributes,
  ChangeEvent,
  FocusEvent,
  RefObject,
  forwardRef,
  useCallback,
  ReactNode,
} from 'react'
import classNames from 'classnames'
import { IconButton } from 'transfix-ui/components/IconButton'
import uuid from 'uuid/v4'
import {
  LocationFilledIcon,
  PriceIcon,
  DryVanIcon,
  DropTrailerIcon,
  NumberIcon,
  SearchIcon,
  ClearIcon,
  ClockIcon,
  PlusIcon,
  PeopleIcon,
  CustomerIcon,
  CalendarIcon,
} from 'transfix-icons'
import 'scss/5_components/_text-input.scss'

export enum TextInputIconType {
  search = 'search',
  locationPin = 'locationPin',
  clock = 'clock',
  price = 'price',
  dryVanIcon = 'dryVanIcon',
  dropTrailerIcon = 'dropTrailerIcon',
  numberIcon = 'numberIcon',
  plus = 'plus',
  people = 'people',
  customer = 'customer',
  calendar = 'calendar',
}
export interface ITextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  value?: string | number
  clearable?: boolean
  invalid?: boolean
  icon?: TextInputIconType
  validationMessage?: string
  helperMessage?: string
  dataTest?: string
  large?: boolean
  className?: string
  onClearButtonClick?: Function
  /**
   * A ReactNode can be passed here to
   * add an icon or avatar before the <input /> element
   */
  startAdornment?: ReactNode
  /**
   * Highlights the field with a background color.
   */
  highlighted?: 'info' | 'warning'
}

/** @deprecated */
export const TextInput = forwardRef<HTMLInputElement, ITextInputProps>(
  (
    {
      className,
      clearable = true,
      dataTest,
      disabled = false,
      helperMessage,
      highlighted,
      icon,
      id,
      invalid = false,
      label,
      large,
      name,
      onBlur,
      onChange,
      onClearButtonClick,
      placeholder,
      required = false,
      startAdornment,
      validationMessage,
      value = '',
      ...rest
    },
    ref
  ) => {
    const baseRef = useRef<HTMLInputElement>(null)
    const inputEl = !ref ? baseRef : (ref as RefObject<HTMLInputElement>)

    const [inputValue, setInputValue] = useState<string | number>('')

    const idRef = useRef<string>(`textinput-${uuid()}`)
    const inputId = id || idRef.current

    useEffect(() => setInputValue(value), [value])

    const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault()
      e.stopPropagation()
      const { value: eventValue } = e.target
      setInputValue(eventValue)
      if (onChange) {
        onChange({ target: { value: eventValue, id: inputId, name } } as ChangeEvent<
          HTMLInputElement
        >)
      }
    }

    const blurHandler = (e: FocusEvent<HTMLInputElement>) => {
      e.preventDefault()

      if (onBlur) {
        onBlur(e)
      }
    }

    const clearButtonClickHandler = useCallback(() => {
      setInputValue('')
      if (onChange) {
        onChange({ target: { value: '', id: inputId, name } } as ChangeEvent<HTMLInputElement>)
      }
      if (inputEl.current) {
        inputEl.current.focus()
      }
      if (onClearButtonClick) {
        onClearButtonClick()
      }
    }, [inputEl, inputId, name, onChange, onClearButtonClick])

    const INPUT_ICONS = {
      search: <SearchIcon size='large' />,
      locationPin: <LocationFilledIcon size='large' />,
      clock: <ClockIcon size='large' />,
      price: <PriceIcon size='large' />,
      dryVanIcon: <DryVanIcon size='large' />,
      dropTrailerIcon: <DropTrailerIcon size='large' />,
      numberIcon: <NumberIcon size='large' />,
      plus: <PlusIcon size='large' color='skyBlue6' />,
      people: <PeopleIcon size='large' />,
      customer: <CustomerIcon size='large' />,
      calendar: <CalendarIcon size='large' />,
    }

    const containerClasses = classNames(
      'text-input',
      {
        'text-input-large': large,
        invalid,
        disabled,
        [`text-input-highlighted-${highlighted}`]: highlighted,
      },
      className
    )

    // Input can either have icon or adornment but not both
    const inputIcon = startAdornment ? (
      <span className='adornment-wrapper xsmall mr2'>{startAdornment}</span>
    ) : (
      icon && INPUT_ICONS[icon]
    )

    return (
      <div className={containerClasses}>
        {label && (
          <label
            htmlFor={inputId}
            id={`label-${inputId}`}
            className={`label ${disabled ? 'disabled' : ''} ${required ? 'required' : ''}`}>
            {label}
          </label>
        )}
        <div className='text-input-container'>
          {inputIcon}
          <input
            {...rest}
            data-test={dataTest}
            className={invalid ? 'invalid' : ''}
            name={name}
            value={inputValue}
            id={inputId}
            disabled={disabled}
            placeholder={placeholder}
            onChange={changeHandler}
            onBlur={blurHandler}
            ref={inputEl}
          />
          {clearable && (
            <IconButton
              Icon={ClearIcon}
              color='tertiary'
              noPadding
              onClick={clearButtonClickHandler}
              tabIndex={-1}
              label={`Clear ${label} text field`}
              className={`clear-all-button ${inputValue ? '' : 'hidden'}`}
              data-test={`${dataTest}-clear-all-button`}
              size='large'
            />
          )}
        </div>
        {(invalid || helperMessage) && (
          <span data-test={`${dataTest}-message`} className='validation-message'>
            {invalid ? validationMessage : helperMessage}
          </span>
        )}
      </div>
    )
  }
)
