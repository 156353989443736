import React, { FC } from 'react'
import { RestfulProvider } from 'restful-react'
import { getUserCookieValues } from 'utils/userAuth'

interface IRequestHeaders {
  'Content-Type': string
  'x-user-email': string
  'x-user-token': string
  'x-user-id': string
  'x-camel-case'?: boolean
}

const getRequestOptions = (): { headers: any } => {
  const { authToken, email, userId: userIdHeader } = getUserCookieValues()

  const authHeaders = {
    'Content-Type': 'application/json',
    'x-user-email': email,
    'x-user-token': authToken,
    'x-user-id': userIdHeader,
    'x-camel-case': true,
    'x-rogers-revision': __ROGERS_VERSION__,
  } as IRequestHeaders

  return { headers: authHeaders }
}

interface IRogersRestfulProviderProps {
  children: React.ReactNode
  onUnauthenticatedResponse: () => void
  baseApi: string
}

export const RogersRestfulProvider: FC<IRogersRestfulProviderProps> = ({
  baseApi,
  children,
  onUnauthenticatedResponse,
}) => (
  <RestfulProvider
    onResponse={res => {
      if (res.status === 401) {
        onUnauthenticatedResponse()
      }
    }}
    requestOptions={getRequestOptions}
    base={baseApi}
    queryParamStringifyOptions={{
      encodeValuesOnly: true,
      arrayFormat: 'brackets',
    }}>
    {children}
  </RestfulProvider>
)
