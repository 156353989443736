import { brandColors } from 'transfix-ui/theme/colors'

interface IGetIconColorProps {
  disabled?: boolean
  invalid?: boolean
}

export const getIconColor = ({ disabled = false, invalid = false }: IGetIconColorProps): string => {
  let iconColor = brandColors.skyBlue6

  if (disabled) {
    iconColor = brandColors.coolGray5
  } else if (invalid) {
    iconColor = brandColors.error
  }

  return iconColor
}
