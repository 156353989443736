import { ENDPOINTS } from 'utils/api/callApi'

export const SIDEBAR_ACTION_TYPES = {
  SIDEBAR_REQUESTED: 'SIDEBAR_REQUESTED',
  SIDEBAR_OPEN: 'SIDEBAR_OPEN',
  SIDEBAR_CLOSED: 'SIDEBAR_CLOSED',
  SIDEBAR_EXTRA: 'SIDEBAR_EXTRA',
}

export const handleSidebar = (id, type) => ({
  payload: { id, type },
  type: id ? SIDEBAR_ACTION_TYPES.SIDEBAR_REQUESTED : SIDEBAR_ACTION_TYPES.SIDEBAR_CLOSED,
})

/**
 * Updates the values of the selected shipment displayed on the sidebar panel
 * @param {number} id shipment id
 * @param {Object} params IShipment type
 *
 * TODO: Remove sidebar reducer and migrate to one consolidated shipment reducer, currently this is
 * fragmented in shipment & sidebar reducer
 */
export const updateSidebarShipment = (id, params) => {
  return {
    type: '',
    payload: {
      callApi: true,
      requestData: {
        endpoint: ENDPOINTS.shipments,
        id,
        method: 'PUT',
        params: {
          includes: [
            'project44_metadata',
            'driver_tracking_information',
            'shipper_insurance_requirement',
            'auto_appts',
          ],
        },
        body: {
          shipment: params,
        },
      },
      actionTypes: ['FETCH_SHIPMENT_REQUEST', 'FETCH_SHIPMENT_SUCCESS', 'FETCH_SHIPMENT_FAIL'],
    },
  }
}
