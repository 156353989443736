import * as yup from 'yup'
import { VALIDATOR_PATTERNS } from 'utils/validators'

const REQUIRED_MESSAGE = 'This field is required.'
const MIN_VALIDATION_MESSAGE = 'Password must be at least 8 characters.'
const NEW_PASSWORD_VALIDATION_MESSAGE =
  'Password must include: uppercase letter, lowercase letter, number, and a special character (!?@#$).'

export const ResetPasswordSchema = yup.object({
  /**
   * @example 'Trucker2219@!
   */
  newPassword: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .min(8, MIN_VALIDATION_MESSAGE)
    .matches(VALIDATOR_PATTERNS.PASSWORD, NEW_PASSWORD_VALIDATION_MESSAGE),
})

export type TResetPasswordSchema = yup.InferType<typeof ResetPasswordSchema>
