import React, { memo } from 'react'
import { LogoTransfix } from 'transfix-ui/components/LogoTransfix'
import { Typography } from 'transfix-ui/core'
import Box from 'transfix-ui/core/Box'

const MaintenancePage = memo(() => {
  return (
    <Box px={2} pb={2} display='flex' flexDirection='column' justifyContent='center' height='100vh'>
      <Box p={2} display='flex' justifyContent='center'>
        <LogoTransfix width={350} />
      </Box>
      <Typography variant='h1' textAlign='center'>
        {' '}
        will be back soon!
      </Typography>
      <Typography variant='body1' textAlign='center'>
        Sorry for the inconvenience but we’re performing some maintenance at the moment.
      </Typography>
    </Box>
  )
})

export default MaintenancePage
