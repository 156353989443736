import { createStyles as muiCreateStyles, StyleRules } from '@mui/styles'

/**
 * This function doesn't really "do anything" at runtime, it's just the identity
 * function. Its only purpose is to defeat TypeScript's type widening when providing
 * style rules to `withStyles` which are a function of the `Theme`.
 *
 * @see https://v4.mui.com/styles/api/#createstyles-styles-styles
 */
function createStyles<ClassKey extends string, Props extends {}>(
  styles: StyleRules<Props, ClassKey>
): StyleRules<Props, ClassKey> {
  return muiCreateStyles(styles)
}

export default createStyles
