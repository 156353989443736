import { DefaultValues, useForm, UseFormOptions } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { ResendDetailsSchema, TResendDetailsSchema } from '../schemas'

export const getDefaultValues = (
  values: DefaultValues<TResendDetailsSchema>
): DefaultValues<TResendDetailsSchema> => {
  return ResendDetailsSchema.cast({
    ...values,
  })
}

interface IUseResendDetailsFormProps
  extends Omit<UseFormOptions<TResendDetailsSchema>, 'resolver'> {}

export const useResendDetailsForm = ({
  defaultValues = {},
  ...props
}: IUseResendDetailsFormProps) => {
  return useForm<TResendDetailsSchema>({
    resolver: yupResolver(ResendDetailsSchema),
    defaultValues: getDefaultValues(defaultValues),
    ...props,
  })
}
