/* eslint-disable camelcase */

import { UseMutateProps, useMutate } from 'restful-react'
import { ENDPOINTS } from 'utils/api/callApi'

export interface IUseResetPasswordMutationResponse {
  success: boolean
}

export interface IUseResetPasswordRequest {
  user: {
    /**
     * @example 'Trucker2202!12'
     */
    password: string
    /**
     * @example 'asdf292jflask'
     */
    reset_password_token: string
  }
}

export type IUseResetPasswordProps = Omit<
  UseMutateProps<
    IUseResetPasswordMutationResponse,
    unknown,
    void,
    IUseResetPasswordRequest,
    unknown
  >,
  'path' | 'base' | 'verb'
>

export const useResetPasswordMutation = ({ ...props }: IUseResetPasswordProps = {}) => {
  return useMutate<
    IUseResetPasswordMutationResponse,
    unknown,
    void,
    IUseResetPasswordRequest,
    unknown
  >({
    path: `/${ENDPOINTS.password}`,
    verb: 'PUT',
    base: __API__,
    /**
     * @note PUT /password returns void, so this enables checking response
     */
    resolve: () => ({ success: true }),
    ...props,
  })
}
