/* eslint-disable camelcase */

import { useCallback, useState } from 'react'
import {
  useResetPasswordMutation,
  IUseResetPasswordMutationResponse,
} from './useResetPasswordMutation'

export const useResetPassword = () => {
  const [errorMessage, setErrorMessage] = useState<string>()
  const { mutate: _resetPassword, ...state } = useResetPasswordMutation()

  /**
   * Resend user details to the email address provided
   */
  const resetPassword = useCallback(
    async (
      password: string,
      reset_password_token: string
    ): Promise<IUseResetPasswordMutationResponse | null> => {
      setErrorMessage(undefined)

      let response: IUseResetPasswordMutationResponse | null = null

      try {
        response = await _resetPassword({
          user: {
            password,
            reset_password_token,
          },
        })
      } catch (error) {
        setErrorMessage('Failed to update password. Please try again.')
      }

      return response
    },
    [_resetPassword]
  )

  return {
    ...state,
    resetPassword,
    errorMessage,
  }
}
