import React, { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Card } from 'transfix-ui/components/Card'
import { Typography } from 'transfix-ui/core'
import Box from 'transfix-ui/core/Box'
import Grid from 'transfix-ui/core/Grid'
import { IResendDetailsFormProps, ResendDetailsForm } from './components'
import { useResendDetails } from './hooks'

const getSuccessMessage = (email: string | undefined): string | undefined => {
  if (!email) {
    return undefined
  }

  return `If you created an account with the email ${email}, you will receive an email with instructions about how to reset your password in a few minutes.`
}

interface IResendDetailsPageProps {
  /**
   * @default 'Resend Instructions'
   */
  pageTitle?: string
}

const ResendDetailsPage = ({ pageTitle = 'Resend Instructions' }: IResendDetailsPageProps) => {
  const history = useHistory()
  const { location } = history || {}
  const { pathname } = location || {}
  const [successEmail, setSuccessEmail] = useState<string | undefined>()
  const { errorMessage, loading: isLoading, resendUserDetails } = useResendDetails()

  const submitSuccessHandler: IResendDetailsFormProps['onSubmitSuccess'] = useCallback(
    async ({ email }) => {
      setSuccessEmail(undefined)

      const response = await resendUserDetails(email)
      const { success } = response || {}

      if (success) {
        setSuccessEmail(email)
      }
    },
    [resendUserDetails]
  )

  const successMessage = useMemo(() => getSuccessMessage(successEmail), [successEmail])

  return (
    <Box data-test='ResendDetails' display='flex' justifyContent='center' width='100%'>
      <Box p={2} pt={8} maxWidth='500px' flex='1 1 500px'>
        <Card>
          <Box>
            <Box mb={2}>
              <Typography component='div' variant='h3'>
                {pageTitle}
              </Typography>
            </Box>
            <Box mb={2}>
              <ResendDetailsForm
                onSubmitSuccess={submitSuccessHandler}
                isLoading={isLoading}
                errorMessage={errorMessage}
                successMessage={successMessage}
              />
            </Box>
          </Box>
        </Card>
        <Box display='flex' justifyContent='center' mt={3}>
          <Grid container spacing={4} justifyContent='center'>
            <Grid item xs={12} md='auto'>
              <a href={`${__ROOT__}/login`} className='btn-link'>
                Back to login
              </a>
            </Grid>
            {!pathname.includes('forgot_password') && (
              <Grid item xs={12} md='auto'>
                <a href='/forgot_password' className='btn-link'>
                  Forgot Password
                </a>
              </Grid>
            )}
            {!pathname.includes('resend_details') && (
              <Grid item xs={12} md='auto'>
                <a href='/resend_details' className='btn-link'>
                  Didn&apos;t receive confirmation instructions?
                </a>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}

export default ResendDetailsPage
