import React, { memo, MouseEventHandler, ChangeEventHandler } from 'react'
import { Button } from 'transfix-ui/components/Button'
import { makeStyles, Theme } from 'transfix-ui/core'
import { brandColors } from 'transfix-ui/theme/colors'
import { INLINE_MESSAGE_VARIANTS } from '../constants'

const colorsByVariant = {
  [INLINE_MESSAGE_VARIANTS.ALERT]: brandColors.error,
  [INLINE_MESSAGE_VARIANTS.BLANK]: brandColors.skyBlue6,
  [INLINE_MESSAGE_VARIANTS.INFO]: brandColors.info,
  [INLINE_MESSAGE_VARIANTS.SUCCESS]: brandColors.green0,
  [INLINE_MESSAGE_VARIANTS.WARNING]: brandColors.warning,
}

const useStyles = makeStyles<Theme, { type: INLINE_MESSAGE_VARIANTS }>(theme => ({
  ctaLabel: {
    display: 'inline',
    color: ({ type }) => colorsByVariant[type],
    paddingLeft: theme.spacing(1.5),
    '&:hover': {
      textDecoration: 'underline',
    },

    '& input': {
      display: 'none',
    },
  },
}))

export interface IActionProps {
  label?: string
  url?: string
  action?: MouseEventHandler<HTMLButtonElement>
  uploadAction?: ChangeEventHandler<HTMLInputElement>
  type?: INLINE_MESSAGE_VARIANTS
}

export const Action = memo<IActionProps>(
  ({ action, label = '', type = INLINE_MESSAGE_VARIANTS.INFO, uploadAction, url }) => {
    const classes = useStyles({ type })

    if (url) {
      return (
        <a
          href={url}
          target='_blank'
          rel='noopener noreferrer'
          data-test='inline-message-cta-link'
          className='inline-message-cta-link'>
          <strong>{label}</strong>
        </a>
      )
    }

    if (uploadAction) {
      return (
        <label
          htmlFor='fileUpload'
          className={classes.ctaLabel}
          data-test='inline-message-cta-label'>
          <input id='fileUpload' type='file' onChange={uploadAction} />
          <strong>{label}</strong>
        </label>
      )
    }

    if (action) {
      return (
        <Button variant='text' onClick={action} data-test='inline-message-cta-button'>
          <span className='MuiButton-label'>{label}</span>
        </Button>
      )
    }

    return null
  }
)
