import { generateApiActionTypes } from 'utils/actions/generateActionTypes'
import { ENDPOINTS } from 'utils/api/callApi'

export const LOGS_ACTION_TYPES = generateApiActionTypes('LOGS')

export const getLogs = (params = {}) => {
  const requestData = {
    endpoint: ENDPOINTS.versions,
    params,
  }
  return {
    payload: {
      callApi: true,
      requestData,
      actionTypes: Object.keys(LOGS_ACTION_TYPES),
    },
  }
}

export const LOGS_COUNT_ACTION_TYPES = generateApiActionTypes('LOGS_COUNT')

export const getLogsCount = (params = {}) => {
  const requestData = {
    endpoint: ENDPOINTS.versions,
    params,
    group: 'count',
  }
  return {
    type: undefined,
    payload: {
      callApi: true,
      requestData,
      actionTypes: Object.keys(LOGS_COUNT_ACTION_TYPES),
    },
  }
}
