/**
 * utility function to leverage light functional programming approaches 👀
 * https://github.com/getify/Functional-Light-JS/blob/master/manuscript/ch3.md#one-on-one
 * type definition taken from: https://www.typescriptlang.org/docs/handbook/2/generics.html
 * @param arg any param
 * @returns exactly the same param
 */
export function identity<Type>(arg: Type): Type {
  return arg
}
