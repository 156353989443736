import React, { FC, forwardRef } from 'react'
import classNames from 'classnames'
import { TIconComponent } from 'transfix-icons/types'
import { TIconSize } from 'transfix-ui/components/SvgIcon/types'
import {
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
  Theme,
  makeStyles,
} from 'transfix-ui/core'
import { brandColors } from 'transfix-ui/theme/colors'

export type TIconButtonColor =
  | MuiIconButtonProps['color']
  | 'tertiary'
  | 'hotPrimary'
  | 'hotSecondary'

export type TIconButtonProps<D extends React.ElementType = 'button', P = {}> = Omit<
  MuiIconButtonProps<D, P>,
  'color' | 'size'
> & {
  Icon: FC | TIconComponent
  /**
   * @default 'primary'
   */
  color?: TIconButtonColor
  'data-test'?: string
  /**
   * @default false
   */
  fullWidth?: boolean

  /**
   * @default 'center'
   */
  justifyIcon?: 'start' | 'center' | 'end'
  label?: string
  /**
   * @default false
   */
  noPadding?: boolean
  /**
   * @default 'medium'
   */
  size?: TIconSize
}

const useIconButtonStyles = makeStyles<
  Theme,
  Pick<TIconButtonProps, 'color' | 'fullWidth' | 'justifyIcon' | 'noPadding'>
>(theme => ({
  root: ({ color, fullWidth, justifyIcon, noPadding }) => {
    let colorStyles = {}

    switch (color) {
      case 'primary':
        colorStyles = {
          '&:hover': {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.dark,
            borderColor: theme.palette.primary.dark,
          },
          '&:active': {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.dark,
            '&:hover': {
              color: theme.palette.primary.contrastText,
              backgroundColor: theme.palette.primary.dark,
              borderColor: theme.palette.primary.dark,
            },
          },
          '&:focus': {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.dark,
            borderColor: theme.palette.primary.dark,
            '&:hover': {
              color: theme.palette.primary.contrastText,
              backgroundColor: theme.palette.primary.dark,
              borderColor: theme.palette.primary.dark,
            },
          },
        }
        break
      case 'secondary':
        colorStyles = {
          '&:hover': {
            color: theme.palette.secondary.contrastText,
            backgroundColor: theme.palette.secondary.dark,
            borderColor: theme.palette.secondary.dark,
          },
          '&:active': {
            color: theme.palette.secondary.contrastText,
            backgroundColor: theme.palette.secondary.dark,
            '&:hover': {
              color: theme.palette.secondary.contrastText,
              backgroundColor: theme.palette.secondary.dark,
              borderColor: theme.palette.secondary.dark,
            },
          },
          '&:focus': {
            color: theme.palette.secondary.contrastText,
            backgroundColor: theme.palette.secondary.dark,
            borderColor: theme.palette.secondary.dark,
            '&:hover': {
              color: theme.palette.secondary.contrastText,
              backgroundColor: theme.palette.secondary.dark,
              borderColor: theme.palette.secondary.dark,
            },
          },
        }
        break
      case 'tertiary':
        colorStyles = {
          '&:hover': {
            color: brandColors.skyBlue7,
            backgroundColor: brandColors.skyBlue2,
            borderColor: brandColors.skyBlue2,
          },
          '&:active': {
            color: brandColors.skyBlue7,
            backgroundColor: brandColors.skyBlue2,
            '&:hover': {
              color: brandColors.skyBlue7,
              backgroundColor: brandColors.skyBlue2,
              borderColor: brandColors.skyBlue2,
            },
          },
          '&:focus': {
            color: brandColors.skyBlue7,
            backgroundColor: brandColors.skyBlue2,
            borderColor: brandColors.skyBlue3,
            '&:hover': {
              color: brandColors.skyBlue7,
              backgroundColor: brandColors.skyBlue2,
              borderColor: brandColors.skyBlue2,
            },
          },
        }
        break
      case 'hotPrimary':
        colorStyles = {
          '&:active': {
            color: brandColors.white,
            backgroundColor: brandColors.error1,
          },
          '&:focus': {
            borderColor: brandColors.hotButtonHover,
            backgroundColor: brandColors.hotButtonHover,
            color: brandColors.white,
            boxShadow: `0 0 0 3px ${brandColors.hotButtonFocus}`,
            '&:hover': {
              borderColor: brandColors.hotButtonHover,
              backgroundColor: brandColors.hotButtonHover,
              color: brandColors.white,
              boxShadow: `0 0 0 3px ${brandColors.hotButtonFocus}`,
            },
          },
          '&:hover': {
            backgroundColor: brandColors.hotButtonHover,
            borderColor: brandColors.hotButtonHover,
            color: brandColors.white,
          },
        }
        break
      case 'hotSecondary':
        colorStyles = {
          '&:active': {
            color: brandColors.error1,
            backgroundColor: brandColors.error0,
          },
          '&:focus': {
            borderColor: brandColors.error0,
            backgroundColor: brandColors.error0,
            color: brandColors.error1,
            boxShadow: `0 0 0 3px ${brandColors.hotButtonFocus}`,
            '&:hover': {
              borderColor: brandColors.error0,
              backgroundColor: brandColors.error0,
              color: brandColors.error1,
              boxShadow: `0 0 0 3px ${brandColors.hotButtonFocus}`,
            },
          },
          '&:hover': {
            backgroundColor: brandColors.error0,
            borderColor: brandColors.error1,
            color: brandColors.error1,
          },
        }
        break
      default:
        colorStyles = {}
    }

    const fullWidthStyles = fullWidth
      ? {
          width: '100%',
        }
      : {}

    const noPaddingStyles = noPadding
      ? {
          padding: 0,
          minWidth: 'auto',
        }
      : {}

    return {
      /**
       * Overrides MUI default of 64px
       */
      minWidth: 42,
      color: 'inherit',
      justifyContent: justifyIcon,
      '&.MuiIconButton-root': {
        ...colorStyles,
      },
      ...fullWidthStyles,
      ...noPaddingStyles,
    }
  },
}))

/**
 * @name IconButton
 * @see https://mui.com/material-ui/api/icon-button/
 */
const IconButtonComponent = <D extends React.ElementType, P = {}>(
  {
    Icon: IconComponent,
    className: classNameProp,
    color = 'primary',
    fullWidth = false,
    justifyIcon = 'center',
    label,
    noPadding = false,
    size = 'medium',
    ...props
  }: TIconButtonProps<D, P>,
  ref:
    | ((instance: HTMLButtonElement | null) => void)
    | React.MutableRefObject<HTMLButtonElement | null>
    | null
) => {
  const classes = useIconButtonStyles({
    color,
    fullWidth,
    justifyIcon,
    noPadding,
  })

  const className = classNames(classes.root, classNameProp)

  return (
    <MuiIconButton {...props} ref={ref} aria-label={label} className={className}>
      <IconComponent size={size} />
    </MuiIconButton>
  )
}

export const IconButton = forwardRef<HTMLButtonElement, TIconButtonProps>(IconButtonComponent)

export default IconButton
