import React, { FC, ReactNode } from 'react'
import classname from 'classnames'
import { makeStyles } from 'transfix-ui/core'
import { calcRem, brandColors } from 'transfix-ui/theme'

export interface LabelComponentProps {
  /**
   * @example 'First name'
   */
  label: ReactNode
  /**
   * @default false
   */
  required?: boolean
  /**
   * @default false
   */
  disabled?: boolean
  className?: string
}

const useLabelStyles = makeStyles(theme => ({
  root: {
    // Overrides styles in _forms.scss for label > span
    fontSize: `${calcRem(12)} !important`,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: 1.6,
  },
  required: {
    '&::after': {
      color: theme.palette.error.main,
      content: "'\\2022'",
      paddingLeft: 2,
      position: 'relative',
    },
  },
  disabled: {
    color: brandColors.coolGray5,
  },
}))

/**
 * Convenience component for adding the red dot to Material UI input
 * components when they're required.
 */
export const LabelComponent: FC<LabelComponentProps> = ({
  className: classNameProp,
  disabled = false,
  label,
  required = false,
}) => {
  const classes = useLabelStyles()
  const className = classname(classes.root, classNameProp, {
    [classes.required]: required,
    [classes.disabled]: disabled,
  })
  return <span className={className}>{label}</span>
}

export default LabelComponent
