import React from 'react'
import { FieldErrors, SubmitHandler, SubmitErrorHandler } from 'react-hook-form'
import get from 'lodash/get'
import ControlledTextField from 'transfix-form/components/ControlledTextInput'
import { Button } from 'transfix-ui/components/Button'
import { InlineMessage, INLINE_MESSAGE_VARIANTS } from 'transfix-ui/components/InlineMessage'
import Grid from 'transfix-ui/core/Grid'
import { useResendDetailsForm } from './hooks'
import { TResendDetailsSchema } from './schemas'

const getErrorMessage = <T extends FieldErrors<TResendDetailsSchema>, K extends keyof T>(
  errors: T,
  keyName: K
): string | undefined => {
  return get(errors, [keyName, 'message'])
}

export interface IResendDetailsFormProps {
  /**
   * @default {}
   */
  defaultValues?: Partial<TResendDetailsSchema>
  /**
   * @example (data) => {}
   */
  onSubmitSuccess: SubmitHandler<TResendDetailsSchema>
  /**
   * @example (errors) => {}
   */
  onSubmitError?: SubmitErrorHandler<TResendDetailsSchema>
  /**
   * @default false
   */
  isLoading?: boolean
  /**
   * @example 'There was an API error'
   */
  errorMessage?: string
  /**
   * @example 'Expect an email in a few minutes'
   */
  successMessage?: string
}

const ResendDetailsForm = ({
  defaultValues = {},
  errorMessage,
  successMessage,
  isLoading = false,
  onSubmitSuccess,
  onSubmitError = () => {},
}: IResendDetailsFormProps) => {
  const { control, errors, handleSubmit } = useResendDetailsForm({
    defaultValues,
  })

  const submitHandler = handleSubmit(onSubmitSuccess, onSubmitError)

  return (
    <form onSubmit={submitHandler} data-test='ResendDetailsForm'>
      <Grid container spacing={1}>
        {errorMessage && (
          <Grid item xs={12}>
            <InlineMessage
              dataTest='message-error'
              message={errorMessage}
              type={INLINE_MESSAGE_VARIANTS.ALERT}
              fullWidth
            />
          </Grid>
        )}
        {successMessage && (
          <Grid item xs={12}>
            <InlineMessage
              dataTest='message-success'
              message={successMessage}
              type={INLINE_MESSAGE_VARIANTS.INFO}
              fullWidth
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <ControlledTextField
            name='email'
            control={control}
            TextInputProps={{
              type: 'email',
              label: 'Email',
              validationMessage: getErrorMessage(errors, 'email'),
              error: !!getErrorMessage(errors, 'email'),
              placeholder: 'jane@abc.com',
              dataTest: 'input-email',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            type='submit'
            color='primary'
            size='large'
            data-test='submit-button'
            disabled={isLoading}>
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default ResendDetailsForm
