import callApi from 'utils/api/callApi'
import { DISPLAY } from 'constants/display'

export const api = store => next => async action => {
  const { payload = {}, onSuccess } = action
  if (!payload.callApi) return next(action)

  const actionWith = data => {
    const finalAction = { ...action, ...data }
    delete finalAction[payload]
    return finalAction
  }

  const [requestType, successType, failureType] = payload.actionTypes

  next(actionWith({ type: requestType, payload }))
  const { requestData } = payload
  try {
    const response = await callApi(requestData)
    const responseData = Array.isArray(response) ? { response } : response
    const nextPayload = {
      ...responseData,
      requestData,
      filters: requestData.filters || null,
    }

    if (onSuccess) {
      store.dispatch(onSuccess)
    }

    return next(
      actionWith({
        type: successType,
        payload: nextPayload,
      })
    )
  } catch (error) {
    return next(
      actionWith({
        type: failureType,
        error: error || DISPLAY.genericError,
        payload,
        filters: requestData.filters || null,
      })
    )
  }
}
