import { TIconComponent } from 'transfix-icons/types'
import { SvgIcon } from 'transfix-ui/core'
import { ClearIcon } from 'transfix-ui/core/icons'
import { ViewOffIcon, ViewOnIcon } from 'transfix-icons'

const END_ADORNMENT_ICONS: Record<string, TIconComponent | typeof SvgIcon> = {
  clear: ClearIcon,
  viewOff: ViewOffIcon,
  viewOn: ViewOnIcon,
}

interface IGetEndAdornmentIconProps {
  isClearable?: boolean
  isPassword?: boolean
  isPasswordVisible?: boolean
}

export const getEndAdornmentIcon = ({
  isClearable,
  isPassword,
  isPasswordVisible,
}: IGetEndAdornmentIconProps) => {
  if (!isClearable && !isPassword) return undefined
  if (isPassword) {
    return isPasswordVisible ? END_ADORNMENT_ICONS.viewOn : END_ADORNMENT_ICONS.viewOff
  }
  return END_ADORNMENT_ICONS.clear
}
