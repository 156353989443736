import React, { memo } from 'react'
import { Button } from 'transfix-ui/components/Button'
import Typography from 'transfix-ui/core/Typography'
import { log } from 'utils/errorLogger'

interface IDataTest {
  dataTest?: string
}

const refresh = () => window.location.reload()

export const ErrorMessage = memo<IDataTest>(({ dataTest = 'error-boundary' }) => (
  <section className='container tc' data-test={dataTest}>
    <Typography variant='h1'>We&#39;re sorry!</Typography>
    <Typography variant='h2'>Something&#39;s gone wrong.</Typography>
    <p className='pb4'>
      Please refresh your browser and try again.
      <br />
      If you&#39;re still experiencing issues, contact{' '}
      <a href='mailto:support@transfix.io'>support@transfix.io</a>.
    </p>
    <Button color='primary' onClick={refresh} onKeyPress={refresh} tabIndex={0}>
      Refresh
    </Button>
  </section>
))

interface IErrorBoundaryProps extends JSX.ElementChildrenAttribute, IDataTest {}
interface IErrorBoundaryState {
  error?: Error
  errorInfo?: {}
}

export class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
  constructor(props: IErrorBoundaryProps) {
    super(props)
    this.state = { error: undefined }
  }

  componentDidCatch(error: Error, errorInfo?: {}) {
    this.setState({ error })
    log('rogers', error, { extra: errorInfo })
  }

  render() {
    const { error } = this.state
    const { children, dataTest } = this.props
    if (error) return <ErrorMessage dataTest={dataTest} />
    return children
  }
}
