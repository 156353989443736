import React, { memo, MouseEvent } from 'react'
import { TIconComponent } from 'transfix-icons/types'
import { SvgIcon, InputAdornment, makeStyles, createStyles } from 'transfix-ui/core'
import { ClearIcon } from 'transfix-ui/core/icons'
import { getIconColor } from '../utils/getIconColor'

export interface IEndAdornmentProps {
  /**
   * @default false
   */
  invalid?: boolean
  /**
   * @default false
   */
  disabled?: boolean
  dataTest?: string
  onClick?: (e?: MouseEvent<HTMLButtonElement>) => void
  /**
   * @default ClearIcon
   */
  Icon?: TIconComponent | typeof SvgIcon
}

type TClassKey = 'icon' | 'button'

const useEndAdornmentStyles = makeStyles(() => {
  return createStyles<TClassKey, Pick<IEndAdornmentProps, 'invalid' | 'disabled'>>({
    icon: {
      color: getIconColor,
      fontSize: 'inherit',
    },
    button: {
      fontSize: 18,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderColor: 'transparent',
      padding: 0,

      '&:hover': {
        background: 'transparent',
      },
    },
  })
})

/**
 * Creates <InputAdornment /> for use with <TextField />
 */
export const EndAdornment = memo<IEndAdornmentProps>(
  ({ Icon = ClearIcon, dataTest, disabled = false, invalid = false, onClick: onClickProp }) => {
    const classes = useEndAdornmentStyles({
      invalid,
      disabled,
    })
    return (
      <InputAdornment position='end'>
        <button
          aria-label='Input End Adornment'
          onClick={onClickProp}
          type='button'
          data-test={dataTest}
          className={classes.button}>
          <Icon className={classes.icon} size='large' />
        </button>
      </InputAdornment>
    )
  }
)

export default EndAdornment
