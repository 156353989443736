import { ENDPOINTS } from 'utils/api/callApi'
import { keymirror } from 'utils/keymirror'

export const SMS_ACTION_TYPES = keymirror({
  SMS_REQUEST: null,
  SMS_SUCCESS: null,
  SMS_FAILURE: null,
})

export const SEND_SMS_ACTION_TYPES = keymirror({
  SEND_SMS_REQUEST: null,
  SEND_SMS_SUCCESS: null,
  SEND_SMS_FAILURE: null,
})

export const fetchSMS = (id, type) => {
  const requestData = {
    endpoint: ENDPOINTS.sms,
    params: {
      perPage: 100,
      sortOrder: 'desc',
      filters: { [type]: id },
    },
  }
  return {
    payload: {
      callApi: true,
      requestData,
      actionTypes: Object.keys(SMS_ACTION_TYPES),
    },
  }
}

export const sendSMS = smsMessage => {
  const requestData = {
    endpoint: ENDPOINTS.sms,
    method: 'POST',
    body: { smsMessage },
  }
  return {
    payload: {
      callApi: true,
      requestData,
      actionTypes: Object.keys(SEND_SMS_ACTION_TYPES),
    },
  }
}
