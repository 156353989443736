import React, { memo } from 'react'
import classNames from 'classnames'
import 'scss/5_components/_loading.scss'

interface ISpinnerProps {
  position?: 'absolute' | 'relative'
}

export const Spinner = memo<ISpinnerProps>(({ position = 'absolute' }) => {
  const spinnerClasses = classNames('spinner', { center: position === 'absolute' })
  return <div data-test='spinner' className={spinnerClasses} />
})

export default Spinner
