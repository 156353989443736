import * as yup from 'yup'

const REQUIRED_MESSAGE = 'This field is required'
const EMAIL_MESSAGE = 'Enter a valid email address.'

export const ResendDetailsSchema = yup.object({
  /**
   * @example 'your@email.com'
   */
  email: yup.string().email(EMAIL_MESSAGE).required(REQUIRED_MESSAGE),
})

export type TResendDetailsSchema = yup.InferType<typeof ResendDetailsSchema>
