import { ENDPOINTS } from 'utils/api/callApi'
import { keymirror } from 'utils/keymirror'
import { generateApiActionTypes } from './generateActionTypes'

export const SHIPMENT_ACTION_TYPES = keymirror({
  REQUEST_SHIPMENTS: null,
  SHIPMENTS_SUCCESS: null,
  SHIPMENTS_FAILURE: null,
})

export const RECENT_SHIPMENTS_ACTION_TYPES = keymirror({
  REQUEST_RECENT_SHIPMENTS: null,
  RECENT_SHIPMENTS_SUCCESS: null,
  RECENT_SHIPMENTS_FAILURE: null,
})

export const SHIPMENTS_COUNT_ACTION_TYPES = keymirror({
  REQUEST_SHIPMENTS_COUNT: null,
  SHIPMENTS_COUNT_SUCCESS: null,
  SHIPMENTS_COUNT_FAILURE: null,
})

export const SHIPMENT_UPDATE_ACTION_TYPES = keymirror({
  REQUEST_SHIPMENT_UPDATE: null,
  SHIPMENT_UPDATE_SUCCESS: null,
  SHIPMENT_UPDATE_FAILURE: null,
})

export const ACTIVE_SHIPMENTS_COUNT_ACTION_TYPES = keymirror({
  REQUEST_ACTIVE_SHIPMENTS_COUNT: null,
  ACTIVE_SHIPMENTS_COUNT_SUCCESS: null,
  ACTIVE_SHIPMENTS_COUNT_FAILURE: null,
})

export const UNRESPONSIVE_ACTION_TYPES = generateApiActionTypes('UNRESPONSIVE')

export const UPDATE_LIST_FROM_UPDATE_REQUEST = 'UPDATE_LIST_FROM_UPDATE_REQUEST'
export const SHOW_LOADING_STATE_ON_SHIPMENT = 'SHOW_LOADING_STATE_ON_SHIPMENT'
export const HIDE_LOADING_ON_ALL_SHIPMENTS = 'HIDE_LOADING_ON_ALL_SHIPMENTS'
export const SHIPMENT_POD_UPLOADED = 'SHIPMENT_POD_UPLOADED'

export const getShipments = (params = {}, tabs, actionTypes = SHIPMENT_ACTION_TYPES) => ({
  type: undefined,
  payload: {
    callApi: true,
    requestData: {
      endpoint: ENDPOINTS.shipments,
      params,
    },
    tabs,
    actionTypes: Object.keys(actionTypes),
  },
})

export const getRecentShipments = (params = {}, tabs) =>
  getShipments(params, tabs, RECENT_SHIPMENTS_ACTION_TYPES)

export const getShipmentsCount = (body = {}, actionTypes = SHIPMENTS_COUNT_ACTION_TYPES) => ({
  payload: {
    callApi: true,
    requestData: {
      endpoint: ENDPOINTS.shipments,
      group: 'count',
      method: 'POST',
      body,
    },
    actionTypes: Object.keys(actionTypes),
  },
})

export const updateShipment = (id, params = {}, token = '', pathName = '') => ({
  payload: {
    callApi: true,
    requestData: {
      pathName,
      method: 'PUT',
      endpoint: ENDPOINTS.shipments,
      id,
      ...(token && { params: { easyAccessToken: token } }),
      body: {
        shipment: params,
      },
    },
    actionTypes: Object.keys(SHIPMENT_UPDATE_ACTION_TYPES),
  },
})

export const updateShipmentStatus = (id, statusChangeKey = '') => ({
  payload: {
    callApi: true,
    requestData: {
      method: 'PUT',
      endpoint: ENDPOINTS.shipments,
      id,
      group: 'update_status',
      body: { statusChangeKey },
    },
    actionTypes: Object.keys(SHIPMENT_UPDATE_ACTION_TYPES),
  },
})

// Shipment objects on the list and details API do not match
export const updateShipmentList = shipment => ({
  payload: { shipment },
  type: UPDATE_LIST_FROM_UPDATE_REQUEST,
})

export const shipmentPODUploaded = shipmentId => ({
  payload: shipmentId,
  type: SHIPMENT_POD_UPLOADED,
})

export const showLoadingOnShipment = shipmentId => ({
  payload: shipmentId,
  type: SHOW_LOADING_STATE_ON_SHIPMENT,
})

export const hideLoadingOnAllShipments = () => ({
  type: HIDE_LOADING_ON_ALL_SHIPMENTS,
})

export const updateUnresponsiveCount = id => ({
  payload: {
    callApi: true,
    requestData: {
      method: 'PUT',
      endpoint: ENDPOINTS.shipments,
      id,
      group: 'update_unresponsive_count',
    },
    actionTypes: Object.keys(UNRESPONSIVE_ACTION_TYPES),
  },
})
