import React, { forwardRef } from 'react'
import classNames from 'classnames'
import { Button as MuiButton, ButtonProps as MuiButtonProps, makeStyles } from 'transfix-ui/core'
import { brandColors } from 'transfix-ui/theme'

export type TMuiButtonColor = MuiButtonProps['color']

export type TButtonColor = TMuiButtonColor | 'tertiary' | 'hotPrimary' | 'hotSecondary'

/**
 * Allows for 'tertiary' type, and maps to correct MUI theme value
 * @example mapMuiColor('tertiary') // 'default'
 */
const mapMuiColor = (colorName?: TButtonColor): TMuiButtonColor | undefined => {
  switch (colorName) {
    case 'inherit':
      return 'inherit'
    case 'primary':
      return 'primary'
    case 'secondary':
      return 'secondary'
    case 'success':
      return 'success'
    case 'error':
      return 'error'
    case 'info':
      return 'info'
    case 'warning':
      return 'warning'
    case 'tertiary':
    case 'hotPrimary':
    case 'hotSecondary':
    case undefined:
    default:
      return undefined
  }
}

type TCustomButtonProps = {
  /**
   * @default 'secondary'
   */
  color?: TButtonColor
  selected?: boolean
  noPadding?: boolean
  'data-test'?: string
}

const useButtonStyles = makeStyles<TCustomButtonProps>(() => ({
  noPadding: {
    '&.MuiButton-root': {
      padding: 0,
      minWidth: 'auto',
    },
  },
  hotPrimary: {
    '&.MuiButton-root': {
      borderColor: brandColors.error1,
      backgroundColor: brandColors.error1,
      '&:active': {
        color: brandColors.white,
        backgroundColor: brandColors.error1,
      },
      '&:focus': {
        borderColor: brandColors.hotButtonHover,
        backgroundColor: brandColors.hotButtonHover,
        color: brandColors.white,
        boxShadow: `0 0 0 3px ${brandColors.hotButtonFocus}`,

        '&:hover': {
          borderColor: brandColors.hotButtonHover,
          backgroundColor: brandColors.hotButtonHover,
          color: brandColors.white,
          boxShadow: `0 0 0 3px ${brandColors.hotButtonFocus}`,
        },
      },
      '&:hover': {
        backgroundColor: brandColors.hotButtonHover,
        borderColor: brandColors.hotButtonHover,
        color: brandColors.white,
      },
    },
  },
  tertiary: {
    '&.MuiButton-root': {
      backgroundColor: brandColors.white,
      color: brandColors.skyBlue6,
      borderColor: brandColors.white,

      '&:hover': {
        color: brandColors.skyBlue7,
        backgroundColor: brandColors.skyBlue2,
        borderColor: brandColors.skyBlue2,
      },

      '&:active': {
        color: brandColors.skyBlue7,
        backgroundColor: brandColors.skyBlue2,

        '&:hover': {
          color: brandColors.skyBlue7,
          backgroundColor: brandColors.skyBlue2,
          borderColor: brandColors.skyBlue2,
        },
      },

      '&:focus': {
        color: brandColors.skyBlue7,
        backgroundColor: brandColors.skyBlue2,
        borderColor: brandColors.skyBlue3,

        '&:hover': {
          color: brandColors.skyBlue7,
          backgroundColor: brandColors.skyBlue2,
          borderColor: brandColors.skyBlue2,
        },
      },
    },
  },
  hotSecondary: {
    '&.MuiButton-root': {
      borderColor: brandColors.error1,
      backgroundColor: brandColors.white,
      color: brandColors.error1,
      '&:active': {
        color: brandColors.error1,
        backgroundColor: brandColors.error0,
      },
      '&:focus': {
        borderColor: brandColors.error0,
        backgroundColor: brandColors.error0,
        color: brandColors.error1,
        boxShadow: `0 0 0 3px ${brandColors.hotButtonFocus}`,

        '&:hover': {
          borderColor: brandColors.error0,
          backgroundColor: brandColors.error0,
          color: brandColors.error1,
          boxShadow: `0 0 0 3px ${brandColors.hotButtonFocus}`,
        },
      },
      '&:hover': {
        backgroundColor: brandColors.error0,
        borderColor: brandColors.error1,
        color: brandColors.error1,
      },
    },
  },
}))

export type TButtonProps<D extends React.ElementType = 'button', P = {}> = Omit<
  MuiButtonProps<D, P>,
  'color'
> &
  TCustomButtonProps

/**
 * @name Button
 * @see https://material-ui.com/components/buttons/
 */
const ButtonComponent = <D extends React.ElementType, P = {}>(
  {
    className: classNameProp,
    color = 'primary',
    noPadding,
    selected,
    ...props
  }: TButtonProps<D, P>,
  ref:
    | ((instance: HTMLButtonElement | null) => void)
    | React.MutableRefObject<HTMLButtonElement | null>
    | null
) => {
  const classes = useButtonStyles()

  const buttonColor = mapMuiColor(color)

  const className = classNames(classNameProp, {
    'MuiButton-selected': selected,
    [classes.tertiary]: color === 'tertiary',
    [classes.noPadding]: noPadding,
    [classes.hotPrimary]: color === 'hotPrimary',
    [classes.hotSecondary]: color === 'hotSecondary',
  })

  return <MuiButton {...props} ref={ref} color={buttonColor} className={className} />
}

export const Button = forwardRef(ButtonComponent)

export default Button
