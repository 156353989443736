import React from 'react'
import ReactTooltip from 'react-tooltip'
import classname from 'classnames'
import { makeStyles } from 'transfix-ui/core/styles'
import { GLOBAL_TOOLTIP_ID, GLOBAL_TOOLTIP_CONTENT_ID } from './constants'
import 'scss/5_components/_tooltip.scss'

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.snackbar,
  },
}))

/**
 * react-tooltip based approach used to avoid having one Tooltips per usage.
 * Instead, we reuse the same instance and update its content dynamically when triggered.
 * more info here -> https://react-tooltip.com/docs/troubleshooting#dynamically-generated-anchor-elements
 *
 * NOTE: This should only be instantiated once at the top level of the application.
 */
export const GlobalTooltip = () => {
  const classes = useStyles()
  const className = classname('tooltip', classes.root)

  return (
    <ReactTooltip id={GLOBAL_TOOLTIP_ID} className={className} delayShow={0} role='tooltip'>
      <div id={GLOBAL_TOOLTIP_CONTENT_ID} />
    </ReactTooltip>
  )
}

export default GlobalTooltip
