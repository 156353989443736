import React from 'react'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import MuiTypography, {
  TypographyProps as MuiTypographyProps,
  TypographyTypeMap,
} from '@mui/material/Typography'

export interface ITypographyProps extends MuiTypographyProps {}

/**
 * @see https://v4.mui.com/components/typography/#typography
 */
const Typography: OverridableComponent<TypographyTypeMap> = (props: ITypographyProps) => {
  return <MuiTypography {...props} />
}

export default Typography
