import { UseMutateProps, useMutate } from 'restful-react'
import { ENDPOINTS } from 'utils/api/callApi'

export interface IUseResendDetailsMutationResponse {
  success: boolean
}

export interface IUseResendMutationRequest {
  /**
   * @example 'your@email.com'
   */
  email: string
}

export type IUseResendDetailsProps = Omit<
  UseMutateProps<
    IUseResendDetailsMutationResponse,
    unknown,
    void,
    IUseResendMutationRequest,
    unknown
  >,
  'path' | 'base' | 'verb'
>

export const useResendDetailsMutation = ({ ...props }: IUseResendDetailsProps = {}) => {
  return useMutate<
    IUseResendDetailsMutationResponse,
    unknown,
    void,
    IUseResendMutationRequest,
    unknown
  >({
    path: `/${ENDPOINTS.password}`,
    verb: 'POST',
    base: __API__,
    /**
     * @note POST /password returns void, so this enables checking response
     */
    resolve: () => ({ success: true }),
    ...props,
  })
}
