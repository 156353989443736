type TApp = 'TMS' | 'FMS' | 'MARKETPLACE'

export const getDefaultApiUrl = (app: TApp) => {
  switch (app) {
    case 'TMS':
      return __TMS_API__
    case 'FMS':
      return __FP_API__
    case 'MARKETPLACE':
    default:
      return __API__
  }
}

export const getDefaultBackendUrl = (app: TApp) => {
  switch (app) {
    case 'TMS':
      return __TMS_API__
    case 'FMS':
      return __FP_API__
    case 'MARKETPLACE':
    default:
      return __MARKETPLACE_BACKEND__
  }
}

export const getDefaultGraphqlUrl = (app: TApp) => {
  switch (app) {
    case 'TMS':
      return `${__TMS_API__}/tms`
    case 'FMS':
      return `${__FP_API__}/graphql`
    case 'MARKETPLACE':
    default:
      return __GRAPHQL_ENDPOINT__
  }
}

export const backendHostnameOverrideKey = (app: TApp) => `TFX${app}BackendHostname`

export const getGraphqlUrl = (app: TApp) => {
  // if we're in prod, just return the default value and don't touch local storage
  if (__PROD__) {
    return getDefaultGraphqlUrl(app)
  }

  const apiHostnameOverride = window.localStorage.getItem(backendHostnameOverrideKey(app))
  if (apiHostnameOverride) {
    const url = new URL(getDefaultGraphqlUrl(app))
    url.host = apiHostnameOverride
    return url.toString()
  }

  return getDefaultGraphqlUrl(app)
}

export const getApiUrl = (app: TApp) => {
  // if we're in prod, just return the default value and don't touch local storage
  if (__PROD__) {
    return getDefaultApiUrl(app)
  }

  const apiHostnameOverride = window.localStorage.getItem(backendHostnameOverrideKey(app))
  if (apiHostnameOverride) {
    const url = new URL(getDefaultApiUrl(app))
    url.host = apiHostnameOverride
    return url.toString()
  }

  return getDefaultApiUrl(app)
}

export const getBackendUrl = (app: TApp) => {
  // if we're in prod, just return the default value and don't touch local storage
  if (__PROD__) {
    return getDefaultBackendUrl(app)
  }

  const apiHostnameOverride = window.localStorage.getItem(backendHostnameOverrideKey(app))
  if (apiHostnameOverride) {
    const url = new URL(getDefaultBackendUrl(app))
    url.host = apiHostnameOverride
    return url.toString()
  }

  return getDefaultBackendUrl(app)
}

export const setCustomApiHostnameOverride = (app: TApp, hostname: string) => {
  if (__ENV__ !== 'production') {
    window.localStorage.setItem(backendHostnameOverrideKey(app), hostname)
  }
}

export const clearApiHostnameOverride = (app: TApp) =>
  window.localStorage.removeItem(backendHostnameOverrideKey(app))
