import React from 'react'
import { Route, Switch } from 'react-router-dom'
import LoginPage from './LoginPage/indexAsync'
import ResendDetailsPage from './ResendDetailsPage'
import ResetPasswordPage from './ResetPasswordPage'
import SignOutPage from './SignOutPage/indexAsync'

const ResendDetails = () => <ResendDetailsPage pageTitle='Resend Instructions' />
const ForgotPassword = () => <ResendDetailsPage pageTitle='Forgot your password?' />

const App = () => {
  return (
    <Switch>
      <Route path='/login' key='login' component={LoginPage} />
      <Route path='/signout' key='signout' component={SignOutPage} />
      <Route path='/resend_details' key='resend-details' component={ResendDetails} />
      <Route path='/change_password' key='forgot-password' component={ForgotPassword} />
      <Route path='/forgot_password' key='reset-password' component={ResetPasswordPage} />
    </Switch>
  )
}

export default App
