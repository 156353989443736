import React from 'react'
import { Route, RouteProps, useHistory } from 'react-router-dom'
import { Spinner } from 'transfix-ui/components/Spinner'
import { useUserDetails } from 'utils/hooks/useUserDetails'
import { useAccessToken } from 'utils/userAuth'

export function AuthenticatedRoute(props: RouteProps) {
  const { location } = props
  const history = useHistory()
  const token = useAccessToken()
  const { userIsAuthenticated } = useUserDetails()
  const { createHref } = history
  const href = createHref(location || {})
  const isTMSUrl = href.includes('/tms')

  if (!(userIsAuthenticated || token)) {
    if (isTMSUrl) {
      history.push('/login', {
        from: location,
      })
    } else {
      window.location.href = `${__ROOT__}/signout?redirect=${encodeURIComponent(href)}`
    }

    return <Spinner />
  }

  return <Route {...props} />
}
